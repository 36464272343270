import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { LoginActionPayload, IsAuthPayload, UpdateProfilePayload } from './types'
import { getStoreState } from '_core/local-storage'
import { ApiCallOptions, ApiRequest, genericActions } from '_core/store'

export interface SessionState {
  initialized: boolean
  logged: boolean
  token?: string
  user?: any
  requests: {
    [key: string]: ApiRequest
  }
}
export type SessionApi = StoreActionApi<SessionState>

const initialState: SessionState = {
  initialized: false,
  logged: false,
  token: undefined,
  user: undefined,
  requests: {},
}

export const actions = {
  login: (data: LoginActionPayload) => async ({
    setState,
    dispatch,
  }: SessionApi): Promise<void> => {
    const res = await dispatch(
      genericActions.api({
        url: '/auth',
        method: 'post',
        data,
      }),
    )

    if (res && res.result && res.result.token && res.result.user.role === 'admin') {
      setState({
        initialized: true,
        logged: true,
        token: res.result.token,
        user: res.result.user,
      })
    }
  },
  updateProfile: (data: UpdateProfilePayload, options?: ApiCallOptions) => async ({
    getState,
    setState,
    dispatch,
  }: SessionApi): Promise<void> => {
    const res = await dispatch(
      genericActions.api(
        {
          url: `/users/${getState().user._id}`,
          method: 'put',
          data,
        },
        options,
      ),
    )
    if (res && res.result) {
      setState({
        user: res.result,
      })
    }
  },
  logout: () => async ({ setState }: SessionApi): Promise<void> => {
    setState(initialState)
  },
  isAuth: (data: IsAuthPayload) => async ({
    setState,
    getState,
    dispatch,
  }: SessionApi): Promise<void> => {
    if (!data.token) data.token = getState().token
    const res = await dispatch(
      genericActions.api({
        url: '/is-auth',
        method: 'get',
        data,
      }),
    )
    if (res && res.result && res.result.token) {
      setState({
        initialized: true,
        logged: true,
        token: res.result.token,
        user: res.result.user,
      })
    } else {
      setState(initialState)
    }
  },
}

export type SessionActions = typeof actions

const name = 'session'

const Store = createStore<SessionState, SessionActions>({
  name,
  initialState: getStoreState(name) || initialState,
  actions,
})

export const SessionSubscriber = createSubscriber(Store)
export const useSession = createHook(Store)
export const SessionContainer = createContainer(Store)
export const useAppUser = createHook(Store, {
  selector: state => state.user,
})

export default Store

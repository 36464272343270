import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Column } from 'react-table'
import { Product, AttemptVote } from '_stores/products/types'
import { User } from '_stores/users/types'

interface UseVotesTableProps {
  product?: Product
}

const useVotesTable = ({ product }: UseVotesTableProps): [Column[], AttemptVote[]] => {
  const columns = React.useMemo(
    () =>
      !product
        ? []
        : [
            {
              Header: 'Nom',
              accessor: `user.firstName`,
              width: '30%',
              Cell: (data: any) => {
                const vote: AttemptVote = data.cell.row.original
                const user = vote.user as User
                return (
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                )
              },
            },
            {
              Header: 'Vote',
              accessor: `score`,
              width: '20%',
              Cell: (data: any) => {
                const vote: AttemptVote = data.cell.row.original
                return (
                  <span>
                    {[1, 2, 3, 4, 5].map(value => (
                      <FontAwesomeIcon
                        key={value}
                        className="text-warning"
                        icon={vote.score >= value ? faStar : faStarRegular}
                      />
                    ))}
                  </span>
                )
              },
            } /*,
            {
              Header: 'Commentaire',
              accessor: `comment`,
            },*/,
          ],
    [product],
  )

  const votes = React.useMemo(() => {
    if (!product) return []
    return product.votes
  }, [product])

  return [columns, votes]
}

export default useVotesTable

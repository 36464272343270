import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import CloudinaryImage from '_components/cloudinary-image'
import { ListGroupFormField } from '_components/form/list-group'
import { useMedias } from '_stores/medias/store'
import { useOrganizations } from '_stores/organizations/store'
import { Organization } from '_stores/organizations/types'

interface UseFormFieldsProps {
  organization?: Organization
}

const useFormFields = ({ organization }: UseFormFieldsProps): [ListGroupFormField[]] => {
  const [state, actions] = useOrganizations()
  const [mediasState, mediasActions] = useMedias()

  React.useEffect(() => {
    if (!mediasState.all[0]) mediasActions.fetch()
  }, [])

  const [logo, setLogo] = React.useState<string>()
  React.useEffect(() => {
    if (mediasState.requests['post /medias'] && mediasState.requests['post /medias'].response) {
      setLogo(mediasState.requests['post /medias'].response[0]._id)
    } else if (organization && organization.logo) {
      setLogo(organization?.logo._id)
    } else {
      setLogo(undefined)
    }
  }, [organization, mediasState.requests['post /medias']])

  const fields = React.useMemo(
    () =>
      !organization
        ? []
        : [
            {
              label: 'Nom',
              value: organization.name,
              formProps: {
                initialValues: organization,
                fields: [
                  {
                    name: 'name',
                    type: 'text',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: organization._id,
                      name: values.name,
                    },
                    {
                      requestKey: `put name /organizations/${organization._id}`,
                    },
                  )
                },
                validate: (values: any) => {
                  const errors: any = {}
                  if (!values.name) errors.name = 'Champs requis'
                  return errors
                },
                request: state.requests[`put name /organizations/${organization._id}`],
              },
            },
            {
              label: 'Logo',
              //@ts-ignore
              value: (
                <div className="pt-2">
                  <CloudinaryImage
                    publicId={organization.logo?.providerId}
                    transformations={{
                      crop: 'scale',
                      height: 50,
                    }}
                  />
                </div>
              ),
              formProps: {
                initialValues: {
                  //@ts-ignore
                  logo: logo && [logo],
                },
                fields: [
                  {
                    type: 'medias',
                    name: 'logo',
                    multiple: false,
                    mediasProps: {
                      mediaColProps: { lg: 3 },
                      onDrop: mediasActions.create,
                      onDropzoneHide: () => mediasActions.clearRequest({ key: 'post /medias' }),
                      request: mediasState.requests['post /medias'],
                      modalReadonly: true,
                    },
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: organization._id,
                      logo: values.logo[0],
                    },
                    {
                      requestKey: `put logo /organizations/${organization._id}`,
                    },
                  )
                },
                request: state.requests[`put logo /organizations/${organization._id}`],
              },
            },
            {
              label: (
                <span>
                  <strong>Actif</strong>, les utilsateurs peuvent se connecter
                </span>
              ),
              value: '',
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { active: organization.active },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'active',
                  },
                ],
                onFieldChange: (fieldName: string, value: boolean) => {
                  actions.update(
                    {
                      _id: organization._id,
                      active: value,
                    },
                    {
                      requestKey: `put active /organizations/${organization._id}`,
                    },
                  )
                },
                request: state.requests[`put active /organizations/${organization._id}`],
              },
            },
            ,
            {
              label: (
                <span>
                  <strong>Pays </strong>
                  {!organization.country && (
                    <span
                      className="text-danger"
                      title="Il faut attribuder un pays pour que les produits affichés aux utilisateurs de ce client prennent en compte la limite de pays"
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                  )}
                </span>
              ),
              value: organization.country,
              //forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { country: organization.country },
                fields: [
                  {
                    name: 'country',
                    type: 'select',
                    initialValue: organization.country,
                    placeholder: 'Choisir...',
                    options: [
                      {
                        value: 'be',
                        label: 'Belgique',
                      },
                      {
                        value: 'fr',
                        label: 'France',
                      },
                      { label: 'Luxembourg', value: 'lu' },
                      {
                        value: 'nl',
                        label: 'Pays-Bas',
                      },
                    ],
                  },
                ],
                onFieldChange: (fieldName: string, value: string) => {
                  actions.update(
                    {
                      _id: organization._id,
                      country: value,
                    },
                    {
                      requestKey: `put country /organizations/${organization._id}`,
                    },
                  )
                },
                request: state.requests[`put country /organizations/${organization._id}`],
              },
            },
          ],
    [organization, logo],
  )

  return [fields]
}

export default useFormFields

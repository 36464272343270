import axios, { Method } from 'axios'

const baseApi = axios.create({
  baseURL: process.env.API_HOST,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export interface ApiCallConfig {
  method: Method
  url: string
  params?: any
  data?: any
  headers?: any
  progress?: boolean
  onUploadProgress?(progressEvent: ProgressEvent): void
  authToken?: string
  timeout?: number
}

const api = async (
  config: ApiCallConfig,
): Promise<{ success?: boolean; data?: any; error?: any; message?: string }> => {
  if (!config.method) config.method = 'get'
  if (config.method === 'get' && config.data) config.params = config.data

  //Init headers
  let headers: { authorization?: string; [key: string]: any } = {}
  if (config.authToken) headers.authorization = config.authToken
  if (config.headers) headers = { ...headers, ...config.headers }

  //TODO: on upload progress

  try {
    const res = await baseApi.request({
      ...config,
      headers,
    })
    return {
      success: true,
      data: res.data,
    }
  } catch (err) {
    let message
    if (err.response && err.response.data) {
      if (err.response.data.data && err.response.data.data.message)
        message = err.response.data.data.message
      else if (err.response.data.message) message = err.response.data.message
    }
    return {
      success: false,
      error: err,
      message,
    }
  }
}

export default api

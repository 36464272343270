import * as React from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import AutoSaveField from '_components/form/auto-save-field'
import { Page, PageField, UpdatePageContextPayload } from '_stores/pages/types'
import PageEditFieldList from './field-list'

interface PageEditFieldsProps {
  page: Page
  fields: PageField[]
  locales: string[]
  context: any
  updateContext(pageId: string, payload: UpdatePageContextPayload, subContext?: any): void
  contextPath?: string
}

const PageEditFields: React.FunctionComponent<PageEditFieldsProps> = ({
  page,
  fields,
  locales,
  context,
  updateContext,
  contextPath,
}) => (
  <>
    {fields.map(field => {
      const itemContextPath = !contextPath ? field.name : `${contextPath}.${field.name}`
      return (
        <Col xl={12} className="mb-3 mt-3 bg-light p-3" key={field.name}>
          <h3>{field.name}</h3>
          {locales.map(locale => (
            <Form.Group key={locale}>
              <Form.Row>
                {field.type !== 'list' && (
                  <Form.Label column md={1} className="text-right">
                    {locale}
                  </Form.Label>
                )}
                <Col>
                  {field.type === 'medias' && context[locale] && (
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        const value = context[locale][field.name]
                        locales.forEach(l => {
                          if (locale !== l && page && page._id) {
                            updateContext(
                              page._id,
                              {
                                locale: l,
                                value,
                                contextPath: itemContextPath,
                              },
                              { ...context[locale], [field.name]: value },
                            )
                          }
                        })
                      }}
                    >
                      Copy to others locales
                    </Button>
                  )}
                  {field.type === 'list' ? (
                    <>
                      <PageEditFieldList
                        page={page}
                        field={field}
                        locale={locale}
                        context={context}
                        updateContext={updateContext}
                        contextPath={itemContextPath}
                      />
                    </>
                  ) : (
                    <AutoSaveField
                      key={locale}
                      disableAutoFocus
                      field={{
                        ...field,
                        ...field.options,
                      }}
                      initialValue={context && context[locale] && context[locale][field.name]}
                      forceEditableMode
                      onSubmit={value => {
                        if (page && page._id)
                          updateContext(
                            page._id,
                            {
                              locale,
                              value,
                              contextPath: itemContextPath,
                            },
                            { ...context[locale], [field.name]: value },
                          )
                      }}
                    />
                  )}
                </Col>
              </Form.Row>
            </Form.Group>
          ))}
        </Col>
      )
    })}
  </>
)

export default PageEditFields

import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useCurrentPage } from '_stores/pages/store'
import PageForm from '../form'

const PageSettings: React.FunctionComponent = () => {
  const [currentPage, actions] = useCurrentPage()
  return (
    <Row>
      <Col md={12}>
        <PageForm onSubmit={values => actions.update(values)} page={currentPage} />
      </Col>
      <Col md={12}>
        <a
          href={
            currentPage &&
            `data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify(currentPage.context),
            )}`
          }
          download={currentPage && `${currentPage.name}_context.json`}
          className="mt-4 btn btn-primary"
        >
          Download context
        </a>
      </Col>
    </Row>
  )
}

export default PageSettings

import * as React from 'react'
import { Button, ListGroup } from 'react-bootstrap'
import RequestMessage from '_components/request-message'
import { ListGroupFormField } from '.'
import Form from '..'

interface ListGroupFormItemProps extends ListGroupFormField {
  buttons?: React.ReactElement
}

const ListGroupFormItem: React.FunctionComponent<ListGroupFormItemProps> = ({
  label,
  editLabel,
  formProps,
  saveButtonLabel,
  value,
  forceEditMode,
  onCancelClick,
  editableClassName,
  buttons,
}) => {
  const [isEditable, setEditable] = React.useState(Boolean(forceEditMode))
  React.useEffect(() => {
    if (forceEditMode) return setEditable(true)
    if (formProps.request && formProps.request.status === 'success') setEditable(false)
  }, [formProps.request && formProps.request.status, forceEditMode])

  return (
    <ListGroup.Item className="d-flex align-items-center">
      <div className="flex-fill pr-3">
        {label && (
          <div className="u-color-light mb-1">
            {isEditable && editLabel ? editLabel : label}
            <RequestMessage request={formProps.request} loader check />
          </div>
        )}
        {isEditable ? (
          <div className={editableClassName || 'mt-3'}>
            <Form
              {...formProps}
              onSubmit={values => {
                formProps.onSubmit && formProps.onSubmit(values)
              }}
              cancelButton={{
                label: 'Annuler',
                onClick: () => {
                  !forceEditMode && setEditable(false)
                  onCancelClick && onCancelClick()
                },
              }}
            />
          </div>
        ) : (
          <>
            <div>{value}</div>
          </>
        )}
      </div>
      {!isEditable && (
        <div>
          {' '}
          {buttons}
          <Button variant="default" onClick={() => setEditable(!isEditable)}>
            {saveButtonLabel || 'Modifier'}
          </Button>
        </div>
      )}
    </ListGroup.Item>
  )
}

export default ListGroupFormItem

import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ConfirmationModal from '_components/confirmation-modal'
import { useUsers, useCurrentUser } from '_stores/users/store'

const DeleteUser: React.FunctionComponent = () => {
  const history = useHistory()
  const locationParams: any = useParams()
  const [state, actions] = useUsers()
  const [currentUser] = useCurrentUser()
  React.useEffect(() => {
    actions.setCurrent({ _id: locationParams.id })
  }, [locationParams, state.all])
  return (
    <ConfirmationModal
      isOpen={true}
      confirm={() => {
        history.push('/users')
        if (currentUser && currentUser._id)
          actions.update({
            _id: currentUser._id,
            disabled: !currentUser.disabled,
          })
      }}
      cancel={() => history.push('/users')}
      title="Remove user access"
      description={
        currentUser && currentUser._id ? (
          <p>
            Are you sure you want to {currentUser.disabled ? 're-activate' : 'disable'} the account
            of&nbsp;
            <strong>
              {currentUser.firstName} {currentUser.lastName} ({currentUser.email})
            </strong>{' '}
            ?
          </p>
        ) : (
          ''
        )
      }
    />
  )
}

export default DeleteUser

import * as React from 'react'
import { Router, useHistory, useLocation } from 'react-router-dom'
import Logged from './logged'
import Anonymous from './anonymous'
import history from '_core/history'
import { getRoute } from '../routes'

const Main: React.FunctionComponent = (): React.ReactElement => {
  const location = useLocation()
  const history = useHistory()
  const currentRoute = getRoute(location.pathname)
  if (location.pathname === '/') history.replace('/login')
  if (!currentRoute) return <div>404</div>
  else if (currentRoute.logged) return <Logged />
  return <Anonymous />
}

const App: React.FunctionComponent = () => {
  return (
    <Router history={history}>
      <Main />
    </Router>
  )
}

export default App

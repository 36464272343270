import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { genericActions, ResourceState } from '_core/store'
import { Media } from './types'
import { ClearRequestPayload } from '_core/store'

export interface MediasState extends ResourceState<Media> {
  used?: {
    articles: Media[]
    pages: Media[]
    unused: string[]
  }
}
export type MediasApi = StoreActionApi<MediasState>

const initialState: MediasState = {
  endpoint: '/medias',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {},
}

const actions = {
  fetch: (query?: any) => async ({ dispatch }: MediasApi) => {
    if (!query) query = {}
    query.sort = '-dates.created'
    await dispatch(genericActions.apiFetch(query))
  },
  get: (_id: string) => async ({ dispatch }: MediasApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: Media) => async ({ getState, dispatch }: MediasApi) => {
    const formData = new FormData()
    data.forEach((file: any) => formData.append('files', file))
    const res = await dispatch(
      genericActions.api(
        {
          data: formData,
          method: 'post',
          url: getState().endpoint,
        },
        {
          timeout: 1000 * 60 * 5,
          progress: true,
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      ),
    )
    res &&
      Array.isArray(res) &&
      res.forEach((media: any) => {
        if (media._id) dispatch(genericActions.addOrReplaceItemInList(media))
      })
    return res
  },
  update: (data: Media) => async ({ dispatch }: MediasApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: Media) => async ({ dispatch }: MediasApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: MediasApi) => {
    await dispatch(genericActions.apiDelete(_id))
    dispatch(genericActions.removeItemInList({ _id }))
  },
  setCurrent: (media?: Media) => ({ dispatch }: MediasApi) => {
    dispatch(genericActions.setCurrent(media))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: MediasApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: MediasApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: MediasApi) =>
    dispatch(genericActions.getRequest(method, withId)),
  checkUsed: () => async ({ setState, getState, dispatch }: MediasApi) => {
    const res = await dispatch(
      genericActions.api({
        url: '/medias/used',
        method: 'get',
      }),
    )

    if (res && res.result) {
      const unused: string[] = []
      getState().all.forEach((media: Media) => {
        if (
          media._id &&
          !res.result.articles.some((m: Media) => m._id === media._id) &&
          !res.result.pages.some((m: Media) => m._id === media._id) &&
          !res.result.products.some((m: Media) => m._id === media._id) &&
          !res.result.brands.some((m: Media) => m._id === media._id) &&
          !res.result.organizations.some((m: Media) => m._id === media._id)
        )
          unused.push(media._id)
      })

      setState({
        used: {
          ...res.result,
          unused,
        },
      })
    }
  },
  clearUsed: () => ({ setState }: MediasApi) => {
    setState({
      used: undefined,
    })
  },
}

export type MediasActions = typeof actions

const Store = createStore<MediasState, MediasActions>({
  name: 'medias',
  initialState,
  actions,
})

export const MediasSubscriber = createSubscriber(Store)
export const useMedias = createHook(Store)
export const useCurrentMedia = createHook(Store, {
  selector: (state: MediasState) => {
    if (state.currentId) return state.all.find(media => media._id === state.currentId)
    return
  },
})
export const MediasContainer = createContainer(Store)

export default Store

import * as React from 'react'
import { Product, ProductKinds } from '_stores/products/types'
import {
  faEye,
  faFilePdf,
  faInfo,
  faPencilAlt,
  faShoppingCart,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import GeneralForm from '../item/general-form'
import VisibilityForm from '../item/visibility-form'
import CharacteristicsForm from '../item/characteristics-form'
import FilesForm from '../item/files-form'
import ListGroupFormTitleWrapper from '_components/form/list-group/title'
import { Button, Card } from 'react-bootstrap'
import MafactData from '../item/mafact-data'
import LinkedProductsForm from '../item/linked-products-form'
import ProductVotesList from '../item/votes-list'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useItemSections = (locale: string, actions: any, product?: Product): any[] => {
  const [editAllGeneralFields, setEditAllGeneralFields] = React.useState(false)
  let allFormsValues: any = {}
  const bindValues = (submitForm: any) => {
    allFormsValues = { ...allFormsValues, ...submitForm }
  }

  const submitAllForms = () => {
    if (allFormsValues.pictures) {
      allFormsValues.pictures = allFormsValues.pictures.map((pictureId: string, index: number) => ({
        cover: Boolean(index === 0),
        item: pictureId,
      }))
    }
    product &&
      actions.update({
        _id: product._id,
        ...allFormsValues,
      })
  }

  return React.useMemo(
    () =>
      product
        ? [
            { label: 'Toutes', path: [`/products/${product._id}`, `/attempts/${product._id}`] },
            product.kind !== ProductKinds.attempt && {
              label: 'Données Mafact',
              path: [`/products/${product._id}/mafact`],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faInfo}
                  title="Données Mafact"
                  description={'Visualiser les données fournies par Mafact.'}
                >
                  <Card className="p-3">
                    <MafactData locale={locale} />
                  </Card>
                </ListGroupFormTitleWrapper>
              ),
            },
            {
              label: 'Général',
              path: [`/products/${product._id}/general`, `/attempts/${product._id}/general`],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faInfo}
                  title="Général"
                  description={"Visualiser et modifier les données de bases d'un produit."}
                >
                  <>
                    <GeneralForm
                      locale={locale}
                      forceEdit={editAllGeneralFields}
                      bindValues={bindValues}
                    />
                    <div className="text-right">
                      {editAllGeneralFields ? (
                        <div className="mt-2">
                          <Button
                            onClick={() => setEditAllGeneralFields(false)}
                            variant="default"
                            className="mr-2"
                          >
                            Annuler
                          </Button>
                          <Button onClick={() => submitAllForms()}>Sauver tous les champs</Button>
                        </div>
                      ) : (
                        <Button
                          size="sm"
                          onClick={() => setEditAllGeneralFields(true)}
                          variant="link"
                        >
                          <FontAwesomeIcon icon={faPencilAlt} className="mr-1" />
                          Modifier tous les champs
                        </Button>
                      )}
                    </div>
                  </>
                </ListGroupFormTitleWrapper>
              ),
            },
            /*product.kind !== ProductKinds.attempt &&*/ {
              label: 'Visibilité',
              path: [`/products/${product._id}/visibility`, `/attempts/${product._id}/visibility`],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faEye}
                  title="Visibilité"
                  description={'Définir qui peut consulter le produit et où il est affiché.'}
                >
                  <VisibilityForm locale={locale} />
                </ListGroupFormTitleWrapper>
              ),
            },
            {
              label: 'Caractéristiques',
              path: [
                `/products/${product._id}/characteristics`,
                `/attempts/${product._id}/characteristics`,
              ],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faInfo}
                  title="Caractéristiques"
                  description={'Définir les détails techniques du produit.'}
                >
                  <CharacteristicsForm locale={locale} />
                </ListGroupFormTitleWrapper>
              ),
            },
            {
              label: 'Fichiers attachés',
              path: [`/products/${product._id}/files`, `/attempts/${product._id}/files`],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faFilePdf}
                  title="Fichiers attachés"
                  description={'Joindre les fichiers au produit.'}
                >
                  <FilesForm locale={locale} />
                </ListGroupFormTitleWrapper>
              ),
            },
            product.kind !== ProductKinds.attempt && {
              label: 'Produits liés',
              path: [`/products/${product._id}/linked-products`],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faShoppingCart}
                  title="Produits liés"
                  description={'Gérer les produits apparentés.'}
                >
                  <LinkedProductsForm locale={locale} />
                </ListGroupFormTitleWrapper>
              ),
            },
            product.kind === ProductKinds.attempt && {
              label: 'Votes',
              path: [`/products/${product._id}/votes`, `/attempts/${product._id}/votes`],
              component: () => (
                <ListGroupFormTitleWrapper
                  icon={faStar}
                  title="Votes"
                  description={'Visualizer les votes pour cette tentative.'}
                >
                  <ProductVotesList />
                </ListGroupFormTitleWrapper>
              ),
            },
          ].filter(i => i)
        : [],
    [product && product._id, locale, editAllGeneralFields, bindValues],
  )
}
export default useItemSections

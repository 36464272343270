import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import Select from 'react-select'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}

export interface ReactSelectProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
  onInputChange?: (value: any, actionMeta: any) => void
}

export const InputField: React.FunctionComponent<ReactSelectProps> = props => {
  const defaultValue = React.useMemo(() => {
    if (!props.defaultValue && props.multiple) return []
    else if (!props.defaultValue) return {}
    if (Array.isArray(props.defaultValue))
      return props.defaultValue.map((value: string) => props.options.find(o => o.value === value))
    return props.options.find(o => o.value === props.defaultValue)
  }, [props.defaultValue, props.options])

  return (
    <Field {...props}>
      <Select
        defaultValue={defaultValue || []}
        isMulti={props.multiple}
        name={props.name}
        options={props.options}
        onChange={selectedOption => {
          if (!props.onChange) return
          if (Array.isArray(selectedOption))
            return props.onChange(selectedOption && selectedOption.map((o: any) => o.value))
          return props.onChange(selectedOption)
        }}
        onInputChange={props.onInputChange}
        classNamePrefix="c-field-react-select"
        //className= {
        /*classNames('c-field-react-select', {
            'is-valid': !props.hideValid && !Boolean(props.error),
            'is-invalid': Boolean(props.error)
          })*/
        //}
      />
    </Field>
  )
}
export default InputField

import * as React from 'react'
import { ContactTypes, Organization, OrganizationContact } from '_stores/organizations/types'

interface UseContactsArrayProps {
  organization?: Organization
}

interface ContactItem {
  title: string
  contact: OrganizationContact
}

const useContactsArray = ({ organization }: UseContactsArrayProps): ContactItem[] => {
  return React.useMemo(() => {
    const contacts: ContactItem[] = []
    if (!organization || !organization.contacts) return contacts

    return organization.contacts.map(contact => {
      let title = 'Contact principal'
      if (contact.type === ContactTypes.billing) title = 'Contact facturation/administration'
      if (contact.type === ContactTypes.commercial) title = 'Contact commercial'
      if (contact.type === ContactTypes.billing) title = 'Contact logistique'
      return {
        contact,
        title,
      }
    })
  }, [organization])
}

export default useContactsArray

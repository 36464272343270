import * as React from 'react'
import { useApiKeys } from '_stores/api-keys/store'
import Table from '_components/table'
import { Link, Route } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import moment from 'moment'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Switch from 'react-bootstrap/esm/Switch'
import ApiKeyItem from './item'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ApiKeysList: React.FunctionComponent = () => {
  const [state, actions] = useApiKeys()
  React.useEffect(() => {
    actions.fetch()
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (d: any) => <Link to={`/api-keys/${d._id}`}>{d.name}</Link>,
      },
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: '10%',
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: (d: any) => <span>{moment(d.dates.created).format()}</span>,
      },
    ],
    [],
  )

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">API keys</h1>
        <div className="ml-auto">
          <Link to="/api-keys/new" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Create new API key
          </Link>
        </div>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <Table columns={columns} data={state.all} />
        </div>
      </Card>
      <Switch>
        <Route path="/api-keys/:id" exact component={ApiKeyItem} />
      </Switch>
    </PageApp>
  )
}

export default ApiKeysList

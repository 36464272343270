import * as React from 'react'
import ListGroupForm from '_components/form/list-group'
import { useCurrentProduct } from '_stores/products/store'
import useVisibilityFormFields from '../hooks/use-visibility-form-fields'

interface VisibilityFormProps {
  locale: string
}

const VisibilityForm: React.FunctionComponent<VisibilityFormProps> = ({ locale }) => {
  const [currentProduct] = useCurrentProduct()
  const [fields] = useVisibilityFormFields(locale, currentProduct)

  return (
    <div className="product-visibility">
      <ListGroupForm fields={fields} />
    </div>
  )
}

export default VisibilityForm

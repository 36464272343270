import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import * as React from 'react'
import CloudinaryImage from '_components/cloudinary-image'
import ListGroupForm, { ListGroupFormField } from '_components/form/list-group'
import APP from '_constants/app'
import { useBrands, useCurrentBrand } from '_stores/brands/store'
import { useMedias } from '_stores/medias/store'

interface BrandFormProps {
  locale: string
}

const VisibleValue: React.FunctionComponent<{
  visible: boolean
  visibleLabel?: string | React.ReactNode
  notVisibleLabel?: string | React.ReactNode
}> = ({ visible, visibleLabel, notVisibleLabel }) => (
  <div className="text-gray-700 d-flex align-items-center">
    <FontAwesomeIcon
      icon={faCircle}
      className={classNames('mr-1', visible ? 'text-success ' : 'text-secondary ')}
      size="xs"
    />
    {visible ? visibleLabel || 'Oui' : notVisibleLabel || 'Non'}
  </div>
)

const BrandForm: React.FunctionComponent<BrandFormProps> = ({ locale }) => {
  const [currentBrand, actions] = useCurrentBrand()
  const [mediasState, mediasActions] = useMedias()
  const [state] = useBrands()

  React.useEffect(() => {
    if (!mediasState.all[0]) mediasActions.fetch()
  }, [])

  const [logo, setLogo] = React.useState<string>()
  React.useEffect(() => {
    if (mediasState.requests['post /medias'] && mediasState.requests['post /medias'].response) {
      setLogo(mediasState.requests['post /medias'].response[0]._id)
    } else if (currentBrand && currentBrand.logo) {
      setLogo(currentBrand?.logo._id)
    } else {
      setLogo(undefined)
    }
  }, [currentBrand, mediasState.requests['post /medias']])

  const fields: ListGroupFormField[] = React.useMemo(
    () =>
      !currentBrand
        ? []
        : [
            {
              label: 'Nom',
              value: currentBrand.name,
              formProps: {
                initialValues: currentBrand,
                fields: [
                  {
                    name: 'name',
                    type: 'text',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      name: values.name,
                    },
                    {
                      requestKey: `put name /brands/${currentBrand._id}`,
                    },
                  )
                },
                validate: (values: any) => {
                  const errors: any = {}
                  if (!values.name) errors.name = 'Champs requis'
                  return errors
                },
                request: state.requests[`put name /brands/${currentBrand._id}`],
              },
            },
            {
              label: 'Logo',
              //@ts-ignore
              value: (
                <div className="pt-2">
                  <CloudinaryImage
                    publicId={currentBrand.logo?.providerId}
                    transformations={{
                      crop: 'scale',
                      height: 50,
                    }}
                  />
                </div>
              ),
              formProps: {
                initialValues: {
                  //@ts-ignore
                  logo: logo && [logo],
                },
                fields: [
                  {
                    type: 'medias',
                    name: 'logo',
                    multiple: false,
                    mediasProps: {
                      mediaColProps: { lg: 3 },
                      onDrop: mediasActions.create,
                      onDropzoneHide: () => mediasActions.clearRequest({ key: 'post /medias' }),
                      request: mediasState.requests['post /medias'],
                      modalReadonly: true,
                    },
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      logo: values.logo[0],
                    },
                    {
                      requestKey: `put logo /brands/${currentBrand._id}`,
                    },
                  )
                },
                request: state.requests[`put logo /brands/${currentBrand._id}`],
              },
            },
            {
              label: 'Description',
              value: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentBrand?.description ? currentBrand?.description[locale] : 'N/A',
                  }}
                />
              ),
              formProps: {
                initialValues: { description: currentBrand.description },
                fields: [
                  {
                    locales: APP.languagues,
                    canDuplicateLocales: true,
                    type: 'wysiwyg',
                    name: 'description',
                    className: 'mb-5',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      description: values.description,
                    },
                    {
                      requestKey: `put description /brands/${currentBrand._id}`,
                    },
                  )
                },
                request: state.requests[`put description /brands/${currentBrand._id}`],
              },
            },
            {
              label: 'Site officiel',
              value: currentBrand.link && (
                <a href={currentBrand.link} target="_blank" rel="noreferrer">
                  {currentBrand.link}
                </a>
              ),
              formProps: {
                initialValues: currentBrand,
                fields: [
                  {
                    name: 'link',
                    type: 'text',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      link: values.link,
                    },
                    {
                      requestKey: `put link /brands/${currentBrand._id}`,
                    },
                  )
                },
                request: state.requests[`put link /brands/${currentBrand._id}`],
              },
            },
            {
              label: (
                <span>
                  Mit en avant <strong>site public</strong>
                </span>
              ),
              value: currentBrand.visibility.public,
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { visibility: currentBrand.visibility },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'visibility.public',
                    className: 'mb-0',
                  },
                ],
                onFieldChange: (fieldName: string, value: boolean) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      visibility: {
                        ...currentBrand.visibility,
                        public: value,
                      },
                    },
                    {
                      requestKey: `put visibility.public /brands/${currentBrand._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.public /brands/${currentBrand._id}`],
              },
            },
            {
              label: (
                <span>
                  Visible dans les <strong>filtres</strong>
                </span>
              ),
              value: currentBrand.visibility.filter,
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { visibility: currentBrand.visibility },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'visibility.filter',
                    className: 'mb-0',
                  },
                ],
                onFieldChange: (fieldName: string, value: boolean) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      visibility: {
                        ...currentBrand.visibility,
                        filter: value,
                      },
                    },
                    {
                      requestKey: `put visibility.filter /brands/${currentBrand._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.filter /brands/${currentBrand._id}`],
              },
            },
            {
              label: (
                <span>
                  Limité la visibilité aux utilisateurs{' '}
                  <strong>professionels de certains pays</strong>
                </span>
              ),
              value: (
                <VisibleValue
                  visible={Boolean(
                    currentBrand.visibility.countries && currentBrand.visibility.countries[0],
                  )}
                  visibleLabel={
                    currentBrand.visibility.countries &&
                    currentBrand.visibility.countries.join(', ')
                  }
                  notVisibleLabel="Accessible pour tous les pays"
                />
              ),
              formProps: {
                initialValues: {
                  visibility: {
                    ...currentBrand.visibility,
                    countries: currentBrand.visibility.countries || [],
                  },
                },
                fields: [
                  {
                    type: 'checkbox',
                    name: 'visibility.countries',
                    select: true,
                    inline: true,
                    options: [
                      {
                        value: 'be',
                        label: 'Belgique',
                      },
                      {
                        value: 'fr',
                        label: 'France',
                      },
                      { label: 'Luxembourg', value: 'lu' },
                      {
                        value: 'nl',
                        label: 'Pays-Bas',
                      },
                    ],
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: currentBrand._id,
                      visibility: {
                        ...currentBrand.visibility,
                        countries: values.visibility.countries,
                      },
                    },
                    {
                      requestKey: `put visibility.countries /brands/${currentBrand._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.countries /brands/${currentBrand._id}`],
              },
            },
          ],
    [state.requests, currentBrand, mediasState.requests['post /medias'], locale, logo],
  )

  return (
    <>
      <ListGroupForm fields={fields} />
    </>
  )
}

export default BrandForm

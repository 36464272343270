import * as React from 'react'
import { Card as BsCard } from 'react-bootstrap'

interface CardProps {
  header?: React.ReactNode | string
  body?: React.ReactNode | string
  children?: React.ReactNode | string
  footer?: React.ReactNode | string
}

const Card: React.FunctionComponent<CardProps> = ({ header, body, footer, children }) => (
  <BsCard>
    <BsCard.Header>{header}</BsCard.Header>
    <BsCard.Body>{children || body}</BsCard.Body>
    <BsCard.Footer>{footer}</BsCard.Footer>
  </BsCard>
)
export default Card

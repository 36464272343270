import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import UserForm from './form'

const PasswordRecovery: React.FunctionComponent = () => (
  <PageCenteredForm
    title={'Complete your profile'}
    titleDescription={'You have been invited to create an account'}
    icon={<FontAwesomeIcon icon={faUser} size="2x" />}
  >
    <UserForm />
  </PageCenteredForm>
)

export default PasswordRecovery

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useUsers } from '_stores/users/store'
import { PasswordRecoveryPayload } from '_stores/users/types'

const LoginForm: React.FunctionComponent = () => {
  const [state, actions] = useUsers()

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email...',
      },
    ],
    [],
  )

  const request = state.requests['post /users/password-recovery']

  if (request && request.status === 'success')
    return (
      <>
        <div className='text-center text-success'>
          <FontAwesomeIcon icon={faCheck} className='mr-1 h2'/>
        </div>
        {request.message}
      </>
    )

  return (
    <Form
      fields={fields}
      initialValues={{}}
      validate={(values: any) => {
        const errors: any = {}
        if (!values.email) errors.email = 'Required field'
        return errors
      }}
      onSubmit={(values: PasswordRecoveryPayload) => actions.passwordRecovery(values)}
      request={state.requests['post /users/password-recovery']}
      submitLabel="Send me an email"
      submitLg
    />
  )
}

export default LoginForm

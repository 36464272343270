import * as React from 'react'
import { Organization, TurnoverTarget } from '_stores/organizations/types'
import { Column } from 'react-table'
import { sortBy } from 'lodash'
import { useOrganizations } from '_stores/organizations/store'
import RemoveConfirmation from '_components/remove-confirmation'

interface UseTurnoverTargetsTableProps {
  organization?: Organization
  onItemClick(item: TurnoverTarget): void
}

const useTurnoverTargetsTable = ({
  organization,
  onItemClick,
}: UseTurnoverTargetsTableProps): [Column[], TurnoverTarget[]] => {
  const [state, actions] = useOrganizations()
  const columns = React.useMemo(
    () =>
      !organization
        ? []
        : [
            {
              Header: "Chiffre d'affaire à atteindre",
              accessor: 'target',
              Cell: (data: any) => {
                const turnoverTarget: TurnoverTarget = data.cell.row.original
                return (
                  <a className="link" onClick={() => onItemClick(turnoverTarget)}>
                    {turnoverTarget.target}
                  </a>
                )
              },
            },
            {
              Header: 'Remise (%)',
              accessor: 'discount',
            },
            {
              Header: ' ',
              accessor: 'actions',
              Cell: (data: any) => {
                const turnoverTarget: TurnoverTarget = data.cell.row.original
                return (
                  <div className="text-right">
                    <RemoveConfirmation
                      onRemove={() => {
                        organization &&
                          actions.update({
                            _id: organization._id,
                            turnoverTargets: organization.turnoverTargets.filter(
                              a => a._id !== turnoverTarget._id,
                            ),
                          })
                      }}
                    />
                  </div>
                )
              },
            },
          ],
    [organization],
  )

  const turnoverTargets = React.useMemo(() => {
    return !organization || !organization.turnoverTargets
      ? []
      : sortBy(organization.turnoverTargets, 'target')
  }, [organization])

  return [columns, turnoverTargets]
}

export default useTurnoverTargetsTable

import * as React from 'react'
import { Link } from 'react-router-dom'
import { Order } from '_stores/orders/types'
import moment from 'moment'
import { Column } from 'react-table'
import { useOrders } from '_stores/orders/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const useListTable = (): [Column[], Order[]] => {
  const [state] = useOrders()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ref',
        accessor: 'organization.mafactData.ref',
        Cell: (data: any) => {
          const order: Order = data.cell.row.original
          return (
            <span>
              {(!order.mafactData || !order.mafactData.ref) && (
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 text-danger" />
              )}
              <Link to={`/orders/${order._id}`}>{order.mafactData?.ref || 'N/A'}</Link>
            </span>
          )
        },
      },
      {
        Header: 'Client',
        accessor: 'organization.name',
      },
      {
        Header: 'Utilisateur',
        accessor: 'creator.firstName',
        Cell: (data: any) => {
          const order: Order = data.cell.row.original
          return (
            <span>
              {order.creator.firstName[0]}. {order.creator.lastName}
            </span>
          )
        },
      },
      {
        Header: 'Produits',
        accessor: 'products',
        Cell: (data: any) => {
          const order: Order = data.cell.row.original
          return <span>{order.products.length}</span>
        },
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => {
          const order: Order = data.cell.row.original
          return <span>{moment(order.dates.created).format()}</span>
        },
      },
    ],
    [],
  )

  const orders = state.all

  return [columns, orders]
}

export default useListTable

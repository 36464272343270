import * as React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

export interface BaseFormFieldProps {
  name: string
  defaultValue?: any
  value?: any
  label?: string | React.ReactElement
  placeholder?: string
  info?: string
  error?: string
  form?: any
  touched?: boolean
  controlId?: string
  isValid?: boolean
  prepend?: string | React.ReactElement
  hideValid?: boolean
  autoFocus?: boolean
  onPrependClick?(e: React.MouseEvent<any>): void
  onBlur?(e: React.FocusEvent<any>): void
  onChange?(value: any): void
  onEnterPress?(value: any): void
  field?: any
  addFieldButtonLabel?: string
  min?: boolean
  max?: boolean
  multiple?: boolean
  group?: {
    label?: string
    onChange?(value: any): void
    name: string
    mainType?: string
  }
  setFieldValue?(fieldName: string, value: any): void
  values?: any
  inputLabel?: string | React.ReactElement
  disabled?: boolean
}

interface FieldMessages {
  info?: string
  error?: string
  touched?: boolean
}

export interface FormFieldProps extends BaseFormFieldProps {
  children: React.ReactElement
  className?: string
}

const FieldMessages: React.FunctionComponent<FieldMessages> = ({ info, error, touched }) => (
  <>
    {touched && error && <Form.Text className="text-danger">{error}</Form.Text>}
    {info && <Form.Text className="text-muted">{info}</Form.Text>}
  </>
)

export const FieldContainer: React.FunctionComponent<FormFieldProps> = props => {
  React.useEffect(() => {
    if (props.form && props.form.validateForm) props.form.validateForm()
  }, [])
  return (
    <Form.Group controlId={props.controlId} className={props.className}>
      {props.label && (
        <Form.Label>
          {props.label}
          {props.error && <span className={'text-danger'}>*</span>}
        </Form.Label>
      )}
      {props.prepend ? (
        <>
          <InputGroup>
            <InputGroup.Prepend
              onClick={props.onPrependClick}
              style={{
                cursor: props.onPrependClick && 'pointer',
              }}
            >
              <InputGroup.Text id="inputGroupPrepend">{props.prepend}</InputGroup.Text>
            </InputGroup.Prepend>
            {props.children}
          </InputGroup>
          <FieldMessages error={props.error} info={props.info} touched={props.touched} />
        </>
      ) : (
        <>
          {props.children}
          <FieldMessages error={props.error} info={props.info} touched={props.touched} />
        </>
      )}
    </Form.Group>
  )
}
export default FieldContainer

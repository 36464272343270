import * as React from 'react'
import { useCurrentApiKey } from '_stores/api-keys/store'
import ApiKeyForm from './form'
import { ApiKey } from '_stores/api-keys/types'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import ApiItemWrapper from '_containers/api-item-wrapper'

export interface ApiKeyItemProps {
  apiKey: ApiKey
}

const ApiKeyItem: React.FunctionComponent = () => {
  const [currentApiKey, actions] = useCurrentApiKey()
  const history = useHistory()

  return (
    <ApiItemWrapper item={currentApiKey} actions={actions}>
      <Modal show={true} onHide={() => history.push('/api-keys')}>
        <Modal.Header>{currentApiKey ? currentApiKey.name : 'New API key'}</Modal.Header>
        <Modal.Body>
          <ApiKeyForm />
        </Modal.Body>
      </Modal>
    </ApiItemWrapper>
  )
}

export default ApiKeyItem

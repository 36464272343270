import { Media } from '_stores/medias/types'

export enum AddressTypes {
  delivery = 'delivery',
  billing = 'billing',
  all = '*',
}

export enum CreatedVia {
  mafact = 'mafact',
  website = 'website',
  backOffice = 'back-office',
}

export enum ContactTypes {
  main = 'main',
  billing = 'billing',
  commercial = 'commercial',
  logistics = 'logistics',
}

export interface TurnoverTarget {
  _id?: string
  target: number
  discount: number
}

export interface OrganizationContact {
  firstName: string
  lastName: string
  email: string
  phone: string
  fax: string
  type: ContactTypes
}

export interface OrganizationAddress {
  street: string
  streetAddition?: string
  streetNumber: string
  streetBoxNumber?: string
  city: string
  zip: string
  country: string
  phone?: string
  email?: string
  type: AddressTypes
  isMainAddress: boolean
  isBillingAddress: boolean
  isDeliveryAddress: boolean
}

export interface Organization {
  _id?: string
  dates: {
    created: string
    lastUpdate: string
  }
  name: string
  logo?: string | Media
  country?: string
  active: boolean
  mafactData: {
    ref: string
    name: string
    nameAddition?: string
    category: string
    language: string
    country: string
    email: string
    location: string
    phone: string
    dates: {
      created?: string
      lastUpdate?: string
    }
  }
  addresses: OrganizationAddress[]
  turnoverTargets: TurnoverTarget[]
  createdVia: CreatedVia
  contacts: [OrganizationContact]
  lastMafactPricesSync: Date
}

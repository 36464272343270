import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { ButtonVariant } from 'react-bootstrap/esm/types'

interface HeaderButtonLinkProps {
  label: React.ReactElement | string
  icon?: IconDefinition
  to?: string
  onClick?(link: HeaderButtonLinkProps): void
  disabled?: boolean
  variant?: ButtonVariant
}

const LinkContent: React.FunctionComponent<HeaderButtonLinkProps> = ({ label, icon }) => (
  <>
    {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
    {label}
  </>
)

const HeaderButtonLink: React.FunctionComponent<HeaderButtonLinkProps> = props => {
  if (props.to)
    return (
      <LinkContainer to={props.to}>
        <Button variant={props.variant} className="mr-2" disabled={props.disabled}>
          <LinkContent {...props} />
        </Button>
      </LinkContainer>
    )
  return (
    <Button
      variant={props.variant}
      className="mr-2"
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick(props)}
    >
      <LinkContent {...props} />
    </Button>
  )
}

export default HeaderButtonLink

import * as React from 'react'
import { useArticles } from '_stores/articles/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Column } from 'react-table'
import moment from 'moment'
import HeaderButtonLink from '_components/header/button-link'
import { Button, Card } from 'react-bootstrap'
import InputField from '_components/form/fields/input'

const ArticlesList: React.FunctionComponent = () => {
  const [state, actions] = useArticles()
  const history = useHistory()
  React.useEffect(() => {
    actions.fetch()
  }, [])

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Title',
          accessor: (d: any) => <Link to={`/articles/${d._id}`}>{d.title}</Link>,
        },
        {
          Header: 'Date',
          width: '15%',
          accessor: (d: any) => <span>{moment(d.dates.created).format()}</span>,
        },
        {
          Header: 'Pub.',
          accessor: (d: any) =>
            d.published && (
              <div className="text-center">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            ),
          width: '5%',
        },
      ] as Column[],
    [],
  )

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">News</h1>
        <div className="ml-auto">
          <Button>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            New post
          </Button>
        </div>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <Table columns={columns} data={state.all} />
        </div>
        {/*<div className="p-4">
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
          />
          <Table columns={columns} data={state.all} />
  </div> */}
      </Card>
    </PageApp>
  )
}

export default ArticlesList

import * as React from 'react'
import classnames from 'classnames'
import Sidebar from '_components/sidebar'
import Header, { HeaderProps } from '_components/header'

interface PageAppProps {
  children: React.ReactNode
  className?: string
  contentClassName?: string
  header?: HeaderProps
}

const PageApp: React.FunctionComponent<PageAppProps> = props => {
  const [collapsed] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  const headerProps = props.header || {}
  return (
    <div className={classnames('app', props.className)}>
      <Header {...headerProps} />
      <Sidebar collapsed={collapsed} toggled={toggled} setToggled={setToggled} />
      {/*<Button onClick={() => setToggled(!toggled)} className="sidebar-toggle">
        <FontAwesomeIcon icon={faBars} />
  </Button> */}
      <div className={classnames('content', props.contentClassName)}>{props.children}</div>
    </div>
  )
}
export default PageApp

import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import {
  useCategories,
  useCategoriesForTreeSelectField,
  useCategoriesRequest,
  useCurrentCategory,
} from '_stores/categories/store'
import APP from '_constants/app'
import queryString from 'query-string'
import { useLocation } from 'react-router'

interface CategoryFormProps {
  defaultParent?: string
}

const CategoryForm: React.FunctionComponent<CategoryFormProps> = ({ defaultParent }) => {
  const [state, actions] = useCategories()
  const [currentCategory] = useCurrentCategory()
  const [request] = useCategoriesRequest()
  const location = useLocation()
  const query: any = queryString.parse(location.search)

  if (!defaultParent) defaultParent = query.parent

  const [categories] = useCategoriesForTreeSelectField({
    locale: 'fr',
    disabled: currentCategory && currentCategory._id,
  })
  React.useEffect(() => {
    actions.fetch()
    return () => actions.setCurrent()
  }, [])

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        name: 'parent',
        label: 'Catégorie parente',
        type: 'tree-select',
        options: categories,
        mode: 'radioSelect',
      },
      {
        name: 'labels',
        type: 'text',
        placeholder: 'Nom...',
        locales: APP.languagues,
        canDuplicateLocales: true,
      },
    ],
    [categories],
  )

  return (
    <>
      <Form
        fields={fields}
        initialValues={
          currentCategory
            ? { ...currentCategory, parent: currentCategory.parent ? [currentCategory.parent] : [] }
            : { parent: defaultParent ? [defaultParent] : [] }
        }
        validate={(values: any) => {
          const errors: any = {}
          APP.languagues.forEach(lang => {
            if (!values.labels || !values.labels[lang]) errors[`labels.${lang}`] = 'Champs requis'
          })
          return errors
        }}
        onSubmit={values => {
          if (values.parent) values.parent = values.parent[0]
          if (!values.parent) values.parent = null
          if (currentCategory && currentCategory._id) actions.update(values)
          else actions.create(values)
        }}
        request={request}
        submitLabel="Save"
      />
    </>
  )
}

export default CategoryForm

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheckCircle, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { Alert } from 'react-bootstrap'
import { ApiRequest } from '_core/store'

interface RequestMessageProps {
  request?: ApiRequest
  loader?: boolean
  check?: boolean
}

const RequestMessage: React.FunctionComponent<RequestMessageProps> = ({
  request,
  loader,
  check,
}) => {
  const [successCheck, displaySuccessCheck] = React.useState(false)

  React.useEffect(() => {
    if (request && request.status === 'success' && check) displaySuccessCheck(true)
    //TODO: manage this in store
    if (check) {
      const tm = setTimeout(() => {
        displaySuccessCheck(false)
      }, 3000)
      return () => {
        clearTimeout(tm)
      }
    }
  }, [request])
  if (!request) return <></>

  if (request && request.status === 'inProgress' && loader)
    return <FontAwesomeIcon icon={faSpinner} spin className="mr-1 ml-1" />

  if (!request.message)
    return (
      <>
        {successCheck && (
          <FontAwesomeIcon icon={faCheckCircle} className="mr-1 ml-1 text-success" />
        )}
      </>
    )

  return (
    <Alert variant={request.status === 'error' ? 'danger' : request.status}>
      {successCheck && <FontAwesomeIcon icon={faCheckCircle} className="mr-1 ml-1 text-success" />}
      {request.status === 'error' && (
        <FontAwesomeIcon icon={faExclamation} className="mr-2 text-danger" />
      )}
      {request.message}
    </Alert>
  )
}

export default RequestMessage

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { Page } from '_stores/pages/types'
import _ from 'lodash'

interface PageEditToolsBarProps {
  page: Page
  filterLocale(locale: string): void
  filteredLocales: string[]
  sessionToken?: string
  publish(pageId: string): void
}

const PageEditToolsBar: React.FunctionComponent<PageEditToolsBarProps> = ({
  page,
  filterLocale,
  filteredLocales,
  publish,
}) => {
  const isPublishable = React.useMemo(() => !_.isEqual(page.context, page.published), [
    page.context,
    page.published,
  ])

  return (
    <div className="d-flex page-tools-bar">
      {page.settings.previewUrl && (
        <Dropdown>
          <Dropdown.Toggle className="ml-2">Preview</Dropdown.Toggle>
          <Dropdown.Menu>
            {page.settings.locales.map(locale => (
              <Dropdown.Item
                key={locale}
                href={`${page.settings.previewUrl}?page=${page._id}&lang=${locale}`}
                target="_blank"
              >
                {locale}
              </Dropdown.Item>
            ))}
            <Dropdown.Item
              href={`${page.settings.previewUrl}?mode=template&page=${page._id}&lang=${page.settings.locales[0]}`}
              target="_blank"
            >
              Template
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <Dropdown>
        <Dropdown.Toggle className="ml-2">Locales</Dropdown.Toggle>

        <Dropdown.Menu>
          {page &&
            page.settings.locales.map(locale => (
              <Dropdown.Item key={locale} onClick={() => filterLocale(locale)}>
                {(!filteredLocales[0] || filteredLocales.includes(locale)) && (
                  <FontAwesomeIcon icon={faCheck} className="mr-1" />
                )}
                {locale}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
        <Button
          className="ml-2"
          disabled={!isPublishable}
          onClick={() => page._id && publish(page._id)}
        >
          Publish!
        </Button>
      </Dropdown>
    </div>
  )
}
export default PageEditToolsBar

import * as React from 'react'
import Form from '_components/form'
import { useUsers } from '_stores/users/store'
import { InvitePayload } from '_stores/users/types'
import useInviteFormFields from './hooks/use-invite-form-fields'

export interface InviteUserFormProps {
  excludedFields?: string[]
  initialValues?: any
}

const InviteUserForm: React.FunctionComponent<InviteUserFormProps> = ({
  excludedFields,
  initialValues,
}) => {
  const [state, actions] = useUsers()
  React.useEffect(() => {
    actions.clearRequest({ key: 'post /users/invite' })
  }, [])

  const [fields] = useInviteFormFields({
    excludedFields,
  })

  return (
    <Form
      fields={fields}
      initialValues={initialValues}
      validate={(values: any) => {
        const errors: any = {}
        if (!values.email) errors.email = 'Champs requis'
        if (!values.role) errors.role = 'Champs requis'
        if (!values.language) errors.language = 'Champs requis'
        return errors
      }}
      onSubmit={(values: InvitePayload) => actions.invite(values)}
      request={state.requests['post /users/invite']}
      success={() => <span className="text-success">Email envoyé au nouvel utilisateur</span>}
      submitLabel="Inviter"
      submitLg
    />
  )
}

export default InviteUserForm

import * as React from 'react'
import { useHistory } from 'react-router-dom'
import InviteUserModal from './invite-modal'

const InviteUser: React.FunctionComponent = () => {
  const history = useHistory()
  return (
    <InviteUserModal
      isOpen={true}
      setOpen={() => history.push('/users')}
      formProps={{
        excludedFields: ['role', 'language', 'organizationRole'],
        initialValues: { role: 'admin', language: 'fr' },
      }}
      description={
        <p>
          Inviter un nouvel administrateur de la plateforme. Cette personne aura{' '}
          <strong>les mêmes droits que vous</strong>.
        </p>
      }
    />
  )
}

export default InviteUser

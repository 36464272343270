//@ts-nocheck
/* eslint no-use-before-define: 0 */
const buttonIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.15 13.628A7.749 7.749 0 0 0 10 17.75a7.74 7.74 0 0 0 6.305-3.242l-2.387-2.127-2.765 2.244-4.389-4.496-3.614 3.5zm-.787-2.303l4.446-4.371 4.52 4.63 2.534-2.057 3.533 2.797c.23-.734.354-1.514.354-2.324a7.75 7.75 0 1 0-15.387 1.325zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"/></svg>'

export const make = function make(tagName: string, classNames = null, attributes = {}): void {
  const el = document.createElement(tagName)

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames)
  } else if (classNames) {
    el.classList.add(classNames)
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName]
  }

  return el
}

class EditorMedias {
  nodes: any
  constructor({ api, config, onSelectFile, readOnly }) {
    this.api = api
    this.config = config
    this.onSelectFile = onSelectFile
    this.readOnly = readOnly
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [this.CSS.imageContainer]),
      fileButton: this.createFileButton(),
      imagePreloader: make('div', this.CSS.imagePreloader),
    }
    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader)
    this.nodes.wrapper.appendChild(this.nodes.fileButton)
  }

  get CSS(): any {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
    }
  }

  render(): any {
    return this.nodes.wrapper
  }

  static get toolbox(): any {
    return {
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"/></svg>',
      title: 'Image',
    }
  }

  createFileButton(): any {
    const button = make('div', [this.CSS.button])
    button.innerHTML =
      this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select an Image')}`
    this.config.chooseMedias()
    button.addEventListener('click', () => {
      this.config.chooseMedias()
    })
    return button
  }

  save(): any {
    return {
      text: 'COOL',
    }
  }
}

export default EditorMedias
/* eslint no-use-before-define: 0 */

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface LoaderProps {
  message?: string
}

const Loader: React.FunctionComponent<LoaderProps> = ({ message }) => (
  <div className="align-middle text-center mt-5 pt-5" style={{ height: '100%' }}>
    <FontAwesomeIcon icon={faSpinner} spin size="6x" />
    {<p className="h1 mt-3">{message || 'Loading'}</p>}
  </div>
)

export default Loader

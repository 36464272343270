import { faRedo, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import Loader from '_components/loader'
import { ApiRequest } from '_core/store'
import { Organization } from '_stores/organizations/types'

export interface MafactDataField {
  label: string
  value?: string | number | React.ReactElement
}

const useMafactDataFields = (
  organization?: Organization,
  syncPrices?: (id: string) => void,
  syncPricesRequest?: ApiRequest,
): [MafactDataField[]] =>
  React.useMemo(
    () =>
      !organization
        ? [[]]
        : [
            [
              {
                label: 'Référence',
                value: organization.mafactData.ref,
              },
              {
                label: 'Nom',
                value: organization.mafactData.name,
              },
              {
                label: 'Nom 2',
                value: organization.mafactData.nameAddition,
              },
              {
                label: 'Langue',
                value: organization.mafactData.language,
              },
              {
                label: 'Ville',
                value: organization.mafactData.location,
              },
              {
                label: 'Pays',
                value: organization.mafactData.country,
              },
              {
                label: 'Email(s)',
                value: organization.mafactData.email,
              },
              {
                label: 'Téléphone',
                value: organization.mafactData.phone,
              },
              {
                label: 'Dernière synchronisation des prix',
                value: (
                  <>
                    {syncPricesRequest && syncPricesRequest.status === 'inProgress' ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <>
                        <Button
                          size="sm"
                          className="mr-1"
                          variant="link"
                          onClick={() =>
                            organization._id && syncPrices && syncPrices(organization._id)
                          }
                          disabled={
                            organization.lastMafactPricesSync &&
                            new Date().getTime() -
                              new Date(organization.lastMafactPricesSync).getTime() <
                              1000 * 60 * 2
                          }
                        >
                          <FontAwesomeIcon icon={faRedo} />
                        </Button>

                        {organization.lastMafactPricesSync
                          ? moment(organization.lastMafactPricesSync).format('DD/MM/YYYY HH:mm')
                          : 'N/A'}
                      </>
                    )}
                  </>
                ),
              },
            ],
          ],
    [organization, syncPricesRequest],
  )

export default useMafactDataFields

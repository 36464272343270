import * as React from 'react'
import Field, { BaseFormFieldProps } from '..'
import Dropzone from 'react-dropzone'

export type FilesFieldProps = BaseFormFieldProps

const FilesField: React.FunctionComponent<FilesFieldProps> = props => (
  <Field {...props}>
    <input
      type="file"
      onChange={e => props.onChange && props.onChange(e.target.files)}
      style={{ display: 'block' }}
      disabled={props.disabled}
    />
    {props.inputLabel}
  </Field>
)

export default FilesField

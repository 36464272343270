import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useUsers } from '_stores/users/store'
import { User } from '_stores/users/types'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

interface UserInviteUrlModalProps {
  user?: User
  isOpen: boolean
  setOpen(isOpen: boolean): void
}

const UserInviteUrlModal: React.FunctionComponent<UserInviteUrlModalProps> = ({
  user,
  isOpen,
  setOpen,
}) => {
  const [state, actions] = useUsers()

  React.useEffect(() => {
    user && actions.getInvitationToken({ id: user._id as string })
  }, [user, isOpen])

  const url = React.useMemo(
    () =>
      user && state.currentUserToken && state.currentUserToken.userId === user._id
        ? user.role === 'admin'
          ? `https://${window.location.hostname}/users/create?token=${state.currentUserToken.token}`
          : `${process.env.APP_HOST}/complete-profile?token=${state.currentUserToken.token}`
        : '',
    [state.currentUserToken, user],
  )

  const [urlCopied, setUrlCopied] = React.useState(false)

  React.useEffect(() => {
    if (urlCopied) {
      setTimeout(() => {
        setUrlCopied(false)
      }, 3000)
    }
  }, [urlCopied])

  return (
    <Modal show={isOpen} onHide={() => setOpen(false)}>
      <Modal.Header>URL d'inscription</Modal.Header>
      <Modal.Body>
        {user && state.currentUserToken && state.currentUserToken.userId === user._id && (
          <div>
            Ce lien permet à l'utilisateur de complèter et valider son compte: <br />
            <div className="mt-2 card card-body bg-light" style={{ overflowWrap: 'break-word' }}>
              {url}
            </div>
            <div className="mt-2 text-right">
              {urlCopied && (
                <span className="text-success mr-2">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              )}
              <CopyToClipboard text={url} onCopy={() => setUrlCopied(true)}>
                <Button variant="link" size="sm">
                  Copier dans le presse papier
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default UserInviteUrlModal

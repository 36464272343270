import * as React from 'react'
import { Column } from 'react-table'
import { useProducts } from '_stores/products/store'
import { Product } from '_stores/products/types'
import RemoveConfirmation from '_components/remove-confirmation'

interface useLinkedProductsTableProps {
  product?: Product
  locale: string
}

const useLinkedProductsTable = ({
  product,
  locale,
}: useLinkedProductsTableProps): [Column[], Product[]] => {
  const [state, actions] = useProducts()
  const columns: Column[] = React.useMemo(
    () =>
      !product
        ? []
        : [
            {
              Header: 'Nom',
              accessor: `name.${locale}`,
            },
            {
              Header: 'Actions',
              accessor: '',
              width: '15%',
              Cell: (data: any) => {
                const linkedProduct: Product = data.cell.row.original
                return (
                  <div className="text-right">
                    <RemoveConfirmation
                      onRemove={() =>
                        actions.update({
                          _id: product._id,
                          linkedProducts: product.linkedProducts.filter(
                            p => p._id !== linkedProduct._id,
                          ),
                        })
                      }
                    />
                  </div>
                )
              },
              canSort: false,
            },
          ],
    [locale, product],
  )

  const data: Product[] = React.useMemo(
    () => (!product || !product.linkedProducts ? [] : (product.linkedProducts as Product[])),
    [locale, product],
  )

  return [columns, data]
}
export default useLinkedProductsTable

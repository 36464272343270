import * as React from 'react'
import {
  useAttributes,
  useAttributesForSelectField,
  useAttributesRequest,
  useCurrentAttribute,
} from '_stores/attributes/store'
import { Attribute, AttributeFamilyEnum, AttributeTypeEnum } from '_stores/attributes/types'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { Breadcrumb, Button, Col, Modal, Row } from 'react-bootstrap'
import PageApp from '_containers/app-pages/page-app'
import ListGroupForm, { ListGroupFormField } from '_components/form/list-group'
import APP from '_constants/app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckDouble,
  faExclamationTriangle,
  faInfo,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap'
import { sortBy } from 'lodash'
import { buildLocalizedObject } from '_core/i18n'
import { Link } from 'react-router-dom'
import { Product } from '_stores/products/types'

export interface AttributeItemProps {
  attribute: Attribute
}

const Warning: React.FunctionComponent = () => (
  <small>
    Les modifications seront propagée à tous les produits
    <FontAwesomeIcon icon={faExclamationTriangle} className="ml-1 mr-2" />
  </small>
)

const AttributeItem: React.FunctionComponent = () => {
  const [currentAttribute, actions] = useCurrentAttribute()
  const [state] = useAttributes()

  const [showNewValue, setShowNewValue] = React.useState(false)
  const [valueToRemove, setValueToRemove] = React.useState<any>()
  const [request] = useAttributesRequest({
    name:
      currentAttribute &&
      valueToRemove &&
      `delete value_${valueToRemove._id} /attributes/${currentAttribute._id}/values/${valueToRemove._id}`,
  })

  const [attributesAsOptions] = useAttributesForSelectField({
    locale: 'fr',
    filter: attribute => {
      return attribute.family === AttributeFamilyEnum.productAttributeSection
    },
  })

  React.useEffect(() => {
    actions.setFilters({})
    actions.fetch()
  }, [])

  const locale = 'fr'

  React.useEffect(() => {
    if (
      state.requests[`post value_new /attributes/${currentAttribute?._id}/values`] &&
      state.requests[`post value_new /attributes/${currentAttribute?._id}/values`].status ===
        'success'
    ) {
      setShowNewValue(false)
      actions.clearRequest({ key: `post value_new /attributes/${currentAttribute?._id}/values` })
    }

    if (
      currentAttribute &&
      valueToRemove &&
      state.requests[
        `delete value_${valueToRemove._id} /attributes/${currentAttribute._id}/values/${valueToRemove._id}`
      ] &&
      state.requests[
        `delete value_${valueToRemove._id} /attributes/${currentAttribute._id}/values/${valueToRemove._id}`
      ].status === 'success'
    ) {
      setValueToRemove(undefined)
      actions.clearRequest({
        key: `delete value_${valueToRemove._id} /attributes/${currentAttribute._id}/values/${valueToRemove._id}`,
      })
    }
  }, [state.requests])

  const fields: ListGroupFormField[] = React.useMemo(
    () =>
      !currentAttribute
        ? []
        : [
            {
              label: 'Nom',
              value: currentAttribute?.labels[locale],
              formProps: {
                initialValues: currentAttribute,
                fields: [
                  {
                    locales: APP.languagues,
                    canDuplicateLocales: true,
                    type: 'text',
                    name: 'labels',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: currentAttribute._id,
                      labels: values.labels,
                    },
                    {
                      requestKey: `put labels /attributes/${currentAttribute._id}`,
                    },
                  )
                },
                request: state.requests[`put labels /attributes/${currentAttribute._id}`],
              },
            },
            currentAttribute?.family === AttributeFamilyEnum.productAttribute && {
              label: 'Section',
              value:
                currentAttribute?.attribute?.labels && currentAttribute?.attribute?.labels[locale],
              formProps: {
                initialValues: {
                  attribute: currentAttribute?.attribute?._id,
                },
                fields: [
                  {
                    name: 'attribute',
                    type: 'creatable-select',
                    placeholder: 'Section...',
                    options: attributesAsOptions,
                  },
                ],
                onSubmit: async (values: any) => {
                  let _id
                  if (values.attribute && typeof values.attribute === 'string') {
                    const res = await actions.create({
                      type: AttributeTypeEnum.text,
                      values: [],
                      family: AttributeFamilyEnum.productAttributeSection,
                      labels: {
                        fr: values.attribute,
                        en: values.attribute,
                        nl: values.attribute,
                      },
                    })
                    if (res && res._id) _id = res._id
                  } else if (values.attribute && values.attribute._id) {
                    _id = values.attribute._id
                  }
                  if (_id) {
                    actions.update(
                      {
                        _id: currentAttribute._id,
                        attribute: _id,
                      },
                      {
                        requestKey: `put attribute /attributes/${currentAttribute._id}`,
                      },
                    )
                  }
                },
                request: state.requests[`put attribute /attributes/${currentAttribute._id}`],
              },
            },
            currentAttribute?.family === AttributeFamilyEnum.productAttributeSection && {
              label: 'Position',
              value: currentAttribute?.positionIndex,
              formProps: {
                initialValues: {
                  positionIndex: currentAttribute?.positionIndex,
                },
                fields: [
                  {
                    name: 'positionIndex',
                    type: 'number',
                    placeholder: '',
                  },
                ],
                onSubmit: async (values: any) => {
                  actions.update(
                    {
                      _id: currentAttribute._id,
                      positionIndex: values.positionIndex,
                    },
                    {
                      requestKey: `put positionIndex /attributes/${currentAttribute._id}`,
                    },
                  )
                },
                request: state.requests[`put positionIndex /attributes/${currentAttribute._id}`],
              },
            },
          ].filter(field => field),
    [currentAttribute, state.all, attributesAsOptions],
  )
  const valuesFields: ListGroupFormField[] = React.useMemo(() => {
    const fields = []
    if (showNewValue) {
      fields.push({
        id: 'new',
        label: 'Nouvelle valeur',
        value: '',
        forceEditMode: true,
        onCancelClick: () => setShowNewValue(false),
        formProps: {
          fields: [
            {
              locales: APP.languagues,
              canDuplicateLocales: true,
              type: 'text',
              name: 'values',
            },
          ],
          onSubmit: (values: any) => {
            if (currentAttribute && currentAttribute._id) {
              actions.createValue(currentAttribute._id, values.values, {
                requestKey: `post value_new /attributes/${currentAttribute?._id}/values`,
              })
            }
          },
          request: state.requests[`post value_new /attributes/${currentAttribute?._id}/values`],
        },
      })
    }
    if (!currentAttribute || !currentAttribute.values) return fields
    sortBy(currentAttribute.values, locale).forEach(value =>
      fields.push({
        id: value._id,
        label: value[locale],
        value: value[locale],
        buttons: [
          <Button className="mr-2" onClick={() => setValueToRemove(value)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>,
        ],
        formProps: {
          initialValues: {
            values: {
              ...APP.languagues.reduce((localizedObject, language: string) => {
                localizedObject[language] = value[language]
                return localizedObject
              }, {}),
              _id: value._id,
            },
          },
          fields: [
            {
              locales: APP.languagues,
              canDuplicateLocales: true,
              type: 'text',
              name: 'values',
            },
          ],
          onSubmit: ({ values, _id }: any) => {
            const attributeValue = currentAttribute.values.find(v => v._id === value._id)
            if (!attributeValue) return
            attributeValue.fr = values.fr
            attributeValue.nl = values.nl
            attributeValue.en = values.en
            currentAttribute &&
              currentAttribute._id &&
              actions.updateValue(currentAttribute._id, attributeValue, {
                requestKey: `put value_${value._id} /attributes/${currentAttribute._id}/values/${value._id}`,
              })
          },
          request:
            state.requests[
              `put value_${value._id} /attributes/${currentAttribute._id}/values/${value._id}`
            ],
        },
      }),
    )
    return fields
  }, [currentAttribute, showNewValue])

  return (
    <ApiItemWrapper item={currentAttribute} actions={actions}>
      <PageApp>
        <Breadcrumb>
          <LinkContainer to="/attributes">
            <Breadcrumb.Item>Attributes</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            {currentAttribute
              ? `${currentAttribute.labels.fr} (${currentAttribute.family})`
              : 'Nouvel attribut'}
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr className="mb-4"></hr>

        <Row className="justify-content-center">
          <Col xl={8}>
            <Row>
              <Col xl={12}>
                <div className="mb-4">
                  <h4>
                    <FontAwesomeIcon icon={faInfo} className="mr-2" />
                    Général
                  </h4>
                  <p>
                    Visualiser et modifier les données de bases de{' '}
                    <strong>{currentAttribute && currentAttribute.labels[locale]}</strong>.
                  </p>
                  <ListGroupForm fields={fields} info={<Warning />} />
                </div>
                {currentAttribute?.family === AttributeFamilyEnum.productAttribute && (
                  <div className="mb-4">
                    <h4>
                      <FontAwesomeIcon icon={faCheckDouble} className="mr-2" />
                      Valeurs
                    </h4>
                    <p>
                      Visualiser et modifier les valeurs attribuées à{' '}
                      <strong>{currentAttribute && currentAttribute.labels[locale]}</strong>.
                    </p>
                    {!showNewValue && (
                      <Button onClick={() => setShowNewValue(true)} className="mb-3" size="sm">
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                        Ajouter une valeur
                      </Button>
                    )}
                    {valuesFields[0] && <ListGroupForm fields={valuesFields} info={<Warning />} />}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal show={Boolean(valueToRemove)} onHide={() => setValueToRemove(undefined)}>
          <Modal.Header>Suppression d'un attribut</Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir supprimer <strong>{valueToRemove?.labels[locale]}</strong>{' '}
            ?
            {request && request.status === 'error' && (
              <>
                <p className="mt-3 text-danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                  Impossible de supprimer l'attribut car il est utilisé par{' '}
                  {`${request.error?.data.products.length} produit(s).`}
                </p>
                {request.error?.data &&
                  request.error?.data.products &&
                  request.error?.data.products.map((product: Product) => (
                    <p key={product._id}>
                      <Link to={`/products/${product._id}`}> {product.name.fr}</Link>
                    </p>
                  ))}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <>
              <Button variant="default" onClick={() => setValueToRemove(undefined)}>
                Annuler
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  currentAttribute &&
                  currentAttribute._id &&
                  valueToRemove &&
                  valueToRemove._id &&
                  actions.deleteValue(currentAttribute._id, valueToRemove._id, {
                    requestKey: `delete value_${valueToRemove._id} /attributes/${currentAttribute._id}/values/${valueToRemove._id}`,
                  })
                }
              >
                Supprimer
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      </PageApp>
    </ApiItemWrapper>
  )
}

export default AttributeItem

import * as React from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

import Dropzone from 'react-dropzone'
import Loader from '_components/loader'
import RequestMessage from '_components/request-message'
import { ApiRequest } from '_core/store'

interface OverlayDropZoneProps {
  onDrop(files: any[]): void
  forceShow?: boolean
  onHide(): void
  request?: ApiRequest
  accept?: string
  multiple?: boolean
}

const OverlayDropZone: React.FunctionComponent<OverlayDropZoneProps> = ({
  onDrop,
  forceShow,
  onHide,
  request,
  accept,
  multiple,
}) => {
  let lastDragEvent = new Date().getTime()
  const [isVisible, setVisible] = React.useState(false)
  const show = () => {
    lastDragEvent = new Date().getTime()
    setVisible(true)
  }
  const hide = () => {
    const now = new Date().getTime()
    lastDragEvent = now
    setTimeout(() => {
      if (now === lastDragEvent) setVisible(false)
    }, 100)
  }
  React.useEffect(() => {
    window.addEventListener('dragover', show)
    window.addEventListener('dragleave', hide)
    return () => {
      window.removeEventListener('dragover', show)
      window.removeEventListener('dragleave', hide)
    }
  }, [])

  React.useEffect(() => {
    if (forceShow !== undefined) setVisible(forceShow)
  }, [forceShow])

  React.useEffect(() => {
    if (!isVisible) onHide()
  }, [isVisible])

  React.useEffect(() => {
    if (request && request.status === 'success') setVisible(false)
  }, [request])

  return (
    <Dropzone
      accept={accept}
      multiple={multiple}
      onDrop={acceptedFiles => {
        onDrop(acceptedFiles)
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classNames('overlay-dropzone', { hide: !isVisible })}>
          {request && request.status === 'inProgress' ? (
            <div className="in-progress">
              <Loader message={request.loaded} />
            </div>
          ) : (
            <div {...getRootProps()} className="input">
              <div>
                {isVisible && <input {...getInputProps()} />}
                <p>Déposez vos fichiers ici </p>
                <Button>Sélectionner</Button>
                <Button
                  variant="dark"
                  className="ml-3"
                  onClick={e => {
                    e.stopPropagation()
                    setVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <div className="mt-5" style={{ fontSize: 12 }}>
                  <RequestMessage request={request} />
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </Dropzone>
  )
}

export default OverlayDropZone

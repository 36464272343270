import * as React from 'react'
import { FormFieldProp } from '_components/form/types'

const useListFiltersFields = (): [FormFieldProp[]] => {
  const fields = React.useMemo(
    () => [
      {
        name: 'filters',
        type: 'section',
        section: {
          fields: [
            {
              field: {
                name: 'family',
                type: 'react-select',
                multiple: true,
                label: 'Groupe',
                options: [
                  {
                    label: 'Caractéristique des produits',
                    value: 'product-attribute',
                  },
                  {
                    label: 'Fichier lié aux produits',
                    value: 'product-filename',
                  },
                  {
                    label: 'Section de caractéristiques des produits',
                    value: 'product-attribute-section',
                  },
                ],
              },
              colProps: {
                md: '6',
              },
            },
          ],
        },
      },
    ],
    [],
  )
  return [fields]
}

export default useListFiltersFields

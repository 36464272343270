import * as React from 'react'
import { Link } from 'react-router-dom'
import { Product } from '_stores/products/types'

export interface MafactDataField {
  label: string
  value?: string | number | React.ReactElement
}

const useMafactDataFields = (
  product?: Product,
): [MafactDataField[], MafactDataField[], MafactDataField[]] =>
  React.useMemo(
    () =>
      !product
        ? [[], [], []]
        : [
            [
              {
                label: 'Référence Mafact',
                value: product.mafactData.ref,
              },
              {
                label: 'Marque',
                value: product.brand && (
                  <Link to={`/brands/${product.brand._id}`}>{product.brand.name}</Link>
                ),
              },
              {
                label: 'Nom FR dans Mafact',
                value: product.mafactData.name.fr,
              },
              {
                label: 'Catégorie dans Mafact',
                value: product.mafactData.category,
              },
              {
                label: 'EAN',
                value: product.mafactData.ean,
              },
              {
                label: 'Prix HTVA',
                value: product.pricing.price,
              },
              {
                label: 'SRP',
                value: `${product.pricing.srp}`,
              },
              {
                label: 'TVA',
                value: `${product.pricing.vat}%`,
              },
              {
                label: 'Stock',
                value: `${product.stock.available} / ${product.stock.total}`,
              },
              {
                label: 'Codebar',
                value: product.mafactData.codebar,
              },
            ],
            [
              {
                label: 'Prix HTVA',
                value: product.pricing.price,
              },
              {
                label: 'TVA',
                value: `${product.pricing.vat}%`,
              },
              {
                label: 'SRP',
                value: `${product.pricing.srp}`,
              },
              {
                label: 'Taxes',
                value: `${product.pricing.taxes}%`,
              },
              {
                label: 'Auvibel',
                value: product.pricing.auvibel,
              },
              {
                label: 'Bebat',
                value: `${product.pricing.bebat}%`,
              },
              {
                label: 'Recupel',
                value: product.pricing.recupel,
              },
            ],
            [
              {
                label: 'Poids',
                value: product.packaging.weight,
              },
              {
                label: 'Volume',
                value: product.packaging.volume,
              },
              {
                label: 'Surface',
                value: product.packaging.surface,
              },
              {
                label: 'Longueur',
                value: product.packaging.length,
              },
              {
                label: 'Unité par carton',
                value: product.packaging.byCardboard,
              },
              {
                label: 'Unité par palette',
                value: product.packaging.byPallet,
              },
            ],
          ],
    [product],
  )

export default useMafactDataFields

import * as React from 'react'
import Card from '_components/card'
import PageCenteredContent from './page-centered-content'

interface PageCenteredFormProps {
  title?: string | React.ReactNode
  children: React.ReactNode
  footer?: string | React.ReactNode
}

const PageCenteredForm: React.FunctionComponent<PageCenteredFormProps> = props => (
  <PageCenteredContent className="centered-form">
    <div className='d-flex justify-content-center h-100 ig-content-view'>
      <Card 
        header={props.title}
        body={props.children}
        footer={props.footer}
      />
    </div>
  </PageCenteredContent>
)

export default PageCenteredForm

import * as React from 'react'
import { Organization, TurnoverTarget } from '_stores/organizations/types'
import useTurnoverTargetFormFields from '../hooks/use-turnover-target-form-fields'
import Form from '_components/form'
import { useOrganizations } from '_stores/organizations/store'

interface TurnoverTargetFormProps {
  organization: Organization
  turnoverTarget?: TurnoverTarget
  onSubmit?(values: any): void
}

const TurnoverTargetForm: React.FunctionComponent<TurnoverTargetFormProps> = ({
  organization,
  turnoverTarget,
  onSubmit,
}) => {
  const [fields] = useTurnoverTargetFormFields()
  const [state, actions] = useOrganizations()

  return (
    <Form
      fields={fields}
      initialValues={turnoverTarget || {}}
      validate={values => {
        const errors: any = {}
        if (!values.target) errors.target = 'Champ requis'
        if (!values.discount) errors.discount = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        const turnoverTargets = organization.turnoverTargets || []
        if (turnoverTarget) {
          const existingIndex = turnoverTargets.findIndex(t => t._id === turnoverTarget._id)
          if (existingIndex !== -1) {
            turnoverTargets[existingIndex] = values
          }
        } else {
          turnoverTargets.push(values)
        }
        actions.update({
          _id: organization._id,
          turnoverTargets,
        })
        onSubmit && onSubmit(values)
      }}
    />
  )
}

export default TurnoverTargetForm

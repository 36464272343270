import * as React from 'react'
import { useCurrentBrand } from '_stores/brands/store'
import BrandForm from './form'
import { Brand } from '_stores/brands/types'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'
import APP from '_constants/app'
import classNames from 'classnames'

export interface BrandItemProps {
  brand: Brand
}

const BrandItem: React.FunctionComponent = () => {
  const [currentBrand, actions] = useCurrentBrand()
  const [currentLocale, setCurrentLocale] = React.useState('fr')

  return (
    <PageApp>
      <Breadcrumb>
        <LinkContainer to="/brands">
          <Breadcrumb.Item>Brands</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{currentBrand && currentBrand.name}</Breadcrumb.Item>
      </Breadcrumb>
      <hr className="mb-4"></hr>
      <ApiItemWrapper item={currentBrand} actions={actions}>
        <Row className="justify-content-center">
          <Col xl={10}>
            <Row>
              <Col xl={9}>
                <div className="mb-4">
                  <h4>
                    <FontAwesomeIcon icon={faCopyright} className="mr-2" />
                    Marque
                  </h4>
                  <p>Visualiser et modifier les données de bases d'une marque.</p>
                  <BrandForm locale={currentLocale} />
                </div>
              </Col>
              <Col xl={3}>
                <nav className="navbar rightbar">
                  <nav className="nav">
                    <div className="nav-link pb-0 h6">Langue d'affichage</div>
                    {APP.languagues.map(locale => (
                      <a
                        key={locale}
                        className={classNames('nav-link', { active: locale === currentLocale })}
                        onClick={() => setCurrentLocale(locale)}
                        style={{ cursor: 'pointer' }}
                      >
                        {locale}
                      </a>
                    ))}
                  </nav>
                </nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </ApiItemWrapper>
    </PageApp>
  )
}

export default BrandItem

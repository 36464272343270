import * as React from 'react'
import { useSession } from '_stores/session/store'
import { useHistory } from 'react-router-dom'
import ConfirmationModal from '_components/confirmation-modal'

const Logout: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  const history = useHistory()
  return (
    <>
      <ConfirmationModal
        isOpen={true}
        confirm={() => actions.logout()}
        cancel={() => history.goBack()}
        title={'Log out'}
        description={'Are you sure you want to log out?'}
      />
    </>
  )
}

export default Logout

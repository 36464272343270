import { faEnvelope, faFax, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Order } from '_stores/orders/types'

export interface MafactDataField {
  label: string
  value?: string | number | React.ReactElement
}

const useDataFields = (order?: Order): [MafactDataField[]] =>
  React.useMemo(
    () =>
      !order
        ? [[]]
        : [
            [
              {
                label: 'Référence Mafact',
                value: order.mafactData && order.mafactData.ref,
              },
              {
                label: 'Client',
                value: (
                  <Link to={`/organizations/${order.organization._id}`}>
                    {order.organization.name}
                  </Link>
                ),
              },
              {
                label: 'Date',
                value: <span>{moment(order.dates.created).format('DD/MM/YYYY HH:mm')}</span>,
              },
              {
                label: 'Créé par',
                value: `${order.creator.firstName} ${order.creator.lastName}`,
              },
              {
                label: 'Addresse de livraison',
                value: order.deliveryAddress && (
                  <p>
                    {order.deliveryAddress.street} {order.deliveryAddress.streetNumber}{' '}
                    {order.deliveryAddress.streetBoxNumber}
                    <br />
                    {order.deliveryAddress.zip} {order.deliveryAddress.city} -{' '}
                    {order.deliveryAddress.country}
                  </p>
                ),
              },
              {
                label: 'Contact de livraison',
                value: order.contact && (
                  <p>
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" title="email" />
                    {order.contact.email}
                    <br />
                    <FontAwesomeIcon icon={faPhone} className="mr-2" title="téléphone" />
                    {order.contact.phone}
                    {order.contact.fax && (
                      <>
                        <FontAwesomeIcon icon={faFax} className="ml-2 mr-2" title="fax" />
                        {order.contact.fax}
                      </>
                    )}
                  </p>
                ),
              },
            ],
          ],
    [order],
  )

export default useDataFields

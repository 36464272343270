import * as React from 'react'
import routes, { getRoute } from '../routes'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import { useSession } from '_stores/session/store'

const Anonymous: React.FunctionComponent = () => {
  const history = useHistory()
  const [sessionState] = useSession()

  React.useEffect(() => {
    if (sessionState.logged) return history.replace('/products')
  }, [sessionState.logged])

  return (
    <Switch>
      {routes
        .filter(route => !route.logged || route.logged === 'common')
        .map(route => (
          <Route
            key={Array.isArray(route.path) ? route.path[0] : route.path}
            path={route.path}
            component={route.component}
            exact
          />
        ))}
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
  )
}

export default Anonymous

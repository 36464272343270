import * as React from 'react'
import APP from '_constants/app'
import { useCurrentProduct, useProducts } from '_stores/products/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Modal } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import Table from '_components/table'
import AutoSaveField from '_components/form/auto-save-field'
import {
  useAttributes,
  useAttributesForSelectField,
  useAttributesRequest,
} from '_stores/attributes/store'
import { Attribute, AttributeFamilyEnum, AttributeTypeEnum } from '_stores/attributes/types'
import AttributeCreation from '_containers/attributes/creation'
import Form from '_components/form'
import { ProductAttribute } from '_stores/products/types'
import { orderBy, sortBy } from 'lodash'
//@ts-ignore
import m from 'match-string'
import { buildLocalizedObject } from '_core/i18n'
import RemoveConfirmation from '_components/remove-confirmation'

interface CharacteristicsFormProps {
  locale: string
}

const CharacteristicsForm: React.FunctionComponent<CharacteristicsFormProps> = ({ locale }) => {
  const [state, actions] = useProducts()
  const [currentProduct] = useCurrentProduct()
  const [attributesState, attributesActions] = useAttributes()
  //Get attributes option for creatable-select field
  /*const [attributesAsOptions] = useAttributesForSelectField({
    locale,
    filter: attribute => attribute.family === AttributeFamilyEnum.productAttribute,
  })*/
  //Current attribute state (new attribute to add)
  const [currentAttribute, setCurrentAttribute] = React.useState<Attribute>()
  //Current attribute value (new attribute to add)
  const [currentValue, setCurrentValue] = React.useState<any>()
  //State to show or hide new attribute modal
  const [attributeCreation, setAttributeCreation] = React.useState<string>()
  //State to show or hide new value modal
  const [valueCreation, setValueCreation] = React.useState<string>()
  //Get attribute creation request
  const [request] = useAttributesRequest({ method: 'post' })
  //Get attribute update request
  const [putRequest] = useAttributesRequest({
    name:
      currentAttribute && currentAttribute._id && `post /attributes/${currentAttribute._id}/values`,
  })
  //Search state
  const [search, setSearch] = React.useState<string>()

  //Fetch all attributes on mount
  React.useEffect(() => {
    attributesActions.fetch()
  }, [])

  //Add creatable attribute in options
  const attributesOptions = React.useMemo(() => {
    if (currentAttribute && currentAttribute.value === '__new__')
      return [currentAttribute, ...attributesState.all]

    const attributesAsOptions = attributesState.all
      .filter(a => a.family === AttributeFamilyEnum.productAttribute)
      .map(attribute => ({
        ...attribute,
        label: attribute.labels[locale || 'fr'],
        value: attribute._id,
      }))

    return orderBy(
      attributesAsOptions.filter(
        attribute => !currentProduct?.attributes.some(a => a.item === attribute.value),
      ),
      [attribute => attribute.labels[locale].toLowerCase()],
    )
  }, [attributesState.all, currentAttribute])

  //Check if attribute is created to set current attribute and close modal
  //Use state to interpret the change of status of the request only once
  const [lastRequestDate, setLastRequestDate] = React.useState<number>()
  React.useEffect(() => {
    if (request && request.status === 'success' && lastRequestDate !== request.lastCallDate) {
      setLastRequestDate(request.lastCallDate)
      if (request.response) setCurrentAttribute(request.response.result)
      setAttributeCreation(undefined)
    }
  }, [request, lastRequestDate])

  //Check if value is created to set current value and close modal
  //Use state to interpret the change of status of the request only once
  const [lastPutRequestDate, setPutLastRequestDate] = React.useState<number>()

  React.useEffect(() => {
    if (
      putRequest &&
      putRequest.status === 'success' &&
      lastPutRequestDate !== putRequest.lastCallDate
    ) {
      setPutLastRequestDate(putRequest.lastCallDate)
      setValueCreation(undefined)
      if (putRequest.response) {
        const value =
          putRequest.response.result.values[putRequest.response.result.values.length - 1]
        setCurrentValue({ ...value, label: value[locale], value: value._id })
        if (currentAttribute) {
          setCurrentAttribute({
            ...currentAttribute,
            values: [
              ...currentAttribute.values,
              { ...value, label: value[locale], value: value._id },
            ],
          })
        }
      }
      // setValueCreation(undefined)
    }
  }, [putRequest, lastPutRequestDate])

  //Table columns
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Nom',
        accessor: 'item',
        width: '50%',
        Cell: (data: any) => {
          const attribute: ProductAttribute = data.cell.row.original
          if (attribute._id) {
            return <div>{attribute[locale]}</div>
          }
          return (
            <>
              <div>
                <AutoSaveField
                  field={{
                    type: 'creatable-select',
                    name: 'item',
                    options: attributesOptions,
                    multiple: true,
                  }}
                  initialValue={currentAttribute && currentAttribute._id}
                  onSubmit={(value: any) => {
                    setCurrentValue(undefined)
                    if (value && !value.value && currentProduct) {
                      setCurrentAttribute({
                        _id: '__new__',
                        value: '__new__',
                        label: value,
                      })
                      //setAttributeCreation(value)
                    } else if (value) {
                      setCurrentAttribute(value)
                    }
                  }}
                  forceEditableMode
                />
              </div>
              {currentAttribute && currentAttribute._id === '__new__' && (
                <div className="mt-2">
                  <Button size="sm" onClick={() => setAttributeCreation(currentAttribute.label)}>
                    Traduire
                  </Button>
                </div>
              )}
            </>
          )
        },
      },
      {
        Header: 'Valeur',
        accessor: 'value',
        width: '50%',
        Cell: (data: any) => {
          const attribute: ProductAttribute = data.cell.row.original
          if (attribute._id) {
            return <div>{attribute.value[locale]}</div>
          }
          return (
            <>
              <div>
                <AutoSaveField
                  field={{
                    type: 'creatable-select',
                    name: 'value',
                    options:
                      !currentAttribute || !currentAttribute.values
                        ? []
                        : sortBy(
                            currentAttribute.values.map(value => ({
                              ...value,
                              value: value._id,
                              label: value[locale],
                            })),
                            `label`,
                          ),
                  }}
                  initialValue={currentValue && currentValue.value}
                  onSubmit={async (value: any) => {
                    if (value && !value.value && currentAttribute) {
                      const newValue = {
                        _id: '__new__',
                        value: '__new__',
                        label: value,
                        [locale]: value,
                      }
                      setCurrentValue(newValue)
                      const currentAttributeValues = currentAttribute.values
                        ? [...currentAttribute.values, newValue]
                        : [newValue]
                      setCurrentAttribute({ ...currentAttribute, values: currentAttributeValues })
                      //setValueCreation(value)
                      /*
                  await attributesActions.update({
                    _id: currentAttribute._id,
                    values: [...currentAttribute.values, value],
                  })*/
                    } else {
                      setCurrentValue(value)
                    }
                  }}
                  forceEditableMode
                />
              </div>
              {currentAttribute && currentValue && (
                <div className="text-right mt-2">
                  {currentAttribute._id !== '__new__' && currentValue._id === '__new__' && (
                    <Button
                      size="sm"
                      onClick={() => setValueCreation(currentValue.label)}
                      className="mr-2"
                    >
                      Traduire
                    </Button>
                  )}
                  <Button
                    size="sm"
                    onClick={async () => {
                      if (!currentProduct) return
                      let attributeId = currentAttribute._id
                      let attributeLabels = currentAttribute.labels
                      let valueId = currentValue._id
                      let valueLabels = currentValue

                      if (currentAttribute._id === '__new__') {
                        const res = await attributesActions.create({
                          labels: buildLocalizedObject(currentAttribute.label),
                          type: AttributeTypeEnum.text,
                          family: AttributeFamilyEnum.productAttribute,
                          values: [buildLocalizedObject(currentValue.label)],
                        })

                        attributeId = res._id
                        attributeLabels = res.labels
                        valueId = res.values[0]._id
                        valueLabels = res.values[0]
                        delete valueLabels._id
                      } else if (currentValue._id === '__new__') {
                        const valueLabels = buildLocalizedObject(currentValue.fr)
                        if (attributeId) {
                          const res = await attributesActions.createValue(attributeId, valueLabels)
                          valueId = res.values[res.values.length - 1]
                        }
                      }
                      actions.update(
                        {
                          _id: currentProduct._id,
                          attributes: [
                            ...currentProduct.attributes,
                            {
                              item: attributeId as string,
                              ...attributeLabels,
                              value: {
                                item: valueId as string,
                                ...valueLabels,
                              },
                            },
                          ],
                        },
                        {
                          requestKey: `put attributes /products/${currentProduct._id}`,
                        },
                      )
                      setCurrentValue(undefined)
                      setCurrentAttribute(undefined)
                      attributesActions.clearRequest({
                        key: 'post /attributes',
                      })
                    }}
                  >
                    Ajouter
                  </Button>{' '}
                </div>
              )}
            </>
          )
        },
      },
      {
        Header: ' ',
        accessor: (attribute: ProductAttribute) => (
          <div className="text-right">
            {attribute._id && (
              <RemoveConfirmation
                onRemove={() =>
                  currentProduct &&
                  actions.update({
                    _id: currentProduct._id,
                    attributes: currentProduct.attributes.filter(a => a._id !== attribute._id),
                  })
                }
              />
            )}
          </div>
        ),
      },
    ]
  }, [attributesOptions, currentAttribute, currentValue])

  //Show fields to add attribute to project state
  const [showFields, setShowFields] = React.useState(false)

  //Table rows (data)
  const data = React.useMemo(() => {
    if (!currentProduct) return []
    const rows = [
      ...orderBy(
        currentProduct.attributes.filter(attribute => m(search, 'i').exec(attribute[locale])),
        [attribute => attribute[locale].toLowerCase()],
      ),
    ]
    //@ts-ignore
    rows.push({})
    return rows
  }, [currentProduct, showFields, search])

  return (
    <>
      <Card>
        <div className="p-4">
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
          />
          <Table columns={columns} data={data} />
        </div>
      </Card>
      {/*<div className="d-flex justify-content-end mt-2">
        <Button
          size="sm"
          variant={'link'}
          className="mb-2"
          onClick={() => setShowFields(!showFields)}
        >
          <FontAwesomeIcon icon={showFields ? faBan : faPlusCircle} className="mr-1" />
          {showFields ? 'Annuler' : 'Ajouter une charactéristique'}
        </Button>
  </div> */}
      {attributeCreation && (
        <AttributeCreation
          defaultLabel={attributeCreation}
          defaultDuplicate={false}
          onHide={() => setAttributeCreation(undefined)}
        />
      )}
      {
        <Modal show={Boolean(valueCreation)} onHide={() => setValueCreation(undefined)}>
          <Modal.Header>
            Nouvelle valeur pour {currentAttribute?.labels && currentAttribute?.labels[locale]}
          </Modal.Header>
          <Modal.Body>
            <Form
              fields={[
                {
                  locales: APP.languagues,
                  canDuplicateLocales: true,
                  defaultDuplicateLocales: false,
                  name: 'labels',
                  type: 'text',
                  placeholder: 'Nom...',
                },
              ]}
              onSubmit={(values: any) => {
                delete values._id
                if (currentAttribute && currentAttribute._id) {
                  attributesActions.createValue(currentAttribute._id, values.labels)
                }
              }}
              initialValues={{
                labels: buildLocalizedObject(valueCreation),
              }}
            />
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default CharacteristicsForm

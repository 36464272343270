import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import {
  User,
  PasswordRecoveryPayload,
  ResetPasswordPayload,
  InvitePayload,
  CreatePayload,
  CompleteProfilePayload,
  UpdateUserPayload,
  DeleteUserOrganizationPayload,
} from './types'
import { ApiRequest, ClearRequestPayload, genericActions, ResourceState } from '_core/store'

export interface UsersState extends ResourceState<User> {
  all: User[]
  currentId?: string
  resetPasswordToken?: string
  currentUserToken?: {
    userId: string
    token: string
  }
}
export type UsersApi = StoreActionApi<UsersState>

const initialState: UsersState = {
  endpoint: '/users',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {},
  resetPasswordToken: undefined,
  currentUserToken: undefined,
}

const actions = {
  fetch: (query: any) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiFetch(query))
  },
  get: (_id: string) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: CreatePayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiCreate(data))
  },
  update: (data: UpdateUserPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: CreatePayload | UpdateUserPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: UsersApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  updateOrganization: (data: { userId: string; organizationId: string; role: string }) => async ({
    dispatch,
  }: UsersApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/users/${data.userId}/organizations/${data.organizationId}`,
        method: 'PUT',
        data: { role: data.role },
      }),
    )
    if (res && res.result) await dispatch(actions.get(res.result._id))
  },
  deleteOrganization: (data: DeleteUserOrganizationPayload) => async ({ dispatch }: UsersApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/users/${data.userId}/organizations/${data.organizationId}`,
        method: 'DELETE',
      }),
    )
    if (res && res.result) await dispatch(actions.get(res.result._id))
  },
  setCurrent: (user?: Partial<User>) => ({ dispatch }: UsersApi) => {
    dispatch(genericActions.setCurrent(user))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: UsersApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: UsersApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: UsersApi) =>
    dispatch(genericActions.getRequest(method, withId)),
  invite: (data: InvitePayload) => async ({ setState, getState, dispatch }: UsersApi) => {
    const res = await dispatch(
      genericActions.api({
        url: '/users/invite',
        method: 'post',
        data,
      }),
    )
    if (res && res.result) {
      setState({
        all: [...getState().all, res.result],
      })
    }
  },
  passwordRecovery: (data: PasswordRecoveryPayload) => async ({ dispatch }: UsersApi) => {
    await dispatch(
      genericActions.api({
        url: '/users/password-recovery',
        method: 'post',
        data,
      }),
    )
  },
  resetPassword: (data: ResetPasswordPayload) => async ({ setState, dispatch }: UsersApi) => {
    const res = await dispatch(
      genericActions.api({
        url: '/users/reset-password',
        method: 'post',
        data,
      }),
    )
    if (res && res.result && res.result.token)
      setState({
        resetPasswordToken: res.result.token,
      })
  },
  completeProfile: (data: CompleteProfilePayload) => async ({ dispatch }: UsersApi) => {
    const res = await dispatch(
      genericActions.api({
        url: '/users/complete-profile',
        method: 'post',
        data,
      }),
    )
    if (res && res.result && res.result.token)
      location.replace(`/is-auth?token=${res.result.token}`)
  },
  getInvitationToken: (data: { id: string }) => async ({ dispatch, setState }: UsersApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/users/${data.id}/invite-token`,
        method: 'get',
      }),
    )
    if (res && res.result && res.result.token)
      setState({
        currentUserToken: {
          userId: data.id,
          token: res.result.token,
        },
      })
  },
}

export type UsersActions = typeof actions

const Store = createStore<UsersState, UsersActions>({
  name: 'users',
  initialState,
  actions,
})

export const UsersSubscriber = createSubscriber(Store)
export const useUsers = createHook(Store)
export const useCurrentUser = createHook(Store, {
  selector: (state: UsersState) => {
    if (state.currentId) return state.all.find(user => user._id === state.currentId)
    return
  },
})
export const useUsersRequest = createHook(Store, {
  selector: (
    state: UsersState,
    props: { method?: string; id?: string; name?: string },
  ): ApiRequest | undefined => {
    const { requests, endpoint } = state
    if (props.name) return requests[props.name]
    if (props.method && props.id) return requests[`${props.method} ${endpoint}/${props.id}`]
    else if (props.method) return requests[`${props.method} ${endpoint}`]
    return
  },
})

export const UsersContainer = createContainer(Store)

export default Store

import * as React from 'react'
import Form from '_components/form'
import { useSession } from '_stores/session/store'
import { UpdateProfilePayload } from '_stores/session/types'
import { Button, Card } from 'react-bootstrap'
import RequestMessage from '_components/request-message'
import { FormFieldProp } from '_components/form/types'

const UserPasswordForm: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  const [formIsVisible, displayPasswordForm] = React.useState(false)
  const requestKey = 'put /user/password'
  const request = state.requests && state.requests[requestKey]

  React.useEffect(() => {
    if (request && request.status === 'success') {
      request.message = 'Password changed successfully'
      displayPasswordForm(false)
    }
  }, [request])

  if (!formIsVisible)
    return (
      <>
        <p>
          <RequestMessage request={request} check />
        </p>
        <Button variant="default" onClick={() => displayPasswordForm(true)}>
          Change your password
        </Button>
      </>
    )

  const fields: FormFieldProp[] = [
    {
      name: 'oldPassword',
      type: 'password',
      placeholder: 'Actual password...',
      autoComplete: 'new-password',
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password...',
      autoComplete: 'new-password',
    },
    {
      name: 'passwordVerification',
      type: 'password',
      placeholder: 'Password verification...',
      autoComplete: 'new-password',
    },
  ]
  return (
    <Card>
      <div className="p-3">
        <Form
          fields={fields}
          validate={(values: any) => {
            const errors: any = {}
            if (!values.password) errors.password = 'Required field'
            else if (!values.password[5]) errors.password = 'Min 6 characters'
            if (!values.passwordVerification) errors.passwordVerification = 'Required field'
            else if (values.passwordVerification !== values.password)
              errors.passwordVerification = 'Incorrect verification password'
            return errors
          }}
          onSubmit={(values: UpdateProfilePayload) =>
            actions.updateProfile(
              {
                password: values.password,
                oldPassword: values.oldPassword,
              },
              {
                requestKey,
              },
            )
          }
          request={request}
          submitLabel="Update your password"
          cancelButton={{
            onClick: () => displayPasswordForm(false),
          }}
        />
      </div>
    </Card>
  )
}

export default UserPasswordForm

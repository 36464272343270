import * as React from 'react'
import { useAttributes, useAttributesRequest } from '_stores/attributes/store'
import Table from '_components/table'
import { Link, Route, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import useDebounce from '../../hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, ButtonGroup, Card, Col, Modal, Row } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import AttributeCreation from './creation'
import { Attribute } from '_stores/attributes/types'
import useListTable from './hooks/use-list-table'
import Form from '_components/form'
import useListFiltersFields from './hooks/use-list-filters-fields'
import app from '_constants/app'
import { Product } from '_stores/products/types'

const AttributesList: React.FunctionComponent = () => {
  const [state, actions] = useAttributes()
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 100)
  const location = useLocation()

  const section = React.useMemo(() => {
    const match = /([a-z-]*)$/.exec(location.pathname)
    return match && match[1]
  }, [location])

  React.useEffect(() => {
    actions.setFilter('search', search)
  }, [debouncedSearch])

  const [locale, setLocale] = React.useState('fr')
  const [attributeToRemove, setAttributeToRemove] = React.useState<Attribute>()

  const [columns, data] = useListTable({
    attributes: state.all.filter(a => section && a.family === section),
    locale,
    family: section,
    removeAttribute: attribute => setAttributeToRemove(attribute),
  })

  const [filtersFields] = useListFiltersFields()
  const [request] = useAttributesRequest({
    method: 'delete',
    id: attributeToRemove && attributeToRemove._id,
  })

  React.useEffect(() => {
    if (request?.status === 'success') {
      setAttributeToRemove(undefined)
    }
  }, [request])

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">Attributs</h1>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          {/*<Form noButton fields={filtersFields} onFieldChange={actions.setFilter} /> */}
          <Row>
            <Col md="10">
              <InputField
                name="search"
                onChange={value => setSearch(value)}
                prepend={<FontAwesomeIcon icon={faSearch} />}
              />
            </Col>
            <Col md="2" className="text-right">
              <ButtonGroup style={{ verticalAlign: 'sub' }}>
                {app.languagues.map(language => (
                  <Button
                    size="sm"
                    key={language}
                    variant={language === locale ? 'primary' : 'secondary'}
                    onClick={() => setLocale(language)}
                  >
                    {language}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <Table columns={columns} data={data} />
        </div>
      </Card>
      <Route path="/attributes/new" component={AttributeCreation} />

      <Modal show={Boolean(attributeToRemove)} onHide={() => setAttributeToRemove(undefined)}>
        <Modal.Header>Suppression d'un attribut</Modal.Header>
        <Modal.Body>
          Êtes-vous certain de vouloir supprimer{' '}
          <strong>{attributeToRemove?.labels[locale]}</strong> ?
          {request && request.status === 'error' && (
            <>
              <p className="mt-3 text-danger">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                Impossible de supprimer l'attribut car il est utilisé par{' '}
                {request.message?.match('products')
                  ? `${request.error?.data.products.length} produit(s).`
                  : `${request.error?.data.attributes.length} attribut(s).`}
              </p>
              {request.error?.data &&
                request.error?.data.products &&
                request.error?.data.products.map((product: Product) => (
                  <p key={product._id}>
                    <Link to={`/products/${product._id}`}> {product.name.fr}</Link>
                  </p>
                ))}
              {request.error?.data &&
                request.error?.data.attributes &&
                request.error?.data.attributes.map((attribute: Attribute) => (
                  <p key={attribute._id}>
                    <Link to={`/attributes/${attribute._id}`}> {attribute.labels.fr}</Link>
                  </p>
                ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="default" onClick={() => setAttributeToRemove(undefined)}>
              Annuler
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                attributeToRemove && attributeToRemove._id && actions.delete(attributeToRemove._id)
              }
            >
              Supprimer
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </PageApp>
  )
}

export default AttributesList

import * as React from 'react'
import { useUsers } from '_stores/users/store'
import Table from '_components/table'
import { Link, Route, Switch } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBan,
  faCheckCircle,
  faLink,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import InviteUser from './invite'
import { Button, Card } from 'react-bootstrap'
import { User } from '_stores/users/types'
import moment from 'moment'
import AutoSaveField from '_components/form/auto-save-field'
import { useAppUser } from '_stores/session/store'
import DeleteUser from './delete'
import UserInviteUrlModal from './invite-url-modal'

const UsersList: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  const [appUser] = useAppUser()
  const [currentUser, setCurrentUser] = React.useState<User>()
  const [urlModalIsOpen, setUrlModalIsOpen] = React.useState(false)
  React.useEffect(() => {
    actions.fetch({ role: 'admin' })
  }, [])

  //Invite modal

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        width: '30%',
        Cell: (data: any) => {
          const user: User = data.cell.row.original
          return (
            <span>
              {user.disabled && <FontAwesomeIcon icon={faBan} className="mr-1" />}
              {user.firstName} {user.lastName}
            </span>
          )
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: '20%',
      },
      {
        Header: 'Invited',
        accessor: 'dates.created',
        width: '10%',
        Cell: (data: any) => {
          const user: User = data.cell.row.original
          return moment(user.dates.created).format()
        },
      },
      {
        Header: ' ',
        accessor: '',
        width: '10%',
        Cell: (data: any) => {
          const user: User = data.cell.row.original
          if (appUser._id === user._id) return ' '
          return (
            <div className="text-right">
              {appUser._id !== user._id && (
                <Link to={`/users/delete/${user._id}`} onClick={() => actions.setCurrent(user)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Link>
              )}
              {!user.passwordValidated && (
                <a
                  className="link ml-2"
                  onClick={() => {
                    setCurrentUser(user)
                    setUrlModalIsOpen(true)
                  }}
                  title="Voir le lien du formulaire de validation du compte"
                >
                  <FontAwesomeIcon icon={faLink} />
                </a>
              )}
            </div>
          )
        },
      },
    ],
    [state.requests, state.all],
  )

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">Administrateurs</h1>
        <div className="ml-auto">
          <Link to="/users/invite" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Inviter un administrateur
          </Link>
        </div>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <Table
            columns={columns}
            data={state.all.filter(u => !u.organizations || !u.organizations[0])}
          />
        </div>
      </Card>
      <Switch>
        <Route path="/users/invite" exact component={InviteUser} />
        <Route path="/users/delete/:id" exact component={DeleteUser} />
      </Switch>
      <UserInviteUrlModal
        isOpen={urlModalIsOpen}
        setOpen={show => setUrlModalIsOpen(show)}
        user={currentUser}
      />
    </PageApp>
  )
}

export default UsersList

import * as React from 'react'
import { Image, Transformation, CloudinaryContext } from 'cloudinary-react'

interface CloudinaryImageProps {
  publicId: string
  transformations?: any
}

const CloudinaryImage: React.FunctionComponent<CloudinaryImageProps> = ({
  publicId,
  transformations,
}) => (
  <CloudinaryContext cloudName="dkgryqpim">
    <Image publicId={publicId} format="jpg">
      {transformations && <Transformation {...transformations} />}
    </Image>
  </CloudinaryContext>
)

export default CloudinaryImage

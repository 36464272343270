import * as React from 'react'
import { useCurrentProduct } from '_stores/products/store'
import APP from '_constants/app'
import { Product, ProductKinds } from '_stores/products/types'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Route, useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import useItemSections from './hooks/use-item-sections'
import LanguagesRightMenuSwitch from '_components/languages/right-menu-switch'

export interface ProductItemProps {
  product: Product
}

const ProductItem: React.FunctionComponent = () => {
  const [currentProduct, actions] = useCurrentProduct()
  const location = useLocation()
  const [currentLocale, setCurrentLocale] = React.useState('fr')

  const sections = useItemSections(currentLocale, actions, currentProduct)

  return (
    <ApiItemWrapper item={currentProduct} actions={actions}>
      <PageApp>
        <Breadcrumb>
          <LinkContainer to="/products">
            <Breadcrumb.Item>
              {currentProduct && currentProduct.kind === ProductKinds.attempt
                ? 'Tentatives'
                : 'Produits'}
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            {currentProduct ? currentProduct.name.fr : 'Nouveau produit'}
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr className="mb-4"></hr>
        <Row className="justify-content-center">
          <Col xl={10}>
            <Row>
              <Col xl={9}>
                {currentProduct && (
                  <>
                    {sections.map(section => (
                      <Route
                        exact
                        key={section.path[0]}
                        path={[
                          ...section.path,
                          `/${
                            currentProduct?.kind === ProductKinds.attempt ? 'attempts' : 'products'
                          }/${currentProduct._id}`,
                        ]}
                        component={section.component}
                      />
                    ))}
                  </>
                )}
              </Col>
              <Col xl={3}>
                <nav className="navbar rightbar">
                  <nav className="nav">
                    <div className="nav-link pb-0 h6">Sections</div>
                    {currentProduct && sections.map(section => {
                      const path =
                        currentProduct?.kind === ProductKinds.attempt
                          ? section.path[1]
                          : section.path[0]
                      return (
                        <NavLink
                          key={section.path[0]}
                          activeClassName={'active'}
                          className={'nav-link'}
                          to={path}
                          isActive={() => location.pathname === path}
                        >
                          {section.label}
                        </NavLink>
                      )
                    })}
                    <LanguagesRightMenuSwitch
                      className="mt-4"
                      languagues={APP.languagues}
                      active={currentLocale}
                      onClick={locale => setCurrentLocale(locale)}
                    />
                  </nav>
                </nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageApp>
    </ApiItemWrapper>
  )
}

export default ProductItem

import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useCurrentArticle } from '../../stores/articles/store'

const ArticleForm: React.FunctionComponent = () => {
  const [currentArticle, actions] = useCurrentArticle()

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        name: 'title',
        type: 'text',
        placeholder: 'Name...',
      },
      {
        name: 'published',
        type: 'checkbox',
        options: [
          {
            value: true,
            label: 'Published',
          },
        ],
      },
      {
        name: 'link',
        type: 'text',
        placeholder: 'Link...',
      },
      {
        name: 'content',
        type: 'editor',
      },
    ],
    [],
  )

  return (
    <>
      <Form
        fields={fields}
        initialValues={currentArticle}
        validate={(values: any) => {
          const errors: any = {}
          if (!values.title) errors.title = 'Required field'
          return errors
        }}
        onSubmit={actions.createOrUpdate}
        request={actions.getRequest()}
        submitLabel="Save"
        buttonPosition="top"
      />
    </>
  )
}

export default ArticleForm

import * as React from 'react'
import { Modal } from 'react-bootstrap'
import ListGroupForm from '_components/form/list-group'
import CategoryForm from '_containers/categories/form'
import { useCategoriesRequest } from '_stores/categories/store'
import { useCurrentProduct } from '_stores/products/store'
import useGeneralFormFields from '../hooks/use-general-form-fields'

interface GeneralFormProps {
  locale: string
  forceEdit?: boolean
  bindValues?: any
}

const GeneralForm: React.FunctionComponent<GeneralFormProps> = ({
  locale,
  forceEdit,
  bindValues,
}) => {
  const [currentProduct] = useCurrentProduct()
  //CATEGORIES
  //Save in state parent category selected for new category form default values
  const [newCategoryDefaultParent, setNewCategoryDefaultParent] = React.useState<string>()
  //Modal state
  const [showCategoryModal, setShowCategoryModal] = React.useState<boolean>(false)
  //Clear saved parent for new category form default values on modal close
  React.useEffect(() => {
    if (!showCategoryModal && newCategoryDefaultParent) setNewCategoryDefaultParent(undefined)
  }, [showCategoryModal])
  //Use post /categories request:
  const [categoriePostRequest] = useCategoriesRequest('post')
  //Close modal on success
  React.useEffect(() => {
    if (categoriePostRequest && categoriePostRequest.status === 'success' && showCategoryModal) {
      setShowCategoryModal(false)
    }
  }, [categoriePostRequest && categoriePostRequest.status])

  //Get fields
  const [fields] = useGeneralFormFields({
    locale,
    product: currentProduct,
    onCreateNewCategory: (value: string) => {
      setShowCategoryModal(true)
      setNewCategoryDefaultParent(value)
    },
    forceEdit,
    bindValues,
  })

  return (
    <>
      <ListGroupForm fields={fields} />
      {showCategoryModal && (
        <Modal
          show={showCategoryModal}
          onHide={() => {
            setShowCategoryModal(false)
          }}
        >
          <Modal.Header>Nouvelle catégorie</Modal.Header>
          <Modal.Body>
            <CategoryForm defaultParent={newCategoryDefaultParent} />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default GeneralForm

import * as React from 'react'
import { FormFieldProp } from '_components/form/types'
import { TurnoverTarget } from '_stores/organizations/types'

/*export interface UseTurnoverTargetFormFieldsProps {
  turnoverTarget?: TurnoverTarget
}*/

const useTurnoverTargetFormFields = (): [FormFieldProp[]] => {
  const fields = React.useMemo(() => {
    const fields: FormFieldProp[] = [
      {
        name: 'target',
        type: 'number',
        placeholder: '120000 €',
        label: 'Objectif (*)',
      },
      {
        name: 'discount',
        type: 'number',
        placeholder: '2 %',
        label: 'Remise (%)',
      },
    ]
    return fields
  }, [])

  return [fields]
}

export default useTurnoverTargetFormFields

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserFriends,
  faImages,
  faKey,
  faNewspaper,
  faTags,
  faShoppingCart,
  faCheckDouble,
  faCopyright,
  faUniversity,
  faVials,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import SidebarLink from './link'

interface SideBarProps {
  collapsed?: boolean
  toggled?: boolean
  setToggled(toggled: boolean): void
}

const Sidebar: React.FunctionComponent<SideBarProps> = ({ collapsed, toggled, setToggled }) => {
  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <div className="menu">
          <div className="menu-header">Produits</div>
          <SidebarLink
            to="/products"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faShoppingCart} />}
            label={<span>Produits</span>}
          />
          <SidebarLink
            to="/attempts"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faVials} />}
            label={<span>Tentatives</span>}
          />
          <SidebarLink
            to="/orders"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faDollarSign} />}
            label={<span>Commandes</span>}
          />
          <SidebarLink
            to="/categories"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faTags} />}
            label={<span>Catégories</span>}
          />
          <SidebarLink
            to="/brands"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faCopyright} />}
            label={<span>Marques</span>}
          />
          <div className="menu-header">Attributs</div>
          <SidebarLink
            to="/attributes/product-attribute"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faCheckDouble} />}
            label={<span>Caractéristiques</span>}
          />
          <SidebarLink
            to="/attributes/product-attribute-section"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faCheckDouble} />}
            label={<span>Sections</span>}
          />
          <SidebarLink
            to="/attributes/product-filename"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faCheckDouble} />}
            label={<span>Fichiers liés</span>}
          />
          <div className="menu-header">Others</div>
          {/*__FEATURE_pages
          <SideBarPages setToggled={setToggled} />*/}
          {/*__FEATURE_pages*/}
          {/*__FEATURE_medias*/}
          <SidebarLink
            to="/medias"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faImages} />}
            label={<span>Medias</span>}
          />
          {/*__FEATURE_medias*/}
          {/*__FEATURE_articles*/}
          <SidebarLink
            to="/articles"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faNewspaper} />}
            label={<span>News</span>}
          />
          {/*__FEATURE_articles*/}
          <div className="menu-header mt-4">Settings</div>
          {/*__FEATURE_api-keys*/}
          {/*<SidebarLink
            to="/api-keys"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faKey} />}
            label={<span>API Keys</span>}
          /> */}
          <SidebarLink
            to="/organizations"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faUniversity} />}
            label={<span>Clients</span>}
          />
          <SidebarLink
            to="/users"
            setToggled={setToggled}
            icon={<FontAwesomeIcon icon={faUserFriends} />}
            label={<span>Administrateurs</span>}
          />
          {/*__FEATURE_api-keys*/}
        </div>
      </div>
    </div>
  )
  //return (
  //  <ProSidebar className="sidebar" breakPoint="md" collapsed={collapsed} toggled={toggled}>
  //    <SidebarHeader>
  //      <div className="sidebar_header text-center">
  //        <img
  //          src="https://codefathers.be/static/codefathers-thomas-and-pierre-with-names-7c82449cb8523cbc0061b20f81861bb5.png"
  //          style={{ width: '100%' }}
  //        />
  //        <br />
  //        Codefathers
  //      </div>
  //    </SidebarHeader>
  //    <SidebarContent>
  //      <Menu>
  //        {/*__FEATURE_pages*/}
  //        <SideBarPages setToggled={setToggled} />
  //        {/*__FEATURE_pages*/}
  //        {/*__FEATURE_medias*/}
  //        <SidebarLink
  //          to="/medias"
  //          setToggled={setToggled}
  //          icon={<FontAwesomeIcon icon={faImages} />}
  //          label={<span>Medias</span>}
  //        />
  //        {/*__FEATURE_medias*/}
  //        {/*__FEATURE_resources*/}
  //        <SidebarLink
  //          to="/resources"
  //          setToggled={setToggled}
  //          icon={<FontAwesomeIcon icon={faFolder} />}
  //          label={<span>Resources</span>}
  //        />
  //        {/*__FEATURE_resources*/}
  //        {/*__FEATURE_api-keys*/}
  //        <SidebarLink
  //          to="/api-keys"
  //          setToggled={setToggled}
  //          icon={<FontAwesomeIcon icon={faKey} />}
  //          label={<span>API Keys</span>}
  //        />
  //        {/*__FEATURE_api-keys*/}
  //        {/*__FEATURE_articles*/}
  //        <SidebarLink
  //          to="/articles"
  //          setToggled={setToggled}
  //          icon={<FontAwesomeIcon icon={faNewspaper} />}
  //          label={<span>News</span>}
  //        />
  //        {/*__FEATURE_articles*/}
  //        <SidebarLink
  //          to="/users"
  //          setToggled={setToggled}
  //          icon={<FontAwesomeIcon icon={faUserFriends} />}
  //          label={<span>Users</span>}
  //        />
  //      </Menu>
  //    </SidebarContent>
  //  </ProSidebar>
  //)
}

export default Sidebar

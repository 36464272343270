import * as React from 'react'
import useDebounce from '../../../hooks/use-debounce'
import { FormFieldProp } from '_components/form/types'
import { useProducts } from '_stores/products/store'
import { Product } from '_stores/products/types'

interface useLinkedProductFormFieldsProps {
  product?: Product
  locale: string
}

const useLinkedProductFormFields = ({
  product,
  locale,
}: useLinkedProductFormFieldsProps): [FormFieldProp[]] => {
  const [state, actions] = useProducts()
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 200)

  React.useEffect(() => {
    actions.autocomplete(search, locale)
  }, [debouncedSearch])

  const fields: FormFieldProp[] = React.useMemo(
    () =>
      !product
        ? []
        : [
            {
              name: 'product',
              type: 'react-select',
              options: state.autocomplete.result.filter(
                p =>
                  p.value !== product._id && !product.linkedProducts.some(lp => lp._id === p.value),
              ),
              label: 'Produit',
              onInputChange: setSearch,
            },
          ],
    [locale, product, state.autocomplete.result],
  )

  return [fields]
}
export default useLinkedProductFormFields

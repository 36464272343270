import * as React from 'react'
import { useProducts } from '_stores/products/store'
import { Product, ProductKinds } from '_stores/products/types'
import APP from '_constants/app'
import ProductCategories from '_components/products/categories'
import { useMedias } from '_stores/medias/store'
import {
  useCategories,
  useCategoriesForTreeSelectField,
  useCategoriesRequest,
} from '_stores/categories/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { ListGroupFormField } from '_components/form/list-group'
import Medias from '_components/medias'
import { Media } from '_stores/medias/types'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useBrands, useBrandsForSelectField } from '_stores/brands/store'

interface UseGeneralFormFieldsProps {
  locale: string
  product?: Product
  onCreateNewCategory?(value: any): void
  forceEdit?: boolean
  bindValues?(values: any): void
}

const useGeneralFormFields = ({
  locale,
  product,
  onCreateNewCategory,
  forceEdit,
  bindValues,
}: UseGeneralFormFieldsProps): [ListGroupFormField[]] => {
  //Use usefull state and actions
  const [state, actions] = useProducts()
  const [mediasState, mediasActions] = useMedias()
  const [categoriesState, categoriesActions] = useCategories()

  //Fetch usefull ressources
  React.useEffect(() => {
    if (!categoriesState.all[0]) categoriesActions.fetch()
    if (!mediasState.all[0]) mediasActions.fetch()
  }, [])

  //Use categories for tree select field (product.categories)
  const [categories] = useCategoriesForTreeSelectField({ locale })
  //Use post /categories request:
  const [categoriePostRequest] = useCategoriesRequest('post')
  //Add created category to field options
  const defaultCategories: string[] = React.useMemo(() => {
    const categoriesIds = product ? product.categories.map((c: any) => c._id as string) : []
    /*if (categoriePostRequest && categoriePostRequest.status === 'success') {
      const newCategoryId =
        categoriePostRequest &&
        categoriePostRequest.response &&
        categoriePostRequest.response.result._id
      //if (!categoriesIds.includes(newCategoryId)) categoriesIds.push(newCategoryId)
    }*/
    return categoriesIds
  }, [product, categoriePostRequest && categoriePostRequest.status])

  //PICTURES
  //Save in state pictures who are selected (used for remove and choose cover)
  const [selectedPictures, setSelectedPictures] = React.useState<string[]>([])
  //Save in state displayed pictures in form (edit mode)
  const [pictures, setPictures] = React.useState<string[]>([])
  //Add uploaded pictures in state after post /medias success
  React.useEffect(() => {
    const pictures: string[] = product
      ? (product.pictures.map((p: any) => p.item._id) as string[])
      : []
    if (mediasState.requests['post /medias'] && mediasState.requests['post /medias'].response) {
      mediasState.requests['post /medias'].response.forEach((media: any) => {
        if (!pictures.includes(media._id)) pictures.push(media._id)
      })
    }
    return setPictures(pictures)
  }, [product, mediasState.requests['post /medias']])

  //Get brands for brand field (only for attempt)
  const [brandsState, brandsActions] = useBrands()
  const [brandsInputOptions] = useBrandsForSelectField()
  React.useEffect(() => {
    if (!brandsState.all[0] && product?.kind === ProductKinds.attempt) brandsActions.fetch()
  }, [])

  //FIELDS
  const fields: ListGroupFormField[] = React.useMemo(
    () =>
      !product
        ? []
        : [
            {
              label: 'Nom',
              forceEditMode: forceEdit,
              value: product?.name[locale],
              formProps: {
                initialValues: {
                  name: product.name,
                },
                noButton: forceEdit,
                bindValues: (values: any) => bindValues && bindValues(values),
                fields: [
                  {
                    locales: APP.languagues,
                    canDuplicateLocales: true,
                    type: 'text',
                    name: 'name',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: product._id,
                      name: values.name,
                    },
                    {
                      requestKey: `put name /products/${product._id}`,
                    },
                  )
                },
                validate: (values: any) => {
                  const errors: any = {}
                  APP.languagues.forEach(lang => {
                    if (!values.name || !values.name[lang]) errors[`name.${lang}`] = 'Champs requis'
                  })
                  return errors
                },
                request: state.requests[`put name /products/${product._id}`],
              },
            },
            product.kind === ProductKinds.attempt && {
              label: 'Marque',
              forceEditMode: forceEdit,
              value: product.brand && product.brand.name,
              formProps: {
                noButton: forceEdit,
                bindValues: (values: any) => bindValues && bindValues(values),
                initialValues: product.brand
                  ? {
                      _id: product._id,
                      brand: product.brand._id,
                    }
                  : {},
                fields: [
                  {
                    type: 'react-select',
                    name: 'brand',
                    options: brandsInputOptions,
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: product._id,
                      brand: values.brand._id,
                    },
                    {
                      requestKey: `put brand /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put brand /products/${product._id}`],
              },
            },
            {
              label: 'Catégorie',
              forceEditMode: forceEdit,
              value: <ProductCategories product={product} locale={locale} />,
              formProps: {
                initialValues: {
                  categories: defaultCategories,
                },
                noButton: forceEdit,
                bindValues: (values: any) => bindValues && bindValues(values),
                fields: [
                  {
                    type: 'tree-select',
                    name: 'categories',
                    creatable: true,
                    onCreate: (value: any) => {
                      onCreateNewCategory && onCreateNewCategory(value && value.parent)
                    },
                    options: categories,
                    multiple: true,
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: product._id,
                      categories: values.categories,
                    },
                    {
                      requestKey: `put categories /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put categories /products/${product._id}`],
              },
            },
            {
              label: 'Photos',
              forceEditMode: forceEdit,
              editLabel: (
                <span>
                  Photos <small>(la première photo est la couverture)</small>
                </span>
              ),
              value: (
                <Medias
                  medias={product.pictures.map(p => p.item) as Media[]}
                  readOnly
                  mediaColProps={{ lg: 3 }}
                />
              ),
              formProps: {
                initialValues: {
                  //@ts-ignore
                  pictures,
                },
                bindValues: (values: any) => bindValues && bindValues(values),
                noButton: forceEdit,
                fields: [
                  {
                    type: 'medias',
                    name: 'pictures',
                    multiple: true,
                    initialValues: {
                      //@ts-ignore
                      pictures,
                    },
                    mediasProps: {
                      mediaColProps: { lg: 3 },
                      onDrop: async (medias: any) => {
                        const res = await mediasActions.create(medias)
                        actions.update(
                          {
                            _id: product._id,
                            pictures: res.map((media: Media, index: number) => ({
                              cover: Boolean(index === 0),
                              item: media._id,
                            })),
                          },
                          {
                            requestKey: `put pictures /products/${product._id}`,
                          },
                        )
                      },
                      onDropzoneHide: () => mediasActions.clearRequest({ key: 'post /medias' }),
                      request: mediasState.requests['post /medias'],
                      modalReadonly: true,
                      selectable: true,
                      onSelect: (media: any) => {
                        const selectedPictureIndex = selectedPictures?.indexOf(media._id)
                        if (selectedPictureIndex === -1)
                          return setSelectedPictures([...selectedPictures, media._id])
                        selectedPictures.splice(selectedPictureIndex, 1)
                        setSelectedPictures([...selectedPictures])
                      },
                      selected: selectedPictures,
                      onMove: (media: Media, direction: 'left' | 'right', mediaIndex: number) => {
                        const newPicturesState = [...pictures]
                        const indexOfMedia = newPicturesState.indexOf(media._id as string)
                        newPicturesState.splice(indexOfMedia, 1)
                        newPicturesState.splice(
                          mediaIndex + (direction === 'left' ? -1 : 1),
                          0,
                          media._id as string,
                        )
                        setPictures(newPicturesState)
                      },
                    },
                    actions: selectedPictures[0] && [
                      <Button
                        variant="primary"
                        onClick={() => {
                          setPictures(
                            pictures.filter(picture => !selectedPictures.includes(picture)),
                          )
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        Retirer du produit
                      </Button>,
                    ],
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: product._id,
                      pictures: values.pictures.map((pictureId: string, index: number) => ({
                        cover: Boolean(index === 0),
                        item: pictureId,
                      })),
                    },
                    {
                      requestKey: `put pictures /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put pictures /products/${product._id}`],
              },
            },
            {
              label: 'Description',
              forceEditMode: forceEdit,
              value: product && product.description && (
                <div dangerouslySetInnerHTML={{ __html: product.description[locale] }} />
              ),
              formProps: {
                initialValues: { description: product.description },
                bindValues: (values: any) => bindValues && bindValues(values),
                noButton: forceEdit,
                fields: [
                  {
                    locales: APP.languagues,
                    canDuplicateLocales: true,
                    type: 'wysiwyg',
                    name: 'description',
                    className: 'mb-5',
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: product._id,
                      description: values.description,
                    },
                    {
                      requestKey: `put description /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put description /products/${product._id}`],
              },
            },
          ].filter(f => f),
    [
      product,
      locale,
      state.requests,
      categories,
      mediasState.requests['post /medias'],
      selectedPictures,
      pictures,
      brandsInputOptions,
      forceEdit,
      bindValues,
    ],
  )

  return [fields]
}
export default useGeneralFormFields

import { FormFieldType } from './types'
import InputField from './fields/input'
import PasswordField from './fields/password'
import CheckboxField from './fields/check'
import SelectField from './fields/select'
import ReactSelect from './fields/react-select'
import Editor from './fields/editor'
import FilesField from './fields/files'
import MediasField from './fields/medias'
import TextsField from './fields/texts'
import Quill from './fields/react-quill'
import CreatableSelect from './fields/creatable-select'
import InputGroupField from './fields/input-group'
import TreeSelectField from './fields/tree-select'
import SwitchField from './fields/switch'

export const getComponent = (inputType: FormFieldType): React.ReactNode => {
  switch (inputType) {
    case 'password':
      return PasswordField
    case 'switch':
      return SwitchField
    case 'checkbox':
      return CheckboxField
    case 'select':
      return SelectField
    case 'react-select':
      return ReactSelect
    case 'creatable-select':
      return CreatableSelect
    case 'wysiwyg':
      return Quill
    case 'editor':
      return Editor
    case 'files':
      return FilesField
    case 'medias':
      return MediasField
    case 'texts':
      return TextsField
    case 'input-group':
      return InputGroupField
    case 'tree-select':
      return TreeSelectField
    default:
      return InputField
  }
}

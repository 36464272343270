import { Category } from '_stores/categories/types'
import { Media } from '_stores/medias/types'
import { Attribute } from '_stores/attributes/types'
import { LocalizedAttribute } from '_stores/common.types'
import { Brand } from '_stores/brands/types'
import { User } from '_stores/users/types'

export interface ProductPicture {
  cover: boolean
  item: string | Media
}

export enum ProductKinds {
  article = 'article',
  attempt = 'attempt',
}

export interface ProductFileNameAttributeSchema extends LocalizedAttribute<string> {
  _id: string
  item: string | Attribute
}

export interface ProductAttribute {
  _id?: string
  item: string | Attribute
  value: ProductFileNameAttributeSchema[]
}
export interface ProductFile {
  _id: string
  name: string | Attribute
  path: string
  visibility: {
    public: boolean
    professionals: boolean
    commercials: boolean
  }
}

export interface AttemptVote {
  user: string | User
  score: 0 | 1 | 2 | 3 | 4 | 5
  comment?: string
}

export interface ProductMafactData {
  dates: {
    created?: string
    lastUpdate?: string
  }
  name: {
    fr?: string
    nl?: string
  }
  category: string
  typart?: string
  uid?: string
  ref: string
  codebar?: string
  ean?: string
}

export interface Product {
  _id?: string
  dates?: {
    created: string
    lastUpdate: string
  }
  name: LocalizedAttribute<string>
  kind: ProductKinds
  brand: Brand
  mafactData: ProductMafactData
  description?: LocalizedAttribute<string>
  visibility: {
    public: boolean
    professionals: boolean
    countries: string[]
    homepage: {
      promotion: boolean
      newness: boolean
    }
  }
  files: ProductFile[]
  pictures: ProductPicture[]
  categories: string[] | Category[]
  attributes: ProductAttribute[]
  stock: {
    total: number
    available: number
  }
  pricing: {
    price: number
    srp: number
    vat: number
    taxes: number
    recupel: number
    bebat: number
    auvibel: number
  }
  packaging: {
    unit: string
    baseUnit: number
    customerUnit: number
    weight: number
    volume: number
    surface: number
    length: number
    byCardboard: number
    byPallet: number
  }
  linkedProducts: Product[]
  votes: AttemptVote[]
}

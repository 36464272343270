import { LocalizedAttribute } from '_stores/common.types'

export enum AttributeTypeEnum {
  text = 'text',
  select = 'select',
  number = 'number',
  medias = 'medias',
}

export enum AttributeFamilyEnum {
  productAttribute = 'product-attribute',
  productFile = 'product-filename',
  productAttributeSection = 'product-attribute-section',
}

export interface AttributeValue extends LocalizedAttribute<string> {
  _id?: string
}

export interface Attribute {
  _id?: string
  dates?: {
    created: string
    lastUpdate: string
  }
  labels: LocalizedAttribute<string>
  type: AttributeTypeEnum
  values: AttributeValue[]
  relational?: boolean
  family?: AttributeFamilyEnum
  attribute?: Attribute
  positionIndex?: number
}

export interface AttributeAsSelectOption extends Attribute {
  label: string
  value: any
}

import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import PasswordRecoveryForm from './form'
import { Link } from 'react-router-dom'

const PasswordRecovery: React.FunctionComponent = () => (
  <PageCenteredForm
    title={
    <h5>
      Password recovery
    </h5>
    }
    footer={
      <div className='d-flex justify-content-center'>
        <Link to="/login">Cancel</Link>
      </div>
    }
  >
    <PasswordRecoveryForm />
  </PageCenteredForm>
)

export default PasswordRecovery

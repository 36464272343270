import * as React from 'react'
import { useBrands } from '_stores/brands/store'
import Table from '_components/table'
import { Link } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import moment from 'moment'
import useDebounce from '../../hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import { Brand } from '_stores/brands/types'
import CloudinaryImage from '_components/cloudinary-image'

const BrandsList: React.FunctionComponent = () => {
  const [state, actions] = useBrands()
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 100)

  React.useEffect(() => {
    actions.fetch()
  }, [])

  React.useEffect(() => {
    actions.setFilter('search', search)
  }, [debouncedSearch])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        Cell: (data: any) => {
          const brand: Brand = data.cell.row.original
          //return <Link to={`/brands/${brand._id}`}>{brand.name}</Link>
          return (
            <div className="d-flex align-items-center">
              <div className="mr-3">
                <Link to={`/brands/${brand._id}`}>
                  <CloudinaryImage
                    publicId={brand.logo ? brand.logo.providerId : 'no_picture'}
                    transformations={{
                      width: '60',
                      height: '60',
                      crop: 'pad',
                    }}
                  />
                </Link>
              </div>

              <Link to={`/brands/${brand._id}`}>{brand.name}</Link>
            </div>
          )
        },
        accessor: 'name',
      },
      {
        Header: 'Siteweb',
        Cell: (data: any) => {
          const brand: Brand = data.cell.row.original
          if (!brand.link) return ''
          return (
            <a href={brand.link} target="__blank">
              {brand.link
                .replace(/^https?:\/\//, '')
                .replace(/^www./, '')
                .replace(/\/$/, '')}
            </a>
          )
        },
        width: '15%',
        accessor: 'link',
      },
      {
        Header: 'Mit en avant',
        Cell: (data: any) => {
          const brand: Brand = data.cell.row.original
          return (
            <span>
              {brand.visibility && brand.visibility.public ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                ''
              )}
            </span>
          )
        },
        width: '15%',
        accessor: 'visibility.public',
      },
      {
        Header: 'Limité à certains pays',
        Cell: (data: any) => {
          const brand: Brand = data.cell.row.original
          return (
            <span>
              {brand.visibility &&
                brand.visibility.countries &&
                brand.visibility.countries.join(' - ').toUpperCase()}
            </span>
          )
        },
        width: '15%',
        accessor: 'visibility.countries',
      },
      {
        Header: 'Visible dans les filtres',
        Cell: (data: any) => {
          const brand: Brand = data.cell.row.original
          return (
            <span>
              {brand.visibility && brand.visibility.filter ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                ''
              )}
            </span>
          )
        },
        width: '15%',
        accessor: 'visibility.filter',
      },
      {
        Header: 'Date',
        width: '15%',
        Cell: (data: any) => {
          const brand: Brand = data.cell.row.original
          return <span>{moment(brand.dates.created).format()}</span>
        },
        accessor: 'dates.created',
      },
    ],
    [],
  )

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">Marques</h1>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
          />
          <Table columns={columns} data={state.all} />
        </div>
      </Card>
    </PageApp>
  )
}

export default BrandsList

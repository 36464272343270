import * as React from 'react'
import { Col, ColProps } from 'react-bootstrap'
import classNames from 'classnames'

interface MediaThumbnailProps {
  onClick?(): void
  children: React.ReactNode | string
  className?: string
  colProps?: ColProps
}

const MediaThumbnail: React.FunctionComponent<MediaThumbnailProps> = ({
  onClick,
  children,
  className,
  colProps,
}) => (
  <Col
    lg={2}
    md={4}
    sm={6}
    xs={6}
    onClick={onClick}
    className={classNames('mb-3', className)}
    {...colProps}
  >
    {children}
  </Col>
)

export default MediaThumbnail

import * as React from 'react'
import APP from '_constants/app'
import { FormFieldProp } from '_components/form/types'
import { useAttributes, useAttributesForSelectField } from '_stores/attributes/store'
import { AttributeFamilyEnum } from '_stores/attributes/types'
import { ProductFile } from '_stores/products/types'
import { buildLocalizedObject } from '_core/i18n'

interface UseFilesFormFields {
  locale: string
  currentFile?: ProductFile
}

const useFilesFormFields = ({
  locale,
  currentFile,
}: UseFilesFormFields): [FormFieldProp[], any, string?] => {
  //Use usefull states
  const [attributesState] = useAttributes()
  //Use product files attributes for field
  const [attributesAsOptions] = useAttributesForSelectField({
    locale,
    filter: attribute => {
      return attribute.family === AttributeFamilyEnum.productFile
    },
  })

  //Save new creatable-select name attribute in state
  const [newNameAttribute, setNewNameAttribute] = React.useState<string>('')

  //Build initial values: current file or default value with new creatable-select name
  const initialValues = React.useMemo(() => {
    const initialValues = currentFile
      ? //@ts-ignore
        { ...currentFile, name: currentFile.name.item }
      : {
          visibility: {
            public: true,
            professionals: true,
            commercials: true,
          },
        }
    if (newNameAttribute)
      return {
        ...initialValues,
        name: buildLocalizedObject(newNameAttribute),
      }
    return initialValues
  }, [newNameAttribute, currentFile])

  const fields: FormFieldProp[] = React.useMemo(() => {
    const fields: FormFieldProp[] = [
      {
        name: 'name',
        type: 'creatable-select',
        placeholder: 'Nom...',
        label: 'Nom',
        options: attributesAsOptions,
        onChange: value => typeof value === 'string' && setNewNameAttribute(value),
      },
      {
        name: 'visibility.public',
        type: 'switch',
        inputLabel: 'Visible sur le site public',
      },
      {
        name: 'visibility.professionals',
        type: 'switch',
        inputLabel: 'Visible pour les utilisateurs professionels connectés',
      },
      {
        name: 'visibility.commercials',
        type: 'switch',
        inputLabel: 'Visible pour les utilisateurs commerciaux',
      },
    ]

    if (!currentFile) {
      fields.push({
        name: 'file',
        type: 'files',
        label: 'Fichier',
        disabled: Boolean(currentFile),
      })
    }

    if (newNameAttribute)
      fields[0] = {
        locales: APP.languagues,
        canDuplicateLocales: true,
        defaultDuplicateLocales: true,
        name: 'name',
        type: 'text',
        placeholder: 'Nom...',
        label: 'Nom',
        options: attributesAsOptions,
      }

    return fields
  }, [newNameAttribute, attributesState.all, currentFile])

  return [fields, initialValues, newNameAttribute]
}

export default useFilesFormFields

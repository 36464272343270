import * as React from 'react'
import routes, { getRoute } from '../routes'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { usePages } from '_stores/pages/store'
import { useSession } from '_stores/session/store'

const Logged: React.FunctionComponent = () => {
  const history = useHistory()

  const [pagesState, pagesActions] = usePages()
  const [sessionState, sessionActions] = useSession()
  React.useEffect(() => {
    pagesActions.fetch()
    sessionActions.isAuth({
      token: sessionState.token,
    })
  }, [])

  React.useEffect(() => {
    if (!sessionState.logged) return history.replace('/login')
  }, [sessionState.logged])

  return (
    <Container fluid className="pl-0">
      <Switch>
        {routes
          .filter(route => route.logged)
          .map(route => (
            <Route
              exact={!route.notExact}
              key={Array.isArray(route.path) ? route.path[0] : route.path}
              path={route.path}
              component={route.component}
            />
          ))}
        <Route exact path="/">
          <Redirect to="/products" />
        </Route>
      </Switch>
    </Container>
  )
}

export default Logged

import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { useUsersRequest } from '_stores/users/store'
import InviteUserForm, { InviteUserFormProps } from './invite-form'

interface InviteUserModalProps {
  isOpen: boolean
  setOpen(isOpen?: boolean): void
  description?: string | React.ReactElement
  formProps?: InviteUserFormProps
}

const InviteUserModal: React.FunctionComponent<InviteUserModalProps> = ({
  isOpen,
  setOpen,
  description,
  formProps = {},
}) => {
  const [request, actions] = useUsersRequest({
    name: 'post /users/invite',
  })

  //Clear request on modal close
  React.useEffect(() => {
    if (!isOpen && request && request.status === 'success')
      actions.clearRequest({ key: 'post /users/invite' })
  }, [isOpen, request])

  return (
    <Modal show={isOpen} onHide={() => setOpen(false)}>
      <Modal.Header>Inviter un utilisateur</Modal.Header>
      <Modal.Body>
        <p>{description}</p>
        <div className="mb-3">
          <InviteUserForm {...formProps} />
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default InviteUserModal

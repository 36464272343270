import * as React from 'react'
import { useProducts } from '_stores/products/store'
import Table from '_components/table'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import useDebounce from '../../hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import ProductCreation from './creation'
import Form from '_components/form'
import useListTable from './hooks/use-list-table'
import useListFiltersFields from './hooks/use-list-filters-fields'

const ProductsList: React.FunctionComponent = () => {
  const [state, actions] = useProducts()
  //Get current kind
  const location = useLocation()
  const isAttemptsList = React.useMemo(() => Boolean(location.pathname.match('attempts')), [
    location.pathname,
  ])

  //Search state
  const [search, setSearch] = React.useState(state.filters.search)
  //Debounce hook on search change
  const debouncedSearch = useDebounce(search, 100)
  //Set filter when search change
  React.useEffect(() => {
    actions.setFilters({
      ...state.filters,
      search: search || undefined,
      offset: search ? 0 : state.filters.offset,
    })
  }, [debouncedSearch, isAttemptsList])
  //Get table columns and data
  const [columns, data] = useListTable(state.filters, isAttemptsList)
  //Get filters fields
  const filters = useListFiltersFields(state.filters, isAttemptsList)

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">{isAttemptsList ? 'Tentatives' : 'Produits'}</h1>
        {isAttemptsList && (
          <div className="ml-auto">
            <Button as={Link} to={'/attempts/new'}>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
              Ajouter une tentative
            </Button>
          </div>
        )}
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <Form
            noButton
            fields={filters}
            onFieldChange={actions.setFilter}
            initialValues={state.filters}
          />
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
            defaultValue={state.filters.search}
          />
          <Table
            columns={columns}
            data={data}
            withPagination
            onPageChange={(pageNumber, pageSize) => {
              actions.setFilters({
                ...state.filters,
                limit: pageSize,
                offset: pageNumber * pageSize,
              })
              actions.setCurrentPaginationPage(pageNumber)
            }}
            itemsCount={state.count}
            defaultPage={state.currentPaginationPage}
            defaultPageSize={state.filters.limit}
            onSort={(sortBy): void => {
              if (!sortBy[0]) {
                actions.setFilter('sort', 'name.fr')
              } else {
                let sortKey = sortBy[0].id
                if (sortBy[0].desc) sortKey = `-${sortKey}`
                actions.setFilter('sort', sortKey)
              }
            }}
            defaultSortBy={[
              {
                id: state.filters.sort,
                desc: false,
              },
            ]}
          />
        </div>
      </Card>
      <Switch>
        <Route path={'/attempts/new'} component={ProductCreation} exact />
      </Switch>
    </PageApp>
  )
}

export default ProductsList

import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { Page, PageField, UpdatePageContextPayload } from './types'
import { ClearRequestPayload, genericActions, ResourceState } from '_core/store'
import history from '_core/history'

export interface PagesState extends ResourceState<Page> {
  currentFilters: {
    locales: string[]
  }
}

export type PagesApi = StoreActionApi<PagesState>

const initialState: PagesState = {
  endpoint: '/pages',
  all: [],
  currentId: undefined,
  requests: {},
  currentFilters: {
    locales: [],
  },
}

const actions = {
  fetch: () => async ({ dispatch }: PagesApi) => {
    await dispatch(genericActions.apiFetch())
  },
  get: (_id: string) => async ({ dispatch }: PagesApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: PageField) => async ({ dispatch, getState }: PagesApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: Partial<PageField>) => async ({ dispatch }: PagesApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: Partial<PageField>) => async ({ dispatch }: PagesApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: PagesApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  setCurrent: (page?: Page) => ({ dispatch }: PagesApi) => {
    dispatch(genericActions.setCurrent(page))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: PagesApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: PagesApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: PagesApi) =>
    dispatch(genericActions.getRequest(method, withId)),
  createField: (pageId: string, field: PageField, parentId?: string) => async ({
    dispatch,
  }: PagesApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/pages/${pageId}/fields`,
        method: 'post',
        data: {
          field,
          parentId,
        },
      }),
    )
    if (res && res.result) dispatch(genericActions.addOrReplaceItemInList(res.result))
  },
  updateField: (pageId: string, field: Partial<PageField>) => async ({ dispatch }: PagesApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/pages/${pageId}/fields/${field._id}`,
        method: 'put',
        data: {
          field,
        },
      }),
    )
    if (res && res.result) dispatch(genericActions.addOrReplaceItemInList(res.result))
  },
  removeField: (pageId: string, fieldId: string) => async ({ dispatch }: PagesApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/pages/${pageId}/fields/${fieldId}`,
        method: 'delete',
      }),
    )
    if (res && res.result) dispatch(genericActions.addOrReplaceItemInList(res.result))
  },
  updateContext: (pageId: string, data: UpdatePageContextPayload) => async ({
    dispatch,
  }: PagesApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/pages/${pageId}/context`,
        method: 'put',
        data,
      }),
    )
    if (res && res.result) dispatch(genericActions.addOrReplaceItemInList(res.result))
  },
  publish: (pageId: string) => async ({ dispatch }: PagesApi) => {
    const res = await dispatch(
      genericActions.api({
        url: `/pages/${pageId}/publish`,
        method: 'post',
      }),
    )
    if (res && res.result) dispatch(genericActions.addOrReplaceItemInList(res.result))
  },
}

export type PagesActions = typeof actions

const Store = createStore<PagesState, PagesActions>({
  name: 'pages',
  initialState,
  actions,
})

export const PagesSubscriber = createSubscriber(Store)
export const usePages = createHook(Store)
export const useCurrentPage = createHook<PagesState, PagesActions, Page | undefined | ''>(Store, {
  selector: (state: PagesState) => {
    if (state.currentId) return state.all.find(page => page._id === state.currentId)
    return
  },
})
export const PagesContainer = createContainer(Store)

export default Store

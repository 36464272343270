import * as React from 'react'
import classnames from 'classnames'

interface PageCenteredContentProps {
  children: React.ReactNode
  className?: string
}

const PageCenteredContent: React.FunctionComponent<PageCenteredContentProps> = props => (
  <div className={classnames('page-centered-content', props.className)}>
    <div className="content">{props.children}</div>
  </div>
)

export default PageCenteredContent

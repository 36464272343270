import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useProducts } from '_stores/products/store'
import ProductForm from './form'

const ProductCreation: React.FunctionComponent = () => {
  const [state, actions] = useProducts()
  const history = useHistory()

  return (
    <Modal show={true} onHide={() => history.push('/products')}>
      <Modal.Header>Nouveau produit</Modal.Header>
      <Modal.Body>
        <ProductForm />
      </Modal.Body>
    </Modal>
  )
}
export default ProductCreation

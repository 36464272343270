import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { ApiRequest, genericActions, ResourceState } from '_core/store'
import { Category } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'
import { TreeSelectOption } from '_components/form/fields/tree-select'

export interface CategoriesState extends ResourceState<Category> {
  allIds: string[]
  allAsList: Category[]
}
export type CategoriesApi = StoreActionApi<CategoriesState>

const initialState: CategoriesState = {
  endpoint: '/categories',
  all: [],
  allIds: [],
  allAsList: [],
  currentId: undefined,
  requests: {},
  filters: {},
}

const actions = {
  fetch: () => async ({ dispatch, setState }: CategoriesApi) => {
    const res = await dispatch(
      genericActions.apiFetch({
        mode: 'tree',
      }),
    )
    const allIds: string[] = []
    const allAsList: Category[] = []
    const getCategoryAndChildrensIds = (categories: Category[]) => {
      categories.forEach(category => {
        allIds.push(category._id as string)
        allAsList.push(category)
        if (category.childrens) {
          getCategoryAndChildrensIds(category.childrens)
        }
      })
    }
    if (res.result) {
      getCategoryAndChildrensIds(res.result)
    }
    setState({ allIds, allAsList })
  },
  get: (_id: string) => async ({ dispatch }: CategoriesApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: Category) => async ({ getState, dispatch }: CategoriesApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    await dispatch(actions.fetch())
    //if (res && res.result) history.goBack()
  },
  update: (data: Partial<Category>) => async ({ dispatch }: CategoriesApi) => {
    await dispatch(genericActions.apiUpdate(data))
    await dispatch(actions.fetch())
  },
  createOrUpdate: (data: Partial<Category>) => async ({ dispatch }: CategoriesApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: CategoriesApi) => {
    await dispatch(genericActions.apiDelete(_id))
    await dispatch(actions.fetch())
  },
  getSchema: () => async ({ dispatch }: CategoriesApi) => {
    await dispatch(genericActions.getSchema())
  },
  setFilter: (key: string, value: any) => async ({ dispatch }: CategoriesApi) => {
    dispatch(genericActions.setFilter(key, value))
    await dispatch(actions.fetch())
  },
  setFilters: (values: any) => async ({ dispatch }: CategoriesApi) => {
    dispatch(genericActions.setFilters(values))
    await dispatch(actions.fetch())
  },
  setCurrent: (category?: Category | string) => ({ dispatch }: CategoriesApi) => {
    dispatch(genericActions.setCurrent(category))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: CategoriesApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: CategoriesApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: CategoriesApi) =>
    dispatch(genericActions.getRequest(method, withId)),
}

export type CategoriesActions = typeof actions

const Store = createStore<CategoriesState, CategoriesActions>({
  name: 'categories',
  initialState,
  actions,
})

export const CategoriesSubscriber = createSubscriber(Store)
export const useCategories = createHook(Store)
export const useCurrentCategory = createHook(Store, {
  selector: (state: CategoriesState) => {
    if (state.currentId) return state.allAsList.find(category => category._id === state.currentId)
    return
  },
})
export const useCategoriesForTreeSelectField = createHook(Store, {
  selector: (
    state: CategoriesState,
    props: { locale?: string; disabled?: string },
  ): TreeSelectOption[] => {
    const sanitizer = (category: Category, disabled?: boolean): TreeSelectOption => ({
      label: category.labels[props.locale || 'fr'],
      value: category._id as string,
      disabled: disabled || (props.disabled && category._id === props.disabled),
      childrens:
        category.childrens &&
        category.childrens.map(children =>
          sanitizer(
            children,
            Boolean(disabled || (props.disabled && category._id === props.disabled)),
          ),
        ),
    })
    return state.all.map(category => sanitizer(category))
  },
})
export const useCategoriesRequest = createHook(Store, {
  selector: (state: CategoriesState, method?: string, withId?: boolean): ApiRequest => {
    const { requests, endpoint, currentId } = state
    if (!method && currentId) method = 'put'
    else if (!method) method = 'post'
    if ((method === 'get' && !withId) || method === 'post') return requests[`${method} ${endpoint}`]
    return requests[`${method} ${endpoint}/${currentId}`]
  },
})
export const CategoriesContainer = createContainer(Store)

export default Store

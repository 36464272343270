import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useCurrentProduct, useProductsRequest } from '_stores/products/store'
import { ProductKinds } from '_stores/products/types'

const ProductForm: React.FunctionComponent = () => {
  const [currentProduct, actions] = useCurrentProduct()
  const [request] = useProductsRequest({ method: 'post' })

  const [showI18nInputs, setShowI18nInputs] = React.useState(false)

  const fields: FormFieldProp[] = React.useMemo(() => {
    const fields: FormFieldProp[] = [
      {
        label: 'Nom FR',
        name: 'name.fr',
        type: 'text',
        placeholder: 'Nom...',
      },
      {
        name: 'copyName',
        type: 'checkbox',
        options: [
          {
            label: 'Utiliser le même nom pour le NL et EN?',
            value: true,
          },
        ],
      },
    ]
    if (showI18nInputs) {
      fields.splice(1, 0, {
        label: 'Nom EN',
        name: 'name.en',
        type: 'text',
        placeholder: 'Nom...',
      })
      fields.splice(1, 0, {
        label: 'Nom NL',
        name: 'name.nl',
        type: 'text',
        placeholder: 'Nom...',
      })
    }
    return fields
  }, [showI18nInputs])

  return (
    <>
      <Form
        fields={fields}
        initialValues={currentProduct || { copyName: true, kind: ProductKinds.attempt }}
        validate={(values: any) => {
          const errors: any = {}
          if (!values?.name.fr) errors['name.fr'] = 'Champ requis'
          if (showI18nInputs && !values?.name.nl) errors['name.nl'] = 'Champ requis'
          if (showI18nInputs && !values?.name.en) errors['name.en'] = 'Champ requis'
          return errors
        }}
        onFieldChange={(fieldName, value) => {
          if (fieldName === 'copyName') setShowI18nInputs(!value)
        }}
        onSubmit={(values: any) => {
          if (!showI18nInputs) {
            values.name.nl = values.name.fr
            values.name.en = values.name.fr
          }
          delete values.copyName
          actions.createOrUpdate(values)
        }}
        request={request}
        submitLabel="Créer le nouveau produit"
      />
    </>
  )
}

export default ProductForm

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { Organization } from '_stores/organizations/types'

interface UseListTableProps {
  organizations: Organization[]
}

const useListTable = ({ organizations }: UseListTableProps): [Column[], Organization[]] => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (data: any) => {
          const organization: Organization = data.cell.row.original
          return <Link to={`/organizations/${organization._id}`}>{organization.name}</Link>
        },
      },
      {
        Header: 'Créé via',
        width: '20%',
        accessor: 'createdVia',
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => {
          const organization: Organization = data.cell.row.original
          return <span>{moment(organization.dates.created).format()}</span>
        },
      },
      {
        Header: 'Active',
        width: '5%',
        accessor: 'active',
        Cell: (data: any) => {
          const organization: Organization = data.cell.row.original
          return organization.active && <FontAwesomeIcon icon={faCheck} />
        },
      },
    ],
    [],
  )

  return [columns, organizations]
}

export default useListTable

import * as React from 'react'
import { ListGroup } from 'react-bootstrap'
import { FormProps } from '..'
import ListGroupFormItem from './item'

export interface ListGroupFormField {
  label: string | React.ReactNode
  editLabel?: string | React.ReactNode
  value: string | React.ReactNode
  id?: string
  formProps: FormProps
  saveButtonLabel?: string
  forceEditMode?: boolean
  onCancelClick?(): void
  editableClassName?: string
  buttons?: React.ReactElement
}

interface ListGroupFormProps {
  fields: ListGroupFormField[]
  info?: string | React.ReactNode
}

const ListGroupForm: React.FunctionComponent<ListGroupFormProps> = ({ fields, info }) => (
  <>
    <ListGroup>
      {fields.map((field, index) => (
        <ListGroupFormItem {...field} key={field.id || index} />
      ))}
    </ListGroup>
    <div className="text-right">{info}</div>
  </>
)

export default ListGroupForm

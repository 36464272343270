import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { ResourceState } from '_core/store'
import { ApiKey, ApiKeyPayload } from './types'
import { ClearRequestPayload, defaultResourceInitialState, genericActions } from '_core/store'
import history from '_core/history'

export type ApiKeysState = ResourceState<ApiKey>

export type ApiKeysApi = StoreActionApi<ApiKeysState>

const initialState: ApiKeysState = {
  ...defaultResourceInitialState,
  endpoint: '/api-keys',
}

const actions = {
  fetch: () => async ({ dispatch }: ApiKeysApi) => {
    await dispatch(genericActions.apiFetch())
  },
  get: (_id: string) => async ({ dispatch }: ApiKeysApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: ApiKeyPayload) => async ({ getState, dispatch }: ApiKeysApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: ApiKeyPayload) => async ({ dispatch }: ApiKeysApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: ApiKeyPayload) => async ({ dispatch }: ApiKeysApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: ApiKeysApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  setCurrent: (apiKey?: ApiKey) => ({ dispatch }: ApiKeysApi) => {
    dispatch(genericActions.setCurrent(apiKey))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: ApiKeysApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: ApiKeysApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: ApiKeysApi) =>
    dispatch(genericActions.getRequest(method, withId)),
}

export type ApiKeysActions = typeof actions

const Store = createStore<ApiKeysState, ApiKeysActions>({
  name: 'apiKeys',
  initialState,
  actions,
})

export const ApiKeysSubscriber = createSubscriber(Store)
export const useApiKeys = createHook(Store)
export const useCurrentApiKey = createHook(Store, {
  selector: (state: ApiKeysState) => {
    if (state.currentId) return state.all.find(apiKey => apiKey._id === state.currentId)
    return
  },
})
export const ApiKeysContainer = createContainer(Store)

export default Store

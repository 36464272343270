import {
  faCaretLeft,
  faCaretRight,
  faInfoCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Col, ColProps, Container, Row } from 'react-bootstrap'
import CarouselModal from '_components/carousel/modal'
import CloudinaryImage from '_components/cloudinary-image'
import OverlayDropZone from '_components/overlay-dropzone'
import { ApiRequest } from '_core/store'
import { Media } from '_stores/medias/types'
import MediaThumbnail from './thumbnail'

export interface MediasProps {
  medias: Media[]
  noCarousel?: boolean
  readOnly?: boolean
  onDrop?(files: any[]): void
  onDropzoneHide?(): void
  request?: ApiRequest
  selectable?: boolean
  onSelect?(media: Media): void
  selected?: string[]
  acceptedFiles?: string
  infos?: string[]
  infoMessage?: string
  forceOpen?: boolean
  mediaColProps?: ColProps
  modalReadonly?: boolean
  multiple?: boolean
  onMove?(media: Media, direction: 'left' | 'right', index: number): void
}

const Medias: React.FunctionComponent<MediasProps> = ({
  medias,
  noCarousel,
  readOnly,
  request,
  onDrop,
  onDropzoneHide,
  selectable,
  onSelect,
  selected,
  acceptedFiles,
  infos,
  infoMessage,
  forceOpen,
  mediaColProps,
  multiple,
  onMove,
}) => {
  const [currentCarouselImageIndex, setCurrentCarouselImageIndex] = React.useState(-1)

  const [dropzoneIsVisible, setDropzoneVisible] = React.useState(false)

  React.useEffect(() => {
    if (request && request.status === 'success') setDropzoneVisible(false)
  }, [request])

  React.useEffect(() => {
    if (forceOpen) setDropzoneVisible(true)
  }, [forceOpen])

  return (
    <Container className="m-auto">
      {!noCarousel && (
        <CarouselModal
          medias={medias}
          onSelect={setCurrentCarouselImageIndex}
          activeIndex={currentCarouselImageIndex}
        />
      )}
      {!readOnly && (
        <OverlayDropZone
          onDrop={(files: any) => onDrop && onDrop(files)}
          forceShow={dropzoneIsVisible}
          onHide={() => {
            setDropzoneVisible(false)
            onDropzoneHide && onDropzoneHide()
          }}
          request={request}
          multiple={multiple}
          accept={acceptedFiles || 'image/jpeg,image/gif,image/png,application/pdf,image/x-ep'}
        />
      )}
      <Row className="mt-3 medias">
        {!readOnly && (!medias || !medias[0]) ? (
          <Col md="12">
            <div onClick={() => setDropzoneVisible(true)} className="upload-invite">
              <div>
                <FontAwesomeIcon icon={faPlusCircle} size="3x" />
              </div>
              <div>
                Déposez vos fichiers ici
                <br />
                ou cliquez pour les sélectionner
              </div>
            </div>
          </Col>
        ) : (
          <>
            {!readOnly && (
              <MediaThumbnail
                onClick={() => setDropzoneVisible(true)}
                className="upload-invite"
                colProps={mediaColProps}
              >
                <div>
                  <FontAwesomeIcon icon={faPlusCircle} size="3x" />
                </div>
                <div>
                  Déposez vos fichiers ici
                  <br />
                  ou cliquez pour les sélectionner
                </div>
              </MediaThumbnail>
            )}
            {medias.map((media, index) => (
              <MediaThumbnail
                key={media._id}
                onClick={() => {
                  if (selectable && onSelect && media._id) onSelect(media)
                  else setCurrentCarouselImageIndex(index)
                }}
                colProps={mediaColProps}
              >
                {selectable && (
                  <input
                    //onClick={e => e.preventDefault()}
                    type="radio"
                    onChange={() => media._id && onSelect && onSelect(media)}
                    checked={Boolean(media._id && selected && selected.includes(media._id))}
                  />
                )}
                {infos && media._id && (
                  <span className="media-info">
                    {infos.includes(media._id) ? (
                      <FontAwesomeIcon icon={faInfoCircle} title={infoMessage} />
                    ) : (
                      ' '
                    )}
                  </span>
                )}
                <CloudinaryImage
                  publicId={media.providerId}
                  transformations={{
                    width: '330',
                    height: '330',
                    crop: 'thumb',
                  }}
                />
                {onMove && (
                  <>
                    {index !== 0 && (
                      <Button
                        size="sm"
                        variant="link"
                        onClick={e => {
                          e.stopPropagation()
                          onMove(media, 'left', index)
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </Button>
                    )}
                    {index !== medias.length - 1 && (
                      <Button
                        size="sm"
                        variant="link"
                        onClick={e => {
                          e.stopPropagation()
                          onMove(media, 'right', index)
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretRight} />
                      </Button>
                    )}
                  </>
                )}
              </MediaThumbnail>
            ))}
          </>
        )}
      </Row>
    </Container>
  )
}

export default Medias

import * as React from 'react'
import { useOrganizations } from '_stores/organizations/store'
import Table from '_components/table'
import { Link, Switch, Route } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import useDebounce from '../../hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import OrganizationCreation from './creation'
import useListTable from './hooks/use-list-table'

const OrganizationsList: React.FunctionComponent = () => {
  const [state, actions] = useOrganizations()
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 100)

  /*React.useEffect(() => {
    actions.fetch()
  }, [])*/

  React.useEffect(() => {
    actions.setFilter('search', search)
  }, [debouncedSearch])

  const [columns, data] = useListTable({
    organizations: state.all,
  })

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">Clients</h1>
        {/*<div className="ml-auto">
          <Button as={Link} to={'/organizations/new'}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Nouveau client
          </Button>
  </div> */}
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
          />
          <Table columns={columns} data={data} withPagination />
        </div>
      </Card>
      <Switch>
        <Route path={'/organizations/new'} component={OrganizationCreation} exact />
      </Switch>
    </PageApp>
  )
}

export default OrganizationsList

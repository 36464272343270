import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import CS from 'react-select/creatable'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}

export interface CreatableSelectProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
}

const CreatableSelect: React.FunctionComponent<CreatableSelectProps> = props => {
  const defaultValue = React.useMemo(() => {
    if (!props.defaultValue && props.multiple) return []
    else if (!props.defaultValue) return {}
    if (Array.isArray(props.defaultValue))
      return props.defaultValue.map((value: string) => props.options.find(o => o.value === value))
    return props.options.find(o => o.value === props.defaultValue)
  }, [props.defaultValue, props.options])
  return (
    <Field {...props}>
      <CS
        isClearable
        defaultValue={defaultValue || []}
        name={props.name}
        options={props.options}
        onChange={(newValue: any, actionMeta: any) => {
          const value = actionMeta.action === 'create-option' ? newValue.value : newValue
          props.onChange && props.onChange(value)
          props.onEnterPress && props.onEnterPress(value)
        }}
        classNamePrefix="c-field-react-select"

        /*className= {
          classNames('c-field-react-select', {
            'is-valid': !props.hideValid && !Boolean(props.error),
            'is-invalid': Boolean(props.error)
          })
        }*/
      />
    </Field>
  )
}

export default CreatableSelect

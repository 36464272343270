import { faLink, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import SelectField from '_components/form/fields/select'
import { Organization } from '_stores/organizations/types'
import { useUsers } from '_stores/users/store'
import { User } from '_stores/users/types'

interface UseUsersListTableProps {
  organization?: Organization
  setCurrentUser(user?: User): void
  setUrlModalIsOpen(isOpen: boolean): void
}

const useUsersListTable = ({
  organization,
  setCurrentUser,
  setUrlModalIsOpen,
}: UseUsersListTableProps): [Column[], User[]] => {
  const [usersState, usersActions] = useUsers()
  const [userDeletedDate, setUserDeletedDate] = React.useState(0)

  React.useEffect(() => {
    if (organization)
      usersActions.fetch({
        'organizations.item': [organization._id],
      })
  }, [organization, userDeletedDate])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: (data: any) => {
          const user: User = data.cell.row.original
          return (
            <span /*to={`/organizations/users/${user._id}`}*/>
              {user.firstName} {user.lastName}
            </span>
          )
        },
      },
      {
        Header: 'Email',
        width: '35%',
        accessor: 'email',
      },
      {
        Header: 'Rôle',
        width: '20%',
        accessor: 'role',
        Cell: (data: any) => {
          const user: User = data.cell.row.original
          if (!user || !user._id) return <></>
          return (
            <span /*to={`/organizations/users/${user._id}`}*/>
              <SelectField
                name="role"
                options={[
                  { label: 'Acheteur', value: 'organization/user' },
                  { label: 'Commercial', value: 'organization/commercials' },
                ]}
                defaultValue={user.organizations[0] && user.organizations[0].role}
                className="mb-0"
                onChange={value =>
                  user._id &&
                  user.organizations[0] &&
                  usersActions.updateOrganization({
                    userId: user._id,
                    organizationId: user.organizations[0]._id,
                    role: value,
                  })
                }
              />
            </span>
          )
        },
      },
      {
        Header: ' ',
        width: '10%',
        accessor: 'actions',
        canSort: false,
        Cell: (data: any) => {
          const user: User = data.cell.row.original
          return (
            <div className="text-right">
              {user._id && (
                <a
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    if (user && organization) {
                      await usersActions.deleteOrganization({
                        userId: user._id as string,
                        organizationId: organization._id as string,
                      })
                      setUserDeletedDate(new Date().getTime())
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </a>
              )}
              {!user.firstName && (
                <a
                  className="link ml-2"
                  onClick={() => {
                    setCurrentUser(user)
                    setUrlModalIsOpen(true)
                  }}
                  title="Voir le lien du formulaire de validation du compte"
                >
                  <FontAwesomeIcon icon={faLink} />
                </a>
              )}
            </div>
          )
        },
      },
    ],
    [organization],
  )

  return [columns, usersState.all]
}

export default useUsersListTable

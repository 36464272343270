import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router'
import AttributeForm from './form'

interface AttributeCreationProps {
  defaultLabel?: string
  defaultDuplicate?: boolean
  onHide?(): void
}

const AttributeCreation: React.FunctionComponent<AttributeCreationProps> = ({
  defaultLabel,
  defaultDuplicate,
  onHide,
}) => {
  const history = useHistory()

  return (
    <Modal show={true} onHide={() => (onHide ? onHide() : history.goBack())}>
      <Modal.Header>Nouvel attribut</Modal.Header>
      <Modal.Body>
        <AttributeForm defaultLabel={defaultLabel} defaultDuplicate={defaultDuplicate} />
      </Modal.Body>
    </Modal>
  )
}
export default AttributeCreation

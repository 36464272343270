import * as React from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

export interface TitleProps {
  label: string | React.ReactNode
  className?: string
  level?: 1 | 2 | 3 | 4 | 5 | 6 | '1' | '2' | '3' | '4' | '5' | '6'
  button?: {
    variant?: string
    label: string | React.ReactNode
    onClick(): void
    size?: 'sm' | 'lg'
  }
}

export const Title: React.FunctionComponent<TitleProps> = ({
  label,
  button,
  level = 1,
  className,
}) => {
  const H = `h${level}` as any
  return (
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center bd-highlight mb-4',
        className,
      )}
    >
      <div>
        <H>{label}</H>
      </div>
      <div>
        {button && (
          <Button
            variant={button.variant || 'secondary'}
            onClick={button.onClick}
            size={button.size}
          >
            {button.label}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Title

import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useSession } from '_stores/session/store'

const LoginForm: React.FunctionComponent = () => {
  const [state, actions] = useSession()

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email...',
      },
      {
        name: 'password',
        type: 'password',
        placeholder: 'Password...',
      },
    ],
    [],
  )

  return (
    <Form
      fields={fields}
      initialValues={{}}
      validate={(values: any) => {
        const errors: any = {}
        if (!values.email) errors.email = 'Required field'
        if (!values.password) errors.password = 'Required field'
        return errors
      }}
      onSubmit={actions.login}
      submitLabel="Login"
      request={state.requests && state.requests['post /auth']}
    />
  )
}
export default LoginForm

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { Attribute, AttributeFamilyEnum } from '_stores/attributes/types'

interface UseListTableProps {
  attributes: Attribute[]
  locale?: string
  removeAttribute(attribute: Attribute): void
  family: string
}

const useListTable = ({
  attributes,
  locale,
  removeAttribute,
  family,
}: UseListTableProps): [Column[], Attribute[]] => {
  const columns = React.useMemo(
    () =>
      [
        family === AttributeFamilyEnum.productAttributeSection && {
          Header: '#',
          accessor: `positionIndex`,
          width: '5%',
        },
        {
          Header: 'Name',
          accessor: `labels.${locale || 'fr'}`,
          Cell: (data: any) => {
            const attribute: Attribute = data.cell.row.original
            return (
              <Link to={`/attributes/${attribute._id}`}>{attribute.labels[locale || 'fr']}</Link>
            )
          },
        },
        {
          Header: 'Date',
          width: '15%',
          accessor: 'dates.created',
          Cell: (data: any) => {
            const attribute: Attribute = data.cell.row.original
            return <span>{moment(attribute.dates?.created).format()}</span>
          },
        },
        family === AttributeFamilyEnum.productAttribute && {
          Header: 'Section',
          accessor: `attribute.labels.fr`,
          Cell: (data: any) => {
            const attribute: Attribute = data.cell.row.original
            return (
              <span>
                {attribute.attribute?.labels && attribute.attribute?.labels[locale || 'fr']}
              </span>
            )
          },
        },
        {
          Header: ' ',
          width: '5%',
          accessor: 'action',
          Cell: (data: any) => {
            const attribute: Attribute = data.cell.row.original
            return (
              <a href="#" onClick={() => removeAttribute(attribute)}>
                <FontAwesomeIcon icon={faTrash} />
              </a>
            )
          },
        },
      ].filter(e => e),
    [attributes, locale],
  )

  return [columns, attributes]
}

export default useListTable

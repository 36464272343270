import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface ExpandlableContentProps {
  title: string | React.ReactElement
  children: React.ReactElement
  defaultExpanded?: boolean
  titleLevel?: number
}

const ExpandlableContent: React.FunctionComponent<ExpandlableContentProps> = ({
  children,
  title,
  defaultExpanded,
  titleLevel,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(Boolean(defaultExpanded))
  const H = `h${titleLevel}` as any
  return (
    <div className="mt-3">
      <H onClick={() => setIsExpanded(!isExpanded)} className="u-cursor-pointer">
        {title}
        <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretUp} className="ml-2" />
      </H>
      {isExpanded && children}
    </div>
  )
}
export default ExpandlableContent

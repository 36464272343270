import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { MenuItem } from 'react-pro-sidebar'
import { Link, NavLink } from 'react-router-dom'

interface SidebarLinkProps {
  to?: string
  label: string | React.ReactNode
  setToggled?(toggled: boolean): void
  icon: React.ReactNode
  onClick?(): void
  activeExactMatch?: boolean
}

const LinkContent: React.FunctionComponent<{
  label: tring | React.ReactNode
  icon: React.ReactNode
}> = ({ label, icon }) => (
  <>
    {icon && <span className="menu-icon">{icon}</span>}
    <span className="menu-text">{label}</span>
  </>
)

const SidebarLink: React.FunctionComponent<SidebarLinkProps> = ({
  to,
  label,
  setToggled,
  icon,
  onClick,
  activeExactMatch,
}) => (
  <div className="menu-item">
    {to ? (
      <NavLink to={to} activeClassName="menu-item-active" exact={activeExactMatch}>
        <LinkContent icon={icon} label={label} />
      </NavLink>
    ) : (
      <a
        onClick={() => {
          onClick && onClick()
          setToggled && setToggled(false)
        }}
      >
        <LinkContent icon={icon} label={label} />
      </a>
    )}
  </div>
)

export default SidebarLink

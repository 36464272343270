import * as React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Modal } from 'react-bootstrap'
import Table from '_components/table'
import Form from '_components/form'
import { useAttributes } from '_stores/attributes/store'
import { AttributeFamilyEnum, AttributeTypeEnum } from '_stores/attributes/types'
import { useCurrentProduct, useProducts, useProductsRequest } from '_stores/products/store'
import { ProductFile } from '_stores/products/types'
import useFileFormTable from '../hooks/use-files-form-table'
import useFilesFormFields from '../hooks/use-files-form-fields'
import Loader from '_components/loader'

interface FilesFormProps {
  locale: string
}

const FilesForm: React.FunctionComponent<FilesFormProps> = ({ locale }) => {
  const [currentProduct, actions] = useCurrentProduct()
  const [showNewModal, setShowNewModal] = React.useState(false)
  const [attributesState, attributesActions] = useAttributes()

  const [request] = useProductsRequest({
    name: currentProduct && `post /products/${currentProduct._id}/files`,
  })

  //Fetch all attributes (used for file name) on mount
  React.useEffect(() => {
    if (!attributesState.all[0]) attributesActions.fetch()
  }, [])

  //State
  //Current file displayed on the modal
  const [currentFile, setCurrentFile] = React.useState<ProductFile>()
  //Watch modal close to un set current files
  React.useEffect(() => {
    if (!showNewModal) {
      currentFile && setCurrentFile(undefined)
    }
  }, [showNewModal])
  //Open modal when current file is setted
  React.useEffect(() => {
    if (currentFile && !showNewModal) {
      setShowNewModal(true)
    }
  }, [currentFile])

  //Product file form fields
  const [fields, initialValues, newNameAttribute] = useFilesFormFields({
    locale: 'fr',
    currentFile,
  })
  //Table columns and data
  const [columns, data] = useFileFormTable({
    locale,
    product: currentProduct,
    setCurrentFile,
  })

  if (request && request.status === 'inProgress') return <Loader message={request.loaded} />

  return (
    <>
      <Card>
        <div className="p-4">
          <Table columns={columns} data={data} />
        </div>
      </Card>
      <div className="d-flex justify-content-end mt-2">
        <Button size="sm" variant={'link'} className="mb-2" onClick={() => setShowNewModal(true)}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
          Ajouter un fichier
        </Button>
      </div>
      <Modal show={showNewModal} onHide={() => setShowNewModal(false)}>
        <Modal.Header>{currentFile ? currentFile.path : 'Nouveau fichier'}</Modal.Header>
        <Modal.Body>
          <Form
            initialValues={initialValues}
            fields={fields}
            onSubmit={async (values: any) => {
              let nameAttribute = values.name
              if (newNameAttribute) {
                nameAttribute = await attributesActions.create({
                  labels: values.name,
                  type: AttributeTypeEnum.text,
                  family: AttributeFamilyEnum.productFile,
                  values: [],
                })
              }
              const payload = {
                productId: currentProduct && currentProduct._id,
                ...values,
                name: nameAttribute &&
                  (nameAttribute._id || nameAttribute.labels) && {
                    item: nameAttribute._id,
                    ...nameAttribute.labels,
                  },
              }
              if (currentFile) {
                actions.updateFile({
                  ...payload,
                  productId: currentProduct && currentProduct._id,
                  fileId: currentFile._id,
                })
              } else {
                actions.createFile(payload)
              }
              setShowNewModal(false)
            }}
            validate={values => {
              const errors: any = {}
              if (!currentFile && !values.file) errors.file = 'Fichier requis'
              if (typeof values.name !== 'string' && (!values.name || !values.name.value)) {
                if (!values.name || !values.name || !values.name.fr)
                  errors['name.fr'] = 'Nom requis'
              }
              if (errors['name.fr']) errors.name = errors['name.fr']
              return errors
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FilesForm

import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useOrganizations } from '_stores/organizations/store'
import OrganizationForm from './form'

const OrganizationCreation: React.FunctionComponent = () => {
  const [state, actions] = useOrganizations()
  const history = useHistory()

  return (
    <Modal show={true} onHide={() => history.push('/organizations')}>
      <Modal.Header>Nouveau client</Modal.Header>
      <Modal.Body>
        <OrganizationForm />
      </Modal.Body>
    </Modal>
  )
}
export default OrganizationCreation

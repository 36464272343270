import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Table from '_components/table'
import { Page, PageField, UpdatePageContextPayload } from '_stores/pages/types'
import PageEditFields from './fields'
import _ from 'lodash'
import ConfirmationModal from '_components/confirmation-modal'

interface PageEditFieldListProps {
  page: Page
  locale: string
  field: PageField
  context: any
  updateContext(pageId: string, payload: UpdatePageContextPayload, subContext?: any): void
  contextPath: string
}

const PageEditFieldList: React.FunctionComponent<PageEditFieldListProps> = ({
  page,
  locale,
  field,
  context,
  updateContext,
  contextPath,
}) => {
  const [currentItem, setCurrentItem] = React.useState(undefined)
  const [currentItemIndex, setCurrentItemIndex] = React.useState(-1)
  const [currentContext, setCurrentContext] = React.useState(
    context[locale] && {
      ...context[locale][field.name],
    },
  )
  const contextData =
    context[locale] && context[locale][field.name] ? context[locale][field.name] : []

  const setItem = (item?: any, itemIndex?: number) => {
    setCurrentContext({
      [locale]: item,
    })
    setCurrentItemIndex(item)
    if (itemIndex === undefined) setCurrentItemIndex(-1)
    else setCurrentItemIndex(itemIndex)
    setCurrentItem(item)
  }

  //Remove item
  const [deleteItemIsOpen, setDeleteItemIsOpen] = React.useState(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (d: any, i: number) => (
          <div className="d-flex justify-content-between">
            <Button
              variant="link"
              onClick={() => {
                setItem(d, i)
              }}
            >
              {d[field.options.identifierKey] || 'No name'}
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setItem(d, i)
                setDeleteItemIsOpen(true)
              }}
            >
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [field],
  )

  return (
    <>
      {currentItem && (
        <ConfirmationModal
          isOpen={deleteItemIsOpen}
          title="Delete item"
          description={
            <span>
              Are you sure you want to delete{' '}
              {currentItem[field.options.identifierKey] || 'No name'}?
            </span>
          }
          cancel={() => {
            setItem(undefined)
            setDeleteItemIsOpen(false)
          }}
          confirm={() => {
            page._id &&
              updateContext(page._id, {
                contextPath: `${contextPath}[${currentItemIndex}]`,
                locale,
                value: undefined,
              })
            setItem(undefined)
            setDeleteItemIsOpen(false)
          }}
        />
      )}
      <Modal
        show={Boolean(currentItem) && !deleteItemIsOpen}
        onHide={() => {
          setItem()
        }}
        size="xl"
      >
        <Modal.Body>
          <PageEditFields
            page={page}
            fields={field.options.fields}
            locales={[locale]}
            context={currentContext}
            updateContext={(pageId, payload, newContext) => {
              if (newContext) {
                setItem(newContext, currentItemIndex)
              }
              const items = [...contextData]
              if (currentItemIndex === -1) items.push(newContext)
              else items[currentItemIndex] = newContext
              updateContext(pageId, payload, {
                ...context[locale],
                [field.name]: items,
              })
            }}
            contextPath={`${contextPath}[${contextData.findIndex((o: PageField) =>
              _.isEqual(o, currentItem),
            )}]`}
          />
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-between">
        <h5>{locale}</h5>
        <Button
          size="sm"
          className="mb-2 ml-4"
          onClick={() => {
            setItem({})
          }}
        >
          Add item
        </Button>
      </div>

      <Table columns={columns} data={contextData} />
    </>
  )
}
export default PageEditFieldList

import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useCurrentApiKey } from '_stores/api-keys/store'

const ApiKeyForm: React.FunctionComponent = () => {
  const [currentApiKey, actions] = useCurrentApiKey()

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        name: 'name',
        type: 'text',
        placeholder: 'Name...',
      },
      {
        name: 'role',
        type: 'select',
        options: [
          {
            label: 'Read',
            value: 'read',
          },
          {
            label: 'Write',
            value: 'write',
          },
        ],
      },
    ],
    [],
  )

  return (
    <>
      <Form
        fields={fields}
        initialValues={currentApiKey}
        validate={(values: any) => {
          const errors: any = {}
          if (!values.name) errors.name = 'Required field'
          return errors
        }}
        onSubmit={actions.createOrUpdate}
        request={actions.getRequest()}
        submitLabel="Save"
      />
    </>
  )
}

export default ApiKeyForm

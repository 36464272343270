import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { ApiCallOptions, ApiRequest, genericActions, ResourceState } from '_core/store'
import { Organization } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'

export type OrganizationsState = ResourceState<Organization>
export type OrganizationsApi = StoreActionApi<OrganizationsState>

const initialState: OrganizationsState = {
  endpoint: '/organizations',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {},
}

const actions = {
  fetch: () => async ({ dispatch }: OrganizationsApi) => {
    await dispatch(
      genericActions.apiFetch({
        populate: ['logo'],
        fields: ['logo', 'name', 'dates', '_id', 'createdVia', 'active'],
        sort: 'name',
      }),
    )
  },
  get: (_id: string) => async ({ dispatch }: OrganizationsApi) => {
    await dispatch(
      genericActions.apiGet(_id, {
        data: {
          populate: ['logo'],
        },
      }),
    )
  },
  create: (data: Organization) => async ({ getState, dispatch }: OrganizationsApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: Partial<Organization>, options?: ApiCallOptions) => async ({
    dispatch,
    getState,
  }: OrganizationsApi) => {
    if (!options) options = {}
    options.requestLivetime = 3000
    const res = await dispatch(
      genericActions.api(
        {
          method: 'put',
          url: `${getState().endpoint}/${data._id}`,
          data,
        },
        options,
      ),
    )
    if (res && res.result) await dispatch(actions.get(res.result._id))
  },
  createOrUpdate: (data: Partial<Organization>) => async ({ dispatch }: OrganizationsApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: OrganizationsApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  syncPrices: (organizationId: string) => async ({ dispatch }: OrganizationsApi) => {
    await dispatch(
      genericActions.api({
        method: 'post',
        url: `/organizations/${organizationId}/sync-prices`,
        timeout: 1000 * 60 * 5,
      }),
    )
    await dispatch(actions.get(organizationId))
  },
  getSchema: () => async ({ dispatch }: OrganizationsApi) => {
    await dispatch(genericActions.getSchema())
  },
  setFilter: (key: string, value: any) => async ({ dispatch }: OrganizationsApi) => {
    dispatch(genericActions.setFilter(key, value))
    await dispatch(actions.fetch())
  },
  setFilters: (values: any) => async ({ dispatch }: OrganizationsApi) => {
    dispatch(genericActions.setFilters(values))
    await dispatch(actions.fetch())
  },
  setCurrent: (organization?: Organization) => ({ dispatch }: OrganizationsApi) => {
    dispatch(genericActions.setCurrent(organization))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: OrganizationsApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: OrganizationsApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: OrganizationsApi) =>
    dispatch(genericActions.getRequest(method, withId)),
}

export type OrganizationsActions = typeof actions

const Store = createStore<OrganizationsState, OrganizationsActions>({
  name: 'organizations',
  initialState,
  actions,
})

export const OrganizationsSubscriber = createSubscriber(Store)
export const useOrganizations = createHook(Store)
export const useCurrentOrganization = createHook(Store, {
  selector: (state: OrganizationsState) => {
    if (state.currentId) return state.all.find(organization => organization._id === state.currentId)
    return
  },
})

//TODO: add core function and refactor all stores!!!
export const useOrganizationsRequest = createHook(Store, {
  selector: (
    state: OrganizationsState,
    props: { method?: string; id?: string; name?: string },
  ): ApiRequest | undefined => {
    const { requests, endpoint } = state
    if (props.name) return requests[props.name]
    if (props.method && props.id) return requests[`${props.method} ${endpoint}/${props.id}`]
    else if (props.method) return requests[`${props.method} ${endpoint}`]
    return
  },
})
export const OrganizationsContainer = createContainer(Store)

export default Store

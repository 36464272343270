import * as React from 'react'
import ReactQuill from 'react-quill'
import Field, { BaseFormFieldProps } from '..'

type QuillProps = BaseFormFieldProps

const Quill: React.FunctionComponent<QuillProps> = props => {
  const [value, setValue] = React.useState(props.defaultValue || props.field.value || '')
  return (
    <Field {...props}>
      <ReactQuill
        defaultValue={value}
        value={value}
        modules={{
          toolbar: [
            /*[{ header: '1' }, { header: '2' }, { font: [] }],*/
            //[{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            //[{ color: [] }, { background: [] }],
            [{ list: 'ordered' }, { list: 'bullet' } /*, { indent: '-1' }, { indent: '+1' }*/],
            ['link' /*, 'image', 'video'*/],
            ['clean'],
          ],
        }}
        onChange={(value: any) => {
          setValue(value)
          props.onChange && props.onChange(value)
        }}
        onBlur={() => props.onBlur && props.onBlur(value)}
        style={{ width: '90%' }}
      />
    </Field>
  )
}
export default Quill

import * as React from 'react'
import { useAppUser } from '_stores/session/store'
import PageApp from '_containers/app-pages/page-app'
import DescriptionList from '_components/description-list'
import AutoSaveField from '_components/form/auto-save-field'
import UserPasswordForm from './password-form'
import { Row, Col, Card, ListGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons'

const UserProfile: React.FunctionComponent = () => {
  const [currentUser, actions] = useAppUser()

  const infosFields = React.useMemo(
    () => [
      {
        label: 'First name',
        value: (
          <AutoSaveField
            field={{
              name: 'firstName',
              type: 'text',
            }}
            onSubmit={value =>
              actions.updateProfile({
                firstName: value,
              })
            }
            initialValue={currentUser.firstName}
          />
        ),
      },
      {
        label: 'Last name',
        value: (
          <AutoSaveField
            field={{
              name: 'lastName',
              type: 'text',
            }}
            onSubmit={value =>
              actions.updateProfile({
                lastName: value,
              })
            }
            initialValue={currentUser.lastName}
          />
        ),
      },
      {
        label: 'Email',
        value: (
          <AutoSaveField
            field={{
              name: 'email',
              type: 'text',
              disabled: true,
            }}
            onSubmit={value =>
              actions.updateProfile({
                email: value,
              })
            }
            initialValue={currentUser.email}
          />
        ),
      },
    ],
    [currentUser],
  )

  return (
    <PageApp
      header={{
        title: 'Your profile',
      }}
    >
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h4>
            <FontAwesomeIcon icon={faUserAlt} className="mr-2" />
            General
          </h4>
          <p>View and update your general account information and settings.</p>
          <DescriptionList items={infosFields} />
          <h4 className="mt-5">
            <FontAwesomeIcon icon={faKey} className="mr-2" />
            Password
          </h4>
          <div>
            <UserPasswordForm />
          </div>
        </Col>
      </Row>
    </PageApp>
  )
}

export default UserProfile

import * as React from 'react'
import { Button, ListGroup } from 'react-bootstrap'

interface DescriptionListItem {
  label: string | React.ReactNode
  value: string | React.ReactNode
}

interface DescriptionListProps {
  items: DescriptionListItem[]
}

const DescriptionList: React.FunctionComponent<DescriptionListProps> = ({ items }) => (
  <ListGroup>
    {items.map((item, index) => (
      <ListGroup.Item className="d-flex align-items-center" key={item.label}>
        <div className="flex-fill">
          <div className="u-color-light">{item.label}</div>
          <div>{item.value}</div>
        </div>
      </ListGroup.Item>
    ))}
  </ListGroup>
)

export default DescriptionList

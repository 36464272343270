import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { ApiCallOptions, ApiRequest, genericActions, ResourceState } from '_core/store'
import { Attribute, AttributeAsSelectOption, AttributeValue } from './types'
import { ClearRequestPayload } from '_core/store'
import { orderBy } from 'lodash'

export type AttributesState = ResourceState<Attribute>
export type AttributesApi = StoreActionApi<AttributesState>

const initialState: AttributesState = {
  endpoint: '/attributes',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {},
}

export const actions = {
  fetch: () => async ({ dispatch }: AttributesApi): Promise<void> => {
    await dispatch(
      genericActions.apiFetch({
        populate: ['attribute'],
        sort: 'positionIndex',
      }),
    )
  },
  get: (_id: string) => async ({ dispatch }: AttributesApi): Promise<void> => {
    await dispatch(
      genericActions.apiGet(_id, {
        data: {
          populate: ['attribute'],
        },
      }),
    )
  },
  create: (data: Attribute) => async ({ dispatch }: AttributesApi): Promise<any> => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) return res.result
  },
  update: (data: Partial<Attribute>, options?: ApiCallOptions) => async ({
    dispatch,
  }: AttributesApi): Promise<any> => {
    const res = await dispatch(genericActions.apiUpdate(data, options))
    if (res && res.result) {
      await dispatch(actions.get(res.result._id))
      return res.result
    }
  },
  createOrUpdate: (data: Partial<Attribute>) => async ({
    dispatch,
  }: AttributesApi): Promise<void> => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: AttributesApi): Promise<void> => {
    await dispatch(genericActions.apiDelete(_id))
    await dispatch(actions.fetch())
  },
  createValue: (attributeId: string, payload: AttributeValue, options?: ApiCallOptions) => async ({
    dispatch,
    getState,
  }: AttributesApi): Promise<void> => {
    const res = await dispatch(
      genericActions.api(
        {
          method: 'post',
          url: `${getState().endpoint}/${attributeId}/values`,
          data: payload,
        },
        options,
      ),
    )
    await dispatch(actions.get(attributeId))
    return res.result
  },
  updateValue: (attributeId: string, payload: AttributeValue, options?: ApiCallOptions) => async ({
    dispatch,
    getState,
  }: AttributesApi): Promise<void> => {
    await dispatch(
      genericActions.api(
        {
          method: 'put',
          url: `${getState().endpoint}/${attributeId}/values/${payload._id}`,
          data: payload,
        },
        options,
      ),
    )
    await dispatch(actions.get(attributeId))
  },
  deleteValue: (id: string, valueId: string, options?: ApiCallOptions) => async ({
    getState,
    dispatch,
  }: AttributesApi): Promise<void> => {
    await dispatch(
      genericActions.api(
        {
          method: 'delete',
          url: `${getState().endpoint}/${id}/values/${valueId}`,
        },
        options,
      ),
    )
    await dispatch(actions.get(id))
  },
  getSchema: () => async ({ dispatch }: AttributesApi): Promise<void> => {
    await dispatch(genericActions.getSchema())
  },
  setFilter: (key: string, value: any) => async ({ dispatch }: AttributesApi): Promise<void> => {
    dispatch(genericActions.setFilter(key, value))
    await dispatch(actions.fetch())
  },
  setFilters: (values: any) => async ({ dispatch }: AttributesApi): Promise<void> => {
    dispatch(genericActions.setFilters(values))
    await dispatch(genericActions.apiFetch())
  },
  setCurrent: (attribute?: Attribute) => ({ dispatch }: AttributesApi): void => {
    dispatch(genericActions.setCurrent(attribute))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: AttributesApi): void => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: AttributesApi): void => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: AttributesApi): Promise<void> =>
    dispatch(genericActions.getRequest(method, withId)),
}

export type AttributesActions = typeof actions

const Store = createStore<AttributesState, AttributesActions>({
  name: 'attributes',
  initialState,
  actions,
})

export const AttributesSubscriber = createSubscriber(Store)
export const useAttributes = createHook(Store)
export const useCurrentAttribute = createHook(Store, {
  selector: (state: AttributesState) => {
    if (state.currentId) return state.all.find(attribute => attribute._id === state.currentId)
    return
  },
})
export const useAttributesForSelectField = createHook(Store, {
  selector: (
    state: AttributesState,
    props: { locale?: string; filter?(attribute: AttributeAsSelectOption): boolean },
  ): AttributeAsSelectOption[] => {
    const sanitizer = (attribute: Attribute): AttributeAsSelectOption => ({
      ...attribute,
      label: attribute.labels[props.locale || 'fr'],
      value: attribute._id,
    })
    let attibutesAsOptions = state.all.map(attribute => sanitizer(attribute))
    if (props.filter) attibutesAsOptions = attibutesAsOptions.filter(props.filter)
    return orderBy(attibutesAsOptions, [
      attribute => attribute.labels[props.locale || 'fr'].toLowerCase(),
    ])
  },
})
export const useAttributesRequest = createHook(Store, {
  selector: (
    state: AttributesState,
    props: { method?: string; id?: string; name?: string },
  ): ApiRequest | undefined => {
    const { requests, endpoint } = state
    if (props.name) return requests[props.name]
    if (props.method && props.id) return requests[`${props.method} ${endpoint}/${props.id}`]
    else if (props.method) return requests[`${props.method} ${endpoint}`]
    return
  },
})

export default Store

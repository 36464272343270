import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import ExpandlableContent from '_components/expandable-content'
import { useCurrentProduct } from '_stores/products/store'
import useMafactDataFields, { MafactDataField } from '../hooks/use-mafact-data-fields'

interface MafactDataProps {
  locale: string
}

interface MafactDateFieldProps {
  item: MafactDataField
  className?: string
}

const Field: React.FunctionComponent<MafactDateFieldProps> = ({ item, className }) => (
  <Col md={6} className={className} key={item.label}>
    {/*TODO....... :P */}
    <div className="flex-fill">
      <div className="u-color-light">{item.label}</div>
      <div>
        {!item.value && (
          <FontAwesomeIcon size="xs" icon={faExclamationTriangle} className="mr-1 text-warning" />
        )}
        {item.value || 'N/A'}
      </div>
    </div>
  </Col>
)

const MafactData: React.FunctionComponent<MafactDataProps> = ({ locale }) => {
  const [currentProduct] = useCurrentProduct()
  const [generalsFields, pricingFields, packaginFields] = useMafactDataFields(currentProduct)
  return (
    <Row>
      {generalsFields.map((item, index) => (
        <Field item={item} className={index > 1 ? 'mt-3' : ''} key={item.label} />
      ))}
      <Col md={12} className="mt-3">
        <ExpandlableContent title="Prix et taxes" titleLevel={5}>
          <Row>
            {pricingFields.map((item, index) => (
              <Field item={item} className={index > 1 ? 'mt-3' : ''} key={item.label} />
            ))}
          </Row>
        </ExpandlableContent>
      </Col>
      <Col md={12}>
        <ExpandlableContent title="Emballage" titleLevel={5}>
          <Row>
            {packaginFields.map((item, index) => (
              <Field item={item} className={index > 1 ? 'mt-3' : ''} key={item.label} />
            ))}
          </Row>
        </ExpandlableContent>
      </Col>
    </Row>
  )
}
export default MafactData

import * as React from 'react'
import { Row } from 'react-bootstrap'
import { useMedias } from '_stores/medias/store'
import { useCurrentPage } from '_stores/pages/store'
import { useSession } from '_stores/session/store'
import PageEditFields from './fields'
import PageEditToolsBar from './tools-bar'

const PageEdit: React.FunctionComponent = () => {
  const [currentPage, actions] = useCurrentPage()

  const [mediasState, mediasActions] = useMedias()

  React.useEffect(() => {
    mediasActions.fetch()
  }, [])

  const [sessionState] = useSession()
  if (!currentPage || !currentPage._id) return <></>

  const [filteredLocales, setFilteredLocales] = React.useState(currentPage.settings.locales || [])
  const filterLocale = (locale: string) => {
    if (filteredLocales.includes(locale)) {
      const locales = filteredLocales.filter(l => l !== locale)
      if (!locales[0] && currentPage) setFilteredLocales(currentPage.settings.locales)
      else setFilteredLocales(locales)
    } else setFilteredLocales([...filteredLocales, locale])
  }

  return (
    <Row>
      <PageEditToolsBar
        page={currentPage}
        filterLocale={filterLocale}
        filteredLocales={filteredLocales}
        publish={actions.publish}
      />
      <PageEditFields
        page={currentPage}
        fields={currentPage.fields}
        locales={filteredLocales}
        context={currentPage.context}
        updateContext={actions.updateContext}
      />
    </Row>
  )
}

export default PageEdit

import * as React from 'react'
import { Link } from 'react-router-dom'
import CloudinaryImage from '_components/cloudinary-image'
import { Media } from '_stores/medias/types'
import { Product } from '_stores/products/types'
import Highlighter from 'react-highlight-words'
import ProductCategories from '_components/products/categories'
import moment from 'moment'
import { Column } from 'react-table'
import { useProducts } from '_stores/products/store'

const useListTable = (filters: any, isAttemptsList: boolean): [Column[], Product[]] => {
  const [state] = useProducts()

  //Get search words (form search) for highlighter component
  const searchWords: string[] = React.useMemo(
    () => (filters.search ? filters.search.split(' ') : []),
    [filters],
  )
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name.fr',
        width: '35%',
        Cell: (data: any) => {
          const product: Product = data.cell.row.original
          const cover: Media | undefined =
            product.pictures[0] && (product.pictures[0].item as Media)
          return (
            <div className="d-flex align-items-center">
              <div className="mr-3">
                <Link to={`/${isAttemptsList ? 'attempts' : 'products'}/${product._id}`}>
                  <CloudinaryImage
                    publicId={cover ? cover.providerId : 'no_picture'}
                    transformations={{
                      width: '60',
                      height: '60',
                      crop: 'thumb',
                    }}
                  />
                </Link>
              </div>

              <Link to={`/${isAttemptsList ? 'attempts' : 'products'}/${product._id}`}>
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={searchWords}
                  autoEscape={true}
                  textToHighlight={product.name.fr || ''}
                />
              </Link>
            </div>
          )
        },
      },
      !isAttemptsList
        ? {
            Header: 'Mafact Ref',
            width: '15%',
            accessor: 'mafactRef',
            Cell: (data: any) => {
              const product: Product = data.cell.row.original
              if (!product.mafactData) return <span></span>
              return (
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={searchWords}
                  autoEscape={true}
                  textToHighlight={product.mafactData.ref}
                />
              )
            },
          }
        : {
            Header: 'Note',
            width: '15%',
            accessor: 'votes',
            Cell: (data: any) => {
              const product: Product = data.cell.row.original
              let total = 0
              if (!product.votes) return <span></span>
              product.votes.forEach(vote => {
                total += vote.score
              })
              return (
                <div>
                  {total} / {product.votes.length * 5}
                </div>
              )
            },
          },
      {
        Header: 'Catégorie',
        width: '35%',
        canSort: false,
        Cell: (data: any) => {
          const product: Product = data.cell.row.original
          return (
            <div className="d-flex align-items-center" style={{ minHeight: 60 }}>
              <ProductCategories product={product} locale={'fr'} />
            </div>
          )
        },
      },
      {
        Header: 'Marque',
        width: '35%',
        canSort: false,
        accessor: 'brand.name',
      },
      {
        Header: "Date d'ajout",
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => {
          const product: Product = data.cell.row.original
          return <span>{moment(product.dates?.created).format()}</span>
        },
      },
    ],
    [searchWords, isAttemptsList],
  )

  const products = state.all

  return [columns, products]
}

export default useListTable

import * as React from 'react'
import Field, { BaseFormFieldProps } from '..'
import { Button } from 'react-bootstrap'
import Medias, { MediasProps } from '_components/medias'
import { useMedias } from '_stores/medias/store'
import { Media } from '_stores/medias/types'
import MediasModal from '_components/medias/modal'

export interface MediasFieldProps extends BaseFormFieldProps {
  multiple?: boolean
  mediasProps?: Partial<MediasProps>
  actions?: React.ReactElement[]
}

const MediasField: React.FunctionComponent<MediasFieldProps> = props => {
  const [state] = useMedias()

  //Modal display
  const [modalIsVisible, setModalVisible] = React.useState(false)
  //Fetch medias
  /*React.useEffect(() => {
    actions.fetch()
  }, [])*/

  //Manage used medias from default value
  const [usedMedias, setUsedMedias] = React.useState([] as Media[])
  React.useEffect(() => {
    if (props.defaultValue && props.defaultValue[0])
      setUsedMedias(
        props.defaultValue.map((id: string) => state.all.find(media => media._id === id)),
      )
    else setUsedMedias([])
  }, [props.defaultValue, state.all])
  //Media props
  const mediasProps = React.useMemo(() => (props.mediasProps ? props.mediasProps : {}), [
    props.mediasProps,
  ])

  return (
    <>
      <MediasModal
        modalIsVisible={modalIsVisible}
        multiple={props.multiple}
        setModalVisible={setModalVisible}
        medias={state.all}
        defaultValue={props.defaultValue}
        onSubmit={selectedMedias => {
          props.form && props.form.setFieldValue(props.field.name, selectedMedias)
          if (props.onEnterPress) props.onEnterPress(selectedMedias)
          setModalVisible(false)
        }}
        modalReadonly={props.mediasProps?.modalReadonly}
      />
      <Field {...props}>
        <Button onClick={() => setModalVisible(true)} className="mr-2">
          Select medias
        </Button>
        {props.actions && props.actions.map((a, index) => <span key={index}>{a}</span>)}
      </Field>
      <Medias {...mediasProps} medias={usedMedias} multiple={props.multiple} />
    </>
  )
}

export default MediasField

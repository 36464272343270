import * as React from 'react'
import { Modal } from 'react-bootstrap'
import Carousel, { CarouselProps } from '.'

type CarouselModalProps = CarouselProps

const CarouselModal: React.FunctionComponent<CarouselModalProps> = props => (
  <Modal
    size="lg"
    show={Boolean(props.activeIndex !== -1)}
    onHide={() => props.onSelect && props.onSelect(-1)}
  >
    <Modal.Body className="text-center bg-dark">
      <Carousel {...props} />
    </Modal.Body>
  </Modal>
)

export default CarouselModal

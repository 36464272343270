import * as React from 'react'
import { useMedias } from '_stores/medias/store'
import PageApp from '_containers/app-pages/page-app'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckDouble,
  faEdit,
  faFilePdf,
  faFolderOpen,
  faImage,
  faImages,
  faInfoCircle,
  faPlusCircle,
  faRedo,
  faTimes,
  faTrash,
  faVideo,
} from '@fortawesome/free-solid-svg-icons'
import Medias from '_components/medias'
import SidebarLink from '_components/sidebar/link'
import { useParams } from 'react-router'
import RequestMessage from '_components/request-message'

const MediasList: React.FunctionComponent = () => {
  const locationParams: any = useParams()
  const [state, actions] = useMedias()
  React.useEffect(() => {
    actions.fetch()
    return () => {
      actions.clearUsed()
    }
  }, [])

  const medias = React.useMemo(() => {
    if (!locationParams.type) return state.all
    if (locationParams.type === 'unused') {
      if (!state.used) actions.checkUsed()
      return state.all.filter(media => media._id && state.used?.unused.includes(media._id))
    }
    return state.all.filter(media => media.type === locationParams.type)
  }, [state.all, locationParams.type, state.used])

  //Select mode
  const [areSelectable, setSelectable] = React.useState(false)
  const [selectedMedias, setSelectedMedias] = React.useState([] as string[])
  const selectMedia = (mediaId: string) => {
    const indexOfMedia = selectedMedias.findIndex(m => m === mediaId)
    if (indexOfMedia === -1) return setSelectedMedias([...selectedMedias, mediaId])
    selectedMedias.splice(indexOfMedia, 1)
    setSelectedMedias([...selectedMedias])
  }

  React.useEffect(() => {
    setSelectedMedias([])
    setSelectable(false)
  }, [locationParams.type])

  //Open dropzone
  const [openDropzone, setOpenDropzone] = React.useState(false)
  React.useEffect(() => {
    setOpenDropzone(false)
  }, [openDropzone])

  const request = state.requests && state.requests['get /medias']

  return (
    <PageApp contentClassName="p-0">
      <div className="medias-gallery">
        <div className="medias-gallery-menu">
          <div className="menu-header">Tools</div>
          <SidebarLink
            onClick={() => setSelectable(!areSelectable)}
            icon={<FontAwesomeIcon icon={faEdit} />}
            label={<span>Sélectionner</span>}
          />
          {areSelectable && (
            <>
              <SidebarLink
                onClick={() => {
                  selectedMedias.forEach(mediaId => actions.delete(mediaId))
                  setSelectedMedias([])
                }}
                icon={<FontAwesomeIcon icon={faTimes} />}
                label={<span>Supprimer</span>}
              />
              <SidebarLink
                onClick={() => {
                  if (selectedMedias.length === medias.length) setSelectedMedias([])
                  else setSelectedMedias(medias.map(media => media._id))
                }}
                icon={<FontAwesomeIcon icon={faCheckDouble} />}
                label={<span>Sélectionner tous</span>}
              />
            </>
          )}
          <div className="menu-header d-flex align-items-center">
            <div className="mr-auto">Medias</div>
            <Button size="sm" variant="link" onClick={() => actions.fetch()}>
              {request && <RequestMessage request={request} loader />}
              {(!request || request.status !== 'inProgress') && <FontAwesomeIcon icon={faRedo} />}
            </Button>
          </div>
          <SidebarLink
            to="/medias"
            icon={<FontAwesomeIcon icon={faFolderOpen} />}
            label={<span>Tous</span>}
            activeExactMatch
          />
          <SidebarLink
            to="/medias/types/image"
            icon={<FontAwesomeIcon icon={faImages} />}
            label={<span>Images</span>}
            activeExactMatch
          />
          <SidebarLink
            to="/medias/types/pdf"
            icon={<FontAwesomeIcon icon={faFilePdf} />}
            label={<span>PDF</span>}
            activeExactMatch
          />
          {/*<SidebarLink
            to="/medias/types/video"
            icon={<FontAwesomeIcon icon={faVideo} />}
            label={<span>Vidéos</span>}
            activeExactMatch
          /> */}
          <div className="menu-header">Others</div>
          <SidebarLink
            to="/medias/types/unused"
            icon={<FontAwesomeIcon icon={faImages} />}
            label={<span>Non utilisé</span>}
            activeExactMatch
          />
          <SidebarLink
            onClick={() => setOpenDropzone(true)}
            icon={<FontAwesomeIcon icon={faPlusCircle} />}
            label={<span>Ajouter</span>}
          />
        </div>
        <div className="medias-list">
          <Medias
            medias={medias}
            onDrop={actions.create}
            onDropzoneHide={() => actions.clearRequest({ key: 'post /medias' })}
            request={state.requests['post /medias']}
            selectable={areSelectable}
            onSelect={media => media._id && selectMedia(media._id)}
            selected={selectedMedias}
            forceOpen={openDropzone}
          />
        </div>
      </div>
    </PageApp>
  )
}

export default MediasList
/*

      <div className="d-flex page-tools-bar">
        <ButtonGroup>
          <Button
            variant={areSelectable ? 'primary' : 'secondary'}
            onClick={() => setSelectable(!areSelectable)}
          >
            <FontAwesomeIcon icon={faCheckSquare} />
          </Button>
          {areSelectable && (
            <DropdownButton
              as={ButtonGroup}
              title={`${selectedMedias.length} medias selected`}
              id="bg-nested-dropdown"
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() => {
                  selectedMedias.forEach(mediaId => actions.delete(mediaId))
                  setSelectedMedias([])
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Delete
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  if (state.all.length !== selectedMedias.length)
                    setSelectedMedias(state.all.map(m => m._id) as string[])
                  else setSelectedMedias([])
                }}
              >
                <FontAwesomeIcon icon={faCheckDouble} className="mr-2" />
                {state.all.length !== selectedMedias.length ? 'Select all' : 'Unselect all'}
              </Dropdown.Item>
              {state.used && state.used.unused && state.used.unused[0] && (
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    if (state.used) setSelectedMedias(state.used.unused)
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                  Select all unused
                </Dropdown.Item>
              )}
            </DropdownButton>
          )}
        </ButtonGroup>
        <Button variant={'primary '} className="ml-2" onClick={() => actions.checkUsed()}>
          See unused
        </Button>
      </div>*/

import * as React from 'react'
import { useCurrentOrganization } from '_stores/organizations/store'
import OrganizationForm from './form'
import { Organization, TurnoverTarget } from '_stores/organizations/types'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { faInfo, faMap, faPercent, faPlusCircle, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb, Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ListGroupFormTitleWrapper from '_components/form/list-group/title'
import Table from '_components/table'
import useUsersListTable from './hooks/use-users-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InviteUserModal from '_containers/users/invite-modal'
import UserInviteUrlModal from '_containers/users/invite-url-modal'
import { User } from '_stores/users/types'
import useTurnoverTargetsTable from './hooks/use-turnover-target-table'
import TurnoverTargetForm from './item/turnover-target-form'
import OrganizationContact from './item/contact'
import useContactsArray from './hooks/use-contacts-array'
import OrganizationAddress from './item/address'
import MafactData from './item/mafact-data'

export interface OrganizationItemProps {
  organization: Organization
}

const OrganizationItem: React.FunctionComponent = () => {
  const [currentOrganization, actions] = useCurrentOrganization()

  //Invite URL modal state
  const [urlModalIsOpen, setUrlModalIsOpen] = React.useState(false)

  //Invite modal state
  const [currentUser, setCurrentUser] = React.useState<User>()
  const [inviteUserModalIsOpen, setInviteUserModalIsOpen] = React.useState(false)

  //Users table columns & data
  const [usersColumns, usersData] = useUsersListTable({
    organization: currentOrganization,
    setCurrentUser,
    setUrlModalIsOpen,
  })

  //Turnover modal state
  const [currentTurnoverTarget, setCurrentTurnoverTarget] = React.useState<TurnoverTarget>()
  const [turnoverTargetModalIsOpen, setTurnoverTargetModalIsOpen] = React.useState(false)

  React.useEffect(() => {
    if (!turnoverTargetModalIsOpen) setCurrentTurnoverTarget(undefined)
  }, [turnoverTargetModalIsOpen])

  //Turnover targets columns & data
  const [turnoverTargetColumns, turnoverTargetData] = useTurnoverTargetsTable({
    organization: currentOrganization,
    onItemClick: turnoverTarget => {
      setCurrentTurnoverTarget(turnoverTarget)
      setTurnoverTargetModalIsOpen(true)
    },
  })

  //Organization contacts as Array
  const contacts = useContactsArray({ organization: currentOrganization })

  return (
    <PageApp>
      <Breadcrumb>
        <LinkContainer to="/organizations">
          <Breadcrumb.Item>Organizations</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{currentOrganization && currentOrganization.name}</Breadcrumb.Item>
      </Breadcrumb>
      <hr className="mb-4"></hr>
      <ApiItemWrapper item={currentOrganization} actions={actions}>
        <Row className="justify-content-center">
          <Col xl={10}>
            <Row>
              <Col xl={12}>
                <ListGroupFormTitleWrapper
                  icon={faInfo}
                  title="Données mafact"
                  description={'Visualiser les données du client fournies ar Mafact.'}
                >
                  <Card className="p-4">
                    <MafactData locale={'fr'} />
                  </Card>
                </ListGroupFormTitleWrapper>
                <ListGroupFormTitleWrapper
                  icon={faInfo}
                  title="Général"
                  description={"Visualiser et modifier les données de bases d'un client."}
                >
                  <OrganizationForm />
                </ListGroupFormTitleWrapper>
                <ListGroupFormTitleWrapper
                  icon={faUsers}
                  title="Utilisateurs"
                  description={"Visualiser et gérer les utilisateurs d'un client."}
                >
                  <>
                    <Card className="p-4">
                      <Table columns={usersColumns} data={usersData} />
                    </Card>
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        size="sm"
                        variant={'link'}
                        className="mb-2"
                        onClick={() => setInviteUserModalIsOpen(true)}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                        Inviter un utilisateur
                      </Button>
                    </div>
                  </>
                </ListGroupFormTitleWrapper>
                <ListGroupFormTitleWrapper
                  icon={faPercent}
                  title="Objectifs de chiffre d'affaires"
                  description={
                    "Gérer les objectifs de chiffres d'affaire pour les remises de ce client"
                  }
                >
                  <>
                    <Card className="p-4">
                      <Table columns={turnoverTargetColumns} data={turnoverTargetData} />
                    </Card>
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        size="sm"
                        variant={'link'}
                        className="mb-2"
                        onClick={() => setTurnoverTargetModalIsOpen(true)}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                        Ajouter un objectif
                      </Button>
                    </div>
                  </>
                </ListGroupFormTitleWrapper>
                <ListGroupFormTitleWrapper
                  icon={faUsers}
                  title="Contacts"
                  description={'Visualiser les différents contacts fournis par le client'}
                >
                  <Row>
                    {contacts.map((contact, index) => (
                      <Col md={6} key={index}>
                        <Card className="p-4 mb-4">
                          <OrganizationContact {...contact} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </ListGroupFormTitleWrapper>
                <ListGroupFormTitleWrapper
                  icon={faMap}
                  title="Adresses"
                  description={'Visualiser les différentes addresses du client'}
                >
                  <Row>
                    {currentOrganization?.addresses.map(address => (
                      <Col md={6} key={address._id}>
                        <Card className="p-4 mb-4">
                          <OrganizationAddress address={address} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </ListGroupFormTitleWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </ApiItemWrapper>
      <UserInviteUrlModal
        isOpen={urlModalIsOpen}
        setOpen={show => setUrlModalIsOpen(show)}
        user={currentUser}
      />
      <InviteUserModal
        isOpen={inviteUserModalIsOpen}
        setOpen={isOpen => setInviteUserModalIsOpen(Boolean(isOpen))}
        description={
          currentOrganization && (
            <p>
              Inviter un utilisateur à rejoindre l'organisation{' '}
              <strong>{currentOrganization.name}</strong>. <br />
              Il pourra donc accéder à toutes les informations privées concernant l'organisation
              (commandes, chiffre d'affaire Exalto, ...). <br /> <br />
              Un email avec un lien qui permet de compléter le compte sera envoyé au nouvel
              utilsateur.
            </p>
          )
        }
        formProps={{
          excludedFields: ['role'],
          initialValues: {
            role: 'organization/user',
            organizationRole: 'organization/user',
            organization: currentOrganization && currentOrganization._id,
            language: 'fr',
          },
        }}
      />
      <Modal show={turnoverTargetModalIsOpen} onHide={() => setTurnoverTargetModalIsOpen(false)}>
        <Modal.Header>
          {currentTurnoverTarget ? "Modification de l'objectif" : 'Nouvel objectif'}
        </Modal.Header>
        <Modal.Body>
          {currentOrganization && (
            <TurnoverTargetForm
              organization={currentOrganization}
              turnoverTarget={currentTurnoverTarget}
              onSubmit={() => setTurnoverTargetModalIsOpen(false)}
            />
          )}
        </Modal.Body>
      </Modal>
    </PageApp>
  )
}

export default OrganizationItem

import * as React from 'react'
import { useCurrentOrder, useOrdersRequest } from '_stores/orders/store'
import { Order } from '_stores/orders/types'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDollarSign,
  faExclamationTriangle,
  faInfo,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import useDataFields from './hooks/use-data-fields'
import { orderBy } from 'lodash'
import RequestMessage from '_components/request-message'
import moment from 'moment'

export interface OrderItemProps {
  order: Order
}

const Field: React.FunctionComponent<any> = ({ item, className }) => (
  <Col md={6} className={className} key={item.label}>
    <div className="flex-fill">
      <div className="u-color-light">{item.label}</div>
      <div>
        {!item.value && (
          <FontAwesomeIcon size="xs" icon={faExclamationTriangle} className="mr-1 text-warning" />
        )}
        {item.value || 'N/A'}
      </div>
    </div>
  </Col>
)

const OrderItem: React.FunctionComponent = () => {
  const [currentOrder, actions] = useCurrentOrder()
  const [mafactSyncRequest] = useOrdersRequest({
    name: currentOrder && `post /orders/${currentOrder._id}/mafact-sync`,
  })

  const [fields] = useDataFields(currentOrder)

  //Check if order is created in last minute
  const isYoung = React.useMemo(() => {
    return Boolean(
      currentOrder &&
        new Date().getTime() - new Date(currentOrder.dates.created).getTime() < 1000 * 60,
    )
  }, [currentOrder])

  return (
    <ApiItemWrapper item={currentOrder} actions={actions}>
      <PageApp>
        <Breadcrumb>
          <Breadcrumb>
            <LinkContainer to="/products">
              <Breadcrumb.Item>Commandes</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>
              {currentOrder && currentOrder.mafactData && currentOrder.mafactData.ref
                ? currentOrder.mafactData.ref
                : 'N/A'}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Breadcrumb.Item active>{currentOrder && currentOrder.name}</Breadcrumb.Item>
        </Breadcrumb>
        <hr className="mb-4"></hr>
        <Row className="justify-content-center">
          <Col xl={10}>
            {currentOrder && (!currentOrder.mafactData || !currentOrder.mafactData.ref) && (
              <>
                <Row className="mb-4">
                  <Col xl={12}>
                    <div className="mb-4">
                      <h4>
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-2 text-danger"
                        />
                        Action requise
                      </h4>
                      <p className="text-danger font-weight-bold">
                        La commande n'est été pas enregistrée dans Mafact!
                      </p>
                      {/* <BrandForm locale={currentLocale} /> */}
                      <div className="mt-2">
                        <RequestMessage request={mafactSyncRequest} loader check />
                      </div>
                      {isYoung && (
                        <p>
                          Le produit vient d'être créé (
                          {currentOrder && moment(currentOrder.dates.creates).format('HH:mm:ss')}),
                          la synchronisation est peut-être encore en cours.
                        </p>
                      )}
                      <Button
                        variant="danger"
                        onClick={() => actions.mafactSync(currentOrder._id)}
                        disabled={
                          isYoung ||
                          (mafactSyncRequest && mafactSyncRequest.status === 'inProgress')
                        }
                      >
                        Cliquez ici pour l'enregistrer
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xl={12}>
                <div className="mb-4">
                  <h4>
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    Détails de la commande
                  </h4>
                  <p>Visualiser les commandes passées depuis le shop.</p>
                  {/* <BrandForm locale={currentLocale} /> */}
                </div>
              </Col>
            </Row>
            <Card className="p-4">
              <Row>
                {fields.map((item, index) => (
                  <Field item={item} className={index > 1 ? 'mt-3' : ''} key={item.label} />
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      </PageApp>
    </ApiItemWrapper>
  )
}

export default OrderItem

import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import LoginForm from './form'
import { Link } from 'react-router-dom'

const Login: React.FunctionComponent = () => (
  <PageCenteredForm
    title={
      <div className="d-flex justify-content-center">
        <img
          src="https://exalto.be/static/506b281920a22cc7b6f196900e61bad0/fba31/exalto-logo.webp"
          style={{ width: '50%', padding: '40px 0' }}
        />
      </div>
    }
    footer={
      <div className="d-flex justify-content-center">
        <Link to="/password-recovery">Mot de passe perdu?</Link>
      </div>
    }
  >
    <h5 className="mb-4">
      Se connecter
      <br />
      <small>Entrez votre email et votre mot de passe</small>
    </h5>
    <LoginForm />
  </PageCenteredForm>
)

export default Login

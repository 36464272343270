import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons'
import ResetPasswordForm from './form'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useUsers } from '_stores/users/store'

const PasswordRecoverySuccess: React.FunctionComponent<{ token?: string }> = ({ token }) => (
  <>
    <h4>
      <FontAwesomeIcon icon={faCheck} size="lg" className="mr-3" />
      Password reseted
    </h4>
    <Link to={`/is-auth?token=${token}`}>Go to your account</Link>
  </>
)

const PasswordRecovery: React.FunctionComponent = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const [state] = useUsers()

  return (
    <PageCenteredForm
      title={'Reset password'}
      titleDescription={'Enter your new password'}
      icon={<FontAwesomeIcon icon={faLock} size="2x" />}
      footer={<Link to="/login">Cancel</Link>}
    >
      {!query.token ? (
        <div>
          <FontAwesomeIcon icon={faExclamation} size="lg" className="mr-3" />
          Invalid link
        </div>
      ) : (
        <ResetPasswordForm
          token={query.token as string}
          success={() => <PasswordRecoverySuccess token={state.resetPasswordToken} />}
        />
      )}
    </PageCenteredForm>
  )
}

export default PasswordRecovery

import CreateUser from './containers/users/create'
import Users from './containers/users/list'
import User from './containers/users/item'
import Login from '_containers/login'
import Logout from '_containers/logout'
import PasswordRecovery from '_containers/password-recovery'
import PasswordReset from '_containers/password-reset'
import IsAuth from './containers/is-auth'
import UserProfile from '_containers/user-profile'
import PageItem from '_containers/pages/item'
import PagesList from '_containers/pages/list'
import MediasList from '_containers/medias/list'
import ApiKeysList from '_containers/api-keys/list'
import ArticlesList from '_containers/articles/list'
import ArticleItem from '_containers/articles/item'
import ProductsList from '_containers/products/list'
import ProductItem from '_containers/products/item'
import AttributesList from '_containers/attributes/list'
import AttributeItem from '_containers/attributes/item'
import CategoriesList from '_containers/categories/list'
import BrandsList from '_containers/brands/list'
import BrandItem from '_containers/brands/item'
import OrganizationsList from '_containers/organizations/list'
import OrganizationItem from '_containers/organizations/item'
import { matchPath } from 'react-router-dom'
import OrdersList from '_containers/orders/list'
import OrderItem from '_containers/orders/item'

interface Route {
  path: string | string[]
  component: any
  logged?: boolean | 'common'
  notExact?: boolean
}

const routes: Route[] = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/logout',
    component: Logout,
    logged: true,
  },
  {
    path: '/password-recovery',
    component: PasswordRecovery,
  },
  {
    path: '/reset-password',
    component: PasswordReset,
  },
  {
    path: '/users/create',
    component: CreateUser,
  },
  {
    path: '/users/delete/:id',
    component: Users,
    logged: true,
  },
  {
    path: '/users/invite',
    component: Users,
    logged: true,
  },
  {
    path: '/users/:id',
    component: User,
    logged: true,
  },
  {
    path: '/users',
    component: Users,
    logged: true,
  },
  {
    path: '/is-auth',
    component: IsAuth,
    logged: 'common',
  },
  {
    path: '/me',
    component: UserProfile,
    logged: true,
  },

  //__FEATURE_api-keys
  {
    path: '/api-keys',
    component: ApiKeysList,
    logged: true,
    notExact: true,
  } /*,
  {
    path: '/api-keys/:id',
    component: ApiKeysList,
    logged: true,
    notExact: true,
  },*/,
  //__FEATURE_api-keys
  //__FEATURE_articles
  {
    path: '/articles',
    component: ArticlesList,
    logged: true,
  },
  {
    path: '/articles/:id',
    component: ArticleItem,
    logged: true,
  },
  //__FEATURE_articles
  //__FEATURE_pages
  {
    path: '/pages',
    component: PagesList,
    logged: true,
  },
  {
    path: '/pages/:id/settings',
    component: PageItem,
    logged: true,
  },
  {
    path: '/pages/:id/fields',
    component: PageItem,
    logged: true,
  },
  {
    path: '/pages/:id',
    component: PageItem,
    logged: true,
  },
  //__FEATURE_pages
  //__FEATURE_medias
  {
    path: '/medias',
    component: MediasList,
    logged: true,
  },
  {
    path: '/medias/types/:type',
    component: MediasList,
    logged: true,
  },
  {
    path: ['/products/new', '/attempts/new'],
    component: ProductsList,
    logged: true,
  },
  {
    path: ['/products/:id', '/attempts/:id'],
    component: ProductItem,
    logged: true,
    notExact: true,
  },
  {
    path: ['/products', '/attempts'],
    component: ProductsList,
    logged: true,
    notExact: true,
  },
  {
    path: '/attributes/product-attribute',
    component: AttributesList,
    logged: true,
  },
  {
    path: '/attributes/product-attribute-section',
    component: AttributesList,
    logged: true,
  },
  {
    path: '/attributes/product-filename',
    component: AttributesList,
    logged: true,
  },
  {
    path: '/attributes/new',
    component: AttributesList,
    logged: true,
  },
  {
    path: '/attributes/:id',
    component: AttributeItem,
    logged: true,
  },
  {
    path: '/attributes',
    component: AttributesList,
    logged: true,
    notExact: true,
  },
  {
    path: '/categories',
    component: CategoriesList,
    logged: true,
    notExact: true,
  },
  {
    path: '/categories/:id',
    component: CategoriesList,
    logged: true,
  },
  {
    path: '/orders/:id',
    component: OrderItem,
    logged: true,
  },
  {
    path: '/orders',
    component: OrdersList,
    logged: true,
    notExact: true,
  },
  {
    path: '/brands',
    component: BrandsList,
    logged: true,
  },
  {
    path: '/brands/:id',
    component: BrandItem,
    logged: true,
  },
  {
    path: '/organizations',
    component: OrganizationsList,
    logged: true,
  },
  {
    path: '/organizations/new',
    component: OrganizationsList,
    logged: true,
  },
  {
    path: '/organizations/:id',
    component: OrganizationItem,
    logged: true,
  },
  //__FEATURE_medias
]

export const getRoute = (path: string): Route | undefined => {
  return routes.find(r => {
    const routePaths = Array.isArray(r.path) ? r.path : [r.path]
    return routePaths.some(p => matchPath(path, p))
  })
}

export default routes

import { faTimes, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button } from 'react-bootstrap'

interface RemoveConfirmationProps {
  onRemove(): void
}

const RemoveConfirmation: React.FunctionComponent<RemoveConfirmationProps> = ({ onRemove }) => {
  const [toConfirmed, setToConfirmed] = React.useState(false)

  if (toConfirmed)
    return (
      <>
        <Button variant="link" size="sm" onClick={() => setToConfirmed(false)} title="Annuler">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Button
          variant="danger"
          size="sm"
          className="ml-1"
          onClick={onRemove}
          title="Supprimer définitivement"
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </>
    )

  return (
    <a className="link" style={{ cursor: 'pointer' }} onClick={() => setToConfirmed(true)}>
      <FontAwesomeIcon icon={faTrash} />
    </a>
  )
}

export default RemoveConfirmation

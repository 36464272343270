import * as React from 'react'
import ListGroupForm from '_components/form/list-group'
import { useCurrentOrganization } from '_stores/organizations/store'
import useFormFields from './hooks/use-form-fields'

const OrganizationForm: React.FunctionComponent = () => {
  const [currentOrganization, actions] = useCurrentOrganization()

  const [fields] = useFormFields({
    organization: currentOrganization,
  })

  return (
    <>
      <ListGroupForm fields={fields} />
    </>
  )
}

export default OrganizationForm

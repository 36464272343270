import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { ListGroupFormField } from '_components/form/list-group'
import { Product, ProductKinds } from '_stores/products/types'
import classNames from 'classnames'
import { useProducts } from '_stores/products/store'

//TODO: move this component
const VisibleValue: React.FunctionComponent<{
  visible: boolean
  visibleLabel?: string | React.ReactNode
  notVisibleLabel?: string | React.ReactNode
}> = ({ visible, visibleLabel, notVisibleLabel }) => (
  <div className="text-gray-700 d-flex align-items-center">
    <FontAwesomeIcon
      icon={faCircle}
      className={classNames('mr-1', visible ? 'text-success ' : 'text-secondary ')}
      size="xs"
    />
    {visible ? visibleLabel || 'Oui' : notVisibleLabel || 'Non'}
  </div>
)

const useVisibilityFormFields = (locale: string, product?: Product): [ListGroupFormField[]] => {
  const [state, actions] = useProducts()
  const fields: ListGroupFormField[] = React.useMemo(
    () =>
      !product
        ? []
        : [
            product.kind === ProductKinds.article && {
              label: (
                <span>
                  Affiché comme <strong>nouveauté</strong>
                </span>
              ),
              value: <VisibleValue visible={product.visibility.homepage.newness} />,
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { visibility: product.visibility },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'visibility.homepage.newness',
                  },
                ],
                onFieldChange: (fielName: string, value: boolean) => {
                  actions.update(
                    {
                      _id: product._id,
                      visibility: {
                        ...product.visibility,
                        homepage: {
                          ...product.visibility.homepage,
                          newness: value,
                        },
                      },
                    },
                    {
                      requestKey: `put visibility.newness /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.newness /products/${product._id}`],
              },
            },
            product.kind === ProductKinds.article && {
              label: (
                <span>
                  Affiché comme produit <strong>en promotion</strong>
                </span>
              ),
              value: <VisibleValue visible={product.visibility.homepage.promotion} />,
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { visibility: product.visibility },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'visibility.homepage.promotion',
                  },
                ],
                onFieldChange: (fieldName: string, value: boolean) => {
                  actions.update(
                    {
                      _id: product._id,
                      visibility: {
                        ...product.visibility,
                        homepage: {
                          ...product.visibility.homepage,
                          promotion: value,
                        },
                      },
                    },
                    {
                      requestKey: `put visibility.promotion /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.promotion /products/${product._id}`],
              },
            },
            product.kind === ProductKinds.article && {
              label: (
                <span>
                  Visible sur le <strong>site public</strong>
                </span>
              ),
              value: <VisibleValue visible={product.visibility.public} />,
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { visibility: product.visibility },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'visibility.public',
                  },
                ],
                onFieldChange: (fieldName: string, value: boolean) => {
                  actions.update(
                    {
                      _id: product._id,
                      visibility: {
                        ...product.visibility,
                        public: value,
                      },
                    },
                    {
                      requestKey: `put visibility.public /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.public /products/${product._id}`],
              },
            },
            {
              label: (
                <span>
                  Visible pour les utilisateurs <strong>professionels connectés</strong>
                </span>
              ),
              value: <VisibleValue visible={product.visibility.professionals} />,
              forceEditMode: true,
              editableClassName: 'mt-0',
              formProps: {
                initialValues: { visibility: product.visibility },
                noButton: true,
                fields: [
                  {
                    type: 'switch',
                    name: 'visibility.professionals',
                    options: [
                      {
                        value: true,
                        label: 'Oui',
                      },
                    ],
                  },
                ],
                onFieldChange: (fieldName, value) => {
                  actions.update(
                    {
                      _id: product._id,
                      visibility: {
                        ...product.visibility,
                        professionals: value,
                      },
                    },
                    {
                      requestKey: `put visibility.professionals /products/${product._id}`,
                    },
                  )
                },
                //onSubmit: (values: any) => {},
                request: state.requests[`put visibility.professionals /products/${product._id}`],
              },
            },
            /*product.kind === ProductKinds.article && {
              label: (
                <span>
                  Limité la visibilité aux utilisateurs{' '}
                  <strong>professionels de certains pays</strong>
                </span>
              ),
              value: (
                <VisibleValue
                  visible={Boolean(product.visibility.countries[0])}
                  visibleLabel={product.visibility.countries.join(', ')}
                  notVisibleLabel="Accessible pour tous les pays"
                />
              ),
              formProps: {
                initialValues: { visibility: product.visibility },
                fields: [
                  {
                    type: 'checkbox',
                    name: 'visibility.countries',
                    select: true,
                    inline: true,
                    options: [
                      {
                        value: 'be',
                        label: 'Belgique',
                      },
                      {
                        value: 'fr',
                        label: 'France',
                      },
                      { label: 'Luxembourg', value: 'lu' },
                      {
                        value: 'nl',
                        label: 'Pays-Bas',
                      },
                    ],
                  },
                ],
                onSubmit: (values: any) => {
                  actions.update(
                    {
                      _id: product._id,
                      visibility: {
                        ...product.visibility,
                        countries: values.visibility.countries,
                      },
                    },
                    {
                      requestKey: `put visibility.countries /products/${product._id}`,
                    },
                  )
                },
                request: state.requests[`put visibility.countries /products/${product._id}`],
              },
            },*/
          ].filter(i => i),
    [product, state.requests],
  )
  return [fields]
}
export default useVisibilityFormFields

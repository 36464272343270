import { faEnvelope, faFax, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { OrganizationAddress as OrganizationAddressType } from '_stores/organizations/types'

interface OrganizationAddressProps {
  address: OrganizationAddressType
}

const OrganizationAddress: React.FunctionComponent<OrganizationAddressProps> = ({ address }) => {
  return (
    <div>
      <h5>
        {address.isMainAddress && 'Adresse principale'}
        {address.isBillingAddress && 'Adresse de facturation'}
        {address.isDeliveryAddress && 'Adresse de livraison'}
      </h5>
      <p></p>
      <p>
        {address.street} {address.streetNumber} {address.streetBoxNumber}
        <br />
        {address.zip} {address.city} - {address.country}
      </p>
    </div>
  )
}

export default OrganizationAddress

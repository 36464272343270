import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface ListGroupFormTitleWrapperProps {
  icon: IconDefinition
  children: React.ReactElement
  title: string | React.ReactElement
  description: string | React.ReactElement
}

const ListGroupFormTitleWrapper: React.FunctionComponent<ListGroupFormTitleWrapperProps> = ({
  title,
  description,
  children,
  icon,
}) => (
  <div className="mb-4">
    <h4>
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {title}
    </h4>
    <p>{description}</p>
    {children}
  </div>
)

export default ListGroupFormTitleWrapper

import * as React from 'react'
import classNames from 'classnames'

interface LanguagesRightMenuSwitchProps {
  languagues: string[]
  active?: string
  onClick?(locale: string): void
  title?: string
  className?: string
}

const LanguagesRightMenuSwitch: React.FunctionComponent<LanguagesRightMenuSwitchProps> = ({
  languagues,
  onClick,
  title,
  active,
  className,
}) => (
  <>
    <div className={classNames('nav-link pb-0 h6', className)}>{title || "Langue d'affichage"}</div>
    {languagues.map(locale => (
      <a
        key={locale}
        className={classNames('nav-link', { active: locale === active })}
        onClick={() => onClick && onClick(locale)}
        style={{ cursor: 'pointer' }}
      >
        {locale}
      </a>
    ))}
  </>
)
export default LanguagesRightMenuSwitch

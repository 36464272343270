import * as React from 'react'
import { Button } from 'react-bootstrap'
import InputField from '../input'
import Field, { BaseFormFieldProps } from '../index'

type IndexProps = BaseFormFieldProps

const Index: React.FunctionComponent<IndexProps> = props => {
  const [fields, setFields] = React.useState(props.defaultValue || [''])
  const setFieldValue = (value: any, index: number) => {
    const newState = [...fields]
    newState[index] = value
    setFields(newState)
    props.onChange && props.onChange(newState.filter(i => i))
  }
  return (
    <Field {...props} value={fields}>
      <>
        {fields.map((field: any, index: number) => (
          <InputField
            key={index}
            onChange={value => setFieldValue(value, index)}
            defaultValue={field}
            name={String(index)}
          />
        ))}
        <Button onClick={() => setFields([...fields, ''])} size="sm" variant="secondary">
          {props.addFieldButtonLabel || 'Add field'}
        </Button>
      </>
    </Field>
  )
}

export default Index

import * as React from 'react'
import { useOrders } from '_stores/orders/store'
import Table from '_components/table'
import PageApp from '_containers/app-pages/page-app'
import useDebounce from '../../hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import useListTable from './hooks/use-list-table'

const OrdersList: React.FunctionComponent = () => {
  const [state, actions] = useOrders()
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 100)

  React.useEffect(() => {
    actions.fetch()
  }, [])

  React.useEffect(() => {
    actions.setFilter('search', search)
  }, [debouncedSearch])

  const [columns, data] = useListTable()

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">Commandes</h1>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
          />
          <Table columns={columns} data={data} />
        </div>
      </Card>
    </PageApp>
  )
}

export default OrdersList

import * as React from 'react'
import { useLocation } from 'react-router'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useCurrentAttribute, useAttributesRequest } from '_stores/attributes/store'
import queryString from 'query-string'
import APP from '_constants/app'
import { AttributeFamilyEnum } from '_stores/attributes/types'
import { buildLocalizedObject } from '_core/i18n'

interface AttributeFormProps {
  defaultLabel?: string
  defaultDuplicate?: boolean
}

const AttributeForm: React.FunctionComponent<AttributeFormProps> = props => {
  const [currentAttribute, actions] = useCurrentAttribute()
  const [request] = useAttributesRequest({ method: 'post' })

  const [showI18nInputs, setShowI18nInputs] = React.useState(false)

  const location = useLocation()
  const defaultLabel: any = React.useMemo(() => {
    return props.defaultLabel || queryString.parse(location.search).label
  }, [location.search])

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        locales: APP.languagues,
        canDuplicateLocales: true,
        defaultDuplicateLocales: props.defaultDuplicate === false ? false : true,
        name: 'labels',
        type: 'text',
        placeholder: 'Nom...',
      },
    ],
    [],
  )

  React.useEffect(() => {
    return () => {
      actions.setCurrent()
    }
  }, [])

  return (
    <>
      <Form
        fields={fields}
        initialValues={
          defaultLabel
            ? {
                labels: buildLocalizedObject(defaultLabel || ''),
              }
            : currentAttribute || {}
        }
        validate={(values: any) => {
          const errors: any = {}
          if (!values?.labels.fr) errors['labels.fr'] = 'Champ requis'
          if (!values?.labels.nl) errors['labels.nl'] = 'Champ requis'
          if (!values?.labels.en) errors['labels.en'] = 'Champ requis'
          return errors
        }}
        onSubmit={values => {
          values.type = 'text'
          values.family = AttributeFamilyEnum.productAttribute
          delete values.copyName
          actions.create(values)
        }}
        request={request}
        submitLabel="Save"
      />
    </>
  )
}

export default AttributeForm

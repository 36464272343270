import { faCommentDollar, faDownload, faHome, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import RequestMessage from '_components/request-message'
import { useProducts } from '_stores/products/store'
import { Product, ProductFile } from '_stores/products/types'
import classNames from 'classnames'
import { Column } from 'react-table'
import RemoveConfirmation from '_components/remove-confirmation'
import { Button } from 'react-bootstrap'

interface UeFileFormTableProps {
  locale: string
  product?: Product
  setCurrentFile?(product: any): any
}

const useFileFormTable = ({
  locale,
  product,
  setCurrentFile,
}: UeFileFormTableProps): [Column[], ProductFile[]] => {
  const [state, actions] = useProducts()

  //Update visibility function
  const updateVisibility = React.useCallback(
    (visibility: string, productFile: ProductFile) => {
      product &&
        actions.updateFile({
          productId: product._id,
          fileId: productFile._id,
          visibility: {
            ...productFile.visibility,
            [visibility]: !productFile.visibility[visibility],
          },
        })
    },
    [product],
  )

  const columns = React.useMemo(
    () =>
      !product
        ? []
        : [
            {
              Header: 'Nom',
              accessor: `name.${locale}`,
              width: '30%',
              Cell: (data: any) => {
                const productFile: ProductFile = data.cell.row.original
                return (
                  <a
                    className="link"
                    onClick={() => {
                      setCurrentFile && setCurrentFile(productFile)
                    }}
                  >
                    {productFile.name[locale] || 'N/A'}
                  </a>
                )
              },
            },
            {
              Header: 'Fichier',
              accessor: 'path',
              width: '30%',
              Cell: (data: any) => {
                const productFile: ProductFile = data.cell.row.original
                return <span>{productFile.path && productFile.path.substring(0, 26)}</span>
              },
            },
            {
              Header: 'Visibilité',
              accessor: 'visibility.public',
              width: '20%',
              Cell: (data: any) => {
                const productFile: ProductFile = data.cell.row.original
                return (
                  <div className="u-color-light">
                    <RequestMessage
                      request={
                        product &&
                        state.requests[`put /products/${product._id}/files/${productFile._id}`]
                      }
                      loader
                      check
                    />
                    <FontAwesomeIcon
                      icon={faHome}
                      className={classNames(
                        'mr-2',
                        productFile.visibility.public ? 'u-color-light' : 'text-secondary',
                      )}
                      title="Visible sur le site public"
                      onClick={() => updateVisibility('public', productFile)}
                      style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon
                      icon={faUsers}
                      className={classNames(
                        'mr-2',
                        productFile.visibility.professionals ? 'u-color-light' : 'text-secondary',
                      )}
                      title="Visible pour les utilisateurs professionels connectés"
                      onClick={() => updateVisibility('professionals', productFile)}
                      style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon
                      icon={faCommentDollar}
                      className={classNames(
                        productFile.visibility.commercials ? 'u-color-light' : 'text-secondary',
                      )}
                      title="Visible pour les utilisateurs commerciaux"
                      onClick={() => updateVisibility('commercials', productFile)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )
              },
            },
            {
              Header: ' ',
              width: '20%',
              accessor: 'actions',
              Cell: (data: any) => {
                const productFile: ProductFile = data.cell.row.original
                return (
                  <div className="text-right">
                    <Button
                      size="sm"
                      variant="link"
                      className="mr-1"
                      target="__blank"
                      href={`${process.env.API_HOST}/products/${product._id}/files/${productFile._id}`}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <RemoveConfirmation
                      onRemove={() =>
                        product &&
                        actions.removeFile({
                          productId: product._id as string,
                          fileId: productFile._id,
                        })
                      }
                    />
                  </div>
                )
              },
            },
          ],
    [locale, product],
  )

  const productFiles = React.useMemo(() => (product ? product.files : []), [product])

  return [columns, productFiles]
}

export default useFileFormTable

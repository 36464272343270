import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { orderBy, sortBy } from 'lodash'
import * as React from 'react'
import { Badge } from 'react-bootstrap'
import { Category } from '_stores/categories/types'
import { Product } from '_stores/products/types'
import classNames from 'classnames'

interface ProductCategoriesProps {
  product: Product
  locale?: string
}

const getCategoryTexts = (category: Category, texts: string[], lang = 'fr'): string[] => {
  if (!texts) texts = []
  texts.unshift(category.labels[lang])
  if (category.parent) {
    texts = getCategoryTexts(category.parent as Category, texts, lang)
  }
  return texts as string[]
}

const ProductCategories: React.FunctionComponent<ProductCategoriesProps> = ({
  product,
  locale,
}) => {
  const categoriesTexts = React.useMemo(() => {
    const texts: string[][] = []
    sortBy(product.categories, `labels.${locale}`).forEach((category: any) => {
      const categoryTexts = getCategoryTexts(category, [], locale || 'fr')
      const existingParentIndex = texts.findIndex(text => {
        if (categoryTexts.length !== text.length) return false
        let haveSameParents = true
        for (let i = 0; i < text.length - 1; i++) {
          if (text[i] !== categoryTexts[i]) haveSameParents = false
        }
        return haveSameParents
      })
      if (existingParentIndex !== -1) {
        texts[existingParentIndex][texts[existingParentIndex].length - 1] = texts[
          existingParentIndex
        ][texts[existingParentIndex].length - 1] += ` __|__ ${
          categoryTexts[categoryTexts.length - 1]
        }`
      } else texts.push(getCategoryTexts(category, [], locale || 'fr'))
    })
    return texts
  }, [product, locale])
  return (
    <div>
      {orderBy(categoriesTexts, [category => category[0].toLowerCase()]).map((category, index) => (
        <div key={index} className="mb-1">
          {category.map((text: string, index) => (
            <span key={index}>
              {text.split('__|__').map((text, i) => (
                <span key={i}>
                  <Badge
                    variant={index === category.length - 1 ? 'primary' : 'light'}
                    className={classNames({ 'mr-1': index === category.length - 1 })}
                  >
                    {text}
                  </Badge>
                  {index !== category.length - 1 && (
                    <FontAwesomeIcon icon={faCaretRight} size="xs" className="ml-1 mr-1" />
                  )}
                </span>
              ))}
            </span>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ProductCategories

import * as React from 'react'
import { Card } from 'react-bootstrap'
import Table from '_components/table'
import { useCurrentProduct } from '_stores/products/store'
import useVotesTable from '../hooks/use-votes-table'

const ProductVotesList: React.FunctionComponent = () => {
  const [currentProduct] = useCurrentProduct()
  const [columns, data] = useVotesTable({
    product: currentProduct,
  })
  return (
    <>
      <Card>
        <div className="p-4">
          <Table columns={columns} data={data} />
        </div>
      </Card>
    </>
  )
}

export default ProductVotesList

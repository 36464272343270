import * as React from 'react'
import { useCurrentArticle } from '_stores/articles/store'
import ArticleForm from './form'
import { Link } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { useMedias } from '_stores/medias/store'
import { Breadcrumb, Card } from 'react-bootstrap'

const ArticleItem: React.FunctionComponent = () => {
  const [currentArticle, actions] = useCurrentArticle()
  const [mediasState, mediasActions] = useMedias()

  React.useEffect(() => {
    mediasActions.fetch()
  }, [])

  return (
    <PageApp>
      <ApiItemWrapper item={currentArticle} actions={actions}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/articles">Articles</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {currentArticle ? currentArticle.title : 'New article'}
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr className="mb-4"></hr>
        <Card className="p-4">
          <ArticleForm />
        </Card>
      </ApiItemWrapper>
    </PageApp>
  )
}

export default ArticleItem

import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { genericActions, ResourceState, ApiRequest, ApiCallOptions } from '_core/store'
import { Brand, BrandAsSelectOption } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'
import { orderBy } from 'lodash'

export type BrandsState = ResourceState<Brand>
export type BrandsApi = StoreActionApi<BrandsState>

const initialState: BrandsState = {
  endpoint: '/brands',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {},
}

const actions = {
  fetch: () => async ({ dispatch }: BrandsApi) => {
    await dispatch(
      genericActions.apiFetch({
        populate: ['logo'],
        sort: 'name',
      }),
    )
    //await dispatch(genericActions.apiFetch())
  },
  get: (_id: string) => async ({ dispatch }: BrandsApi) => {
    await dispatch(
      genericActions.apiGet(_id, {
        data: {
          populate: ['logo'],
        },
      }),
    )
  },
  create: (data: Brand) => async ({ getState, dispatch }: BrandsApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: Partial<Brand>, options?: ApiCallOptions) => async ({
    dispatch,
    getState,
  }: BrandsApi) => {
    if (!options) options = {}
    options.requestLivetime = 3000
    const res = await dispatch(
      genericActions.api(
        {
          method: 'put',
          url: `${getState().endpoint}/${data._id}`,
          data,
        },
        options,
      ),
    )
    if (res && res.result) await dispatch(actions.get(res.result._id))
  },
  createOrUpdate: (data: Partial<Brand>) => async ({ dispatch }: BrandsApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: BrandsApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  getSchema: () => async ({ dispatch }: BrandsApi) => {
    await dispatch(genericActions.getSchema())
  },
  setFilter: (key: string, value: any) => async ({ dispatch }: BrandsApi) => {
    dispatch(genericActions.setFilter(key, value))
    await dispatch(actions.fetch())
  },
  setFilters: (values: any) => async ({ dispatch }: BrandsApi) => {
    dispatch(genericActions.setFilters(values))
    await dispatch(actions.fetch())
  },
  setCurrent: (brand?: Brand) => ({ dispatch }: BrandsApi) => {
    dispatch(genericActions.setCurrent(brand))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: BrandsApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: BrandsApi) => {
    dispatch(genericActions.clearRequests())
  },
}

export type BrandsActions = typeof actions

const Store = createStore<BrandsState, BrandsActions>({
  name: 'brands',
  initialState,
  actions,
})

export const BrandsSubscriber = createSubscriber(Store)
export const useBrands = createHook(Store)
export const useCurrentBrand = createHook(Store, {
  selector: (state: BrandsState): Brand | undefined => {
    if (state.currentId) return state.all.find((brand: Brand) => brand._id === state.currentId)
    return
  },
})
export const useBrandsRequest = createHook(Store, {
  selector: (
    state: BrandsState,
    props: { method?: string; id?: string; name?: string },
  ): ApiRequest | undefined => {
    const { requests, endpoint } = state
    if (props.name) return requests[props.name]
    if (props.method && props.id) return requests[`${props.method} ${endpoint}/${props.id}`]
    else if (props.method) return requests[`${props.method} ${endpoint}`]
    return
  },
})
export const useBrandsForSelectField = createHook(Store, {
  selector: (state: BrandsState): BrandAsSelectOption[] => {
    const sanitizer = (brand: Brand): BrandAsSelectOption => ({
      ...brand,
      label: brand.name,
      value: brand._id,
    })
    return orderBy(
      state.all.map(brand => sanitizer(brand)),
      [brand => brand.name.toLowerCase()],
    )
  },
})
export const BrandsContainer = createContainer(Store)

export default Store

import * as React from 'react'
import { FormFieldProp } from '_components/form/types'

interface UseInviteFormFieldsProps {
  excludedFields?: string[]
}

const useInviteFormFields = ({ excludedFields }: UseInviteFormFieldsProps): [FormFieldProp[]] => {
  const fields: FormFieldProp[] = React.useMemo(() => {
    const fields: FormFieldProp[] = [
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email...',
      },
      {
        name: 'role',
        type: 'select',
        placeholder: 'Role...',
        options: [
          {
            value: 'user',
            label: 'User',
          },
          {
            value: 'admin',
            label: 'Admin',
          },
        ],
      },
      {
        name: 'language',
        type: 'select',
        label: 'Langue',
        options: [
          {
            value: 'fr',
            label: 'Français',
          },
          {
            value: 'nl',
            label: 'Néerlandais',
          },
          {
            value: 'en',
            label: 'Anglais',
          },
        ],
      },
      {
        name: 'organizationRole',
        type: 'select',
        label: 'Rôle',
        options: [
          {
            value: 'rganization/user',
            label: 'Acheteur',
          },
          {
            value: 'organization/commercials',
            label: 'Commercial',
          },
        ],
      },
    ]

    return fields.filter(field => !excludedFields?.includes(field.name))
  }, [excludedFields])

  return [fields]
}

export default useInviteFormFields

import * as React from 'react'
import Form from '_components/form'
import { FormFieldProp } from '_components/form/types'
import { useCurrentPage } from '_stores/pages/store'

const PageForm: React.FunctionComponent = () => {
  const [currentPage, actions] = useCurrentPage()

  const fields: FormFieldProp[] = React.useMemo(
    () => [
      {
        name: 'name',
        type: 'text',
        placeholder: 'Name...',
        label: 'Name',
      },
      {
        name: 'settings.previewUrl',
        type: 'text',
        label: 'Preview URL',
      },
      {
        name: 'settings.locales',
        type: 'texts',
        label: 'Locales',
        addFieldButtonLabel: 'Add locale',
      },
      {
        name: 'settings.contextResources',
        type: 'checkbox',
        select: true,
        label: 'Context data resources',
        options: [
          {
            value: 'resources',
            label: 'Resources',
          },
          {
            value: 'medias',
            label: 'Medias',
          },
        ],
      },
    ],
    [],
  )

  return (
    <>
      <Form
        fields={fields}
        initialValues={currentPage}
        validate={(values: any) => {
          const errors: any = {}
          if (!values.name) errors.name = 'Required field'
          return errors
        }}
        onSubmit={actions.createOrUpdate}
        request={actions.getRequest()}
        submitLabel="Save"
      />
    </>
  )
}
export default PageForm

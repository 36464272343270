import * as React from 'react'
import { useTable, useSortBy, Column, usePagination, SortingRule } from 'react-table'
import { Button, ButtonGroup, Form, Table as BsTable } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

export interface TableProps {
  columns: Column[]
  data: any[]
  onRowClick?(row: any): void
  onPageChange?(pageNumber: number, pageSize: number): void
  itemsCount?: number
  withPagination?: boolean
  defaultPage?: number
  defaultPageSize?: number
  onSort?(sortBy: { id: string; desc?: boolean }, pageIndex: number, pageSize: number): void
  defaultSortBy?: SortingRule<any>[]
}

const TablePagination: React.FunctionComponent<any> = ({
  pageIndex,
  pageOptions,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  pageCount,
  itemsCount,
}) => (
  <div className="pagination mb-3 flex">
    <div className="d-flex mr-2">
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageCount}
        </strong>{' '}
        <br />
        <small>{itemsCount} items in total</small>
      </span>
    </div>
    <div className="ml-auto mr-auto">
      <ButtonGroup>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button variant="secondary" size="sm" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>
      </ButtonGroup>
    </div>
    <div className="d-flex">
      <Form.Control
        value={pageSize}
        size="sm"
        onChange={e => {
          setPageSize(Number(e.target.value))
        }}
        as="select"
        defaultValue={pageSize}
      >
        {[10, 25, 50, 100, 250, 500]
          .sort((a, b) => a - b)
          .filter(count => count <= itemsCount)
          .map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
      </Form.Control>
    </div>
  </div>
)

const Table: React.FunctionComponent<TableProps> = ({
  columns,
  data,
  onRowClick,
  onPageChange,
  withPagination,
  itemsCount,
  defaultPage,
  defaultPageSize,
  onSort,
  defaultSortBy,
}) => {
  const prevCountRef = React.useRef()
  React.useEffect(() => {
    prevCountRef.current = itemsCount
  })
  const prevCount = prevCountRef.current
  const stateReducer = (newState, action, prevState) => {
    return {
      ...newState,
      pageIndex:
        withPagination && itemsCount && prevCount && prevCount !== itemsCount
          ? 0
          : newState.pageIndex,
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: defaultPage || 0,
        pageSize: defaultPageSize || 25,
        //sortBy: defaultSortBy || [],
      },
      manualPagination: Boolean(itemsCount),
      pageCount: -1,
      autoResetPage: Boolean(!withPagination && !itemsCount),
      stateReducer: stateReducer,
      autoResetSortBy: false,
      manualSortBy: Boolean(onSort),
      defaultCanSort: false,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
  )

  const displayedRows = withPagination ? page : rows

  const TablePaginationComponent = withPagination && (
    <TablePagination
      pageIndex={pageIndex}
      pageOptions={pageOptions}
      gotoPage={(pageNumber: number) => {
        onPageChange && onPageChange(pageNumber, pageSize)
        gotoPage(pageNumber)
      }}
      canPreviousPage={canPreviousPage}
      previousPage={() => {
        onPageChange && onPageChange(pageIndex - 1, pageSize)
        previousPage()
      }}
      nextPage={() => {
        onPageChange && onPageChange(pageIndex + 1, pageSize)
        nextPage()
      }}
      canNextPage={canNextPage}
      pageSize={pageSize}
      setPageSize={(pageSize: number) => {
        onPageChange && onPageChange(pageIndex, pageSize)
        setPageSize(pageSize)
      }}
      pageCount={parseInt((itemsCount || rows.length) / pageSize) + 1}
      itemsCount={itemsCount || data.length}
    />
  )

  React.useEffect(() => {
    onSort && onSort(sortBy, pageIndex, pageSize)
  }, [sortBy])

  return (
    <>
      {TablePaginationComponent}
      <BsTable {...getTableProps()} /*hover*/>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} width={column.width}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                      ) : (
                        <FontAwesomeIcon icon={faCaretUp} className="ml-1" />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {displayedRows.map(row => {
            prepareRow(row)
            const props = row.getRowProps()
            return (
              <tr {...props} onClick={() => onRowClick && onRowClick(row)}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </BsTable>
      {TablePaginationComponent}
    </>
  )
}

export default Table

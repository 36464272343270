import * as React from 'react'
import Field, { BaseFormFieldProps } from '..'
import EditorJs from 'react-editor-js'
import { OutputData, API } from '@editorjs/editorjs'
//@ts-ignore
import Header from '@editorjs/header'
//@ts-ignore
import Image from '@editorjs/image'
//@ts-ignore
//import Paragraph from 'editorjs-paragraph-with-alignment'
import { useMedias } from '_stores/medias/store'
import MediasTool from './tools/medias'
import MediasModal from '_components/medias/modal'

type EditorProps = BaseFormFieldProps

const Editor: React.FunctionComponent<EditorProps> = props => {
  const [state, actions] = useMedias()
  const [modalIsVisible, setModalVisible] = React.useState(false)

  const editorRef = React.useRef(null)
  const [currentData, setCurrentData] = React.useState<OutputData>()

  return (
    <div>
      <MediasModal
        medias={state.all}
        modalIsVisible={modalIsVisible}
        setModalVisible={setModalVisible}
        onSubmit={medias => {
          const media = state.all.find(m => m._id === medias[0])
          const data: OutputData = { ...currentData } as OutputData
          if (data && data.blocks) {
            data.blocks[data.blocks.length - 1] = {
              type: 'image',
              data: {
                file: {
                  url: media && media.url,
                },
                level: 3,
              },
            }
          }
          //@ts-ignore
          editorRef.current.instance.render(data)
        }}
      />
      <Field {...props}>
        <EditorJs
          ref={editorRef}
          data={props.defaultValue && { blocks: props.defaultValue }}
          onChange={(api: API, newData?: OutputData) => {
            if (newData) {
              setCurrentData(newData)
              props.form && props.form.setFieldValue(props.field.name, newData.blocks)
            }
          }}
          tools={{
            /*paragraph: {
              class: Paragraph,
              inlineToolbar: true,
            },*/
            header: Header,
            myTool: {
              class: MediasTool,
              config: {
                chooseMedias: () => {
                  setModalVisible(true)
                },
              },
            },
            image: {
              class: Image,
              config: {
                uploader: {
                  async uploadByFile(file: any) {
                    const res = await actions.create([file])
                    return {
                      success: 1,
                      file: {
                        url: res[0].url,
                        // any other image data you want to store, such as width, height, color, extension, etc
                      },
                    }
                  },
                },
              },
            },
          }}
        />
      </Field>
    </div>
  )
}
export default Editor

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useCurrentOrganization, useOrganizationsRequest } from '_stores/organizations/store'
import useMafactDataFields, { MafactDataField } from '../hooks/use-mafact-data-fields'

interface MafactDataProps {
  locale: string
}

interface MafactDateFieldProps {
  item: MafactDataField
  className?: string
}

const Field: React.FunctionComponent<MafactDateFieldProps> = ({ item, className }) => (
  <Col md={6} className={className} key={item.label}>
    {/*TODO....... :P */}
    <div className="flex-fill">
      <div className="u-color-light">{item.label}</div>
      <div>
        {!item.value && (
          <FontAwesomeIcon size="xs" icon={faExclamationTriangle} className="mr-1 text-warning" />
        )}
        {item.value || 'N/A'}
      </div>
    </div>
  </Col>
)

const MafactData: React.FunctionComponent<MafactDataProps> = ({ locale }) => {
  const [currentOrganization, actions] = useCurrentOrganization()
  const [syncPricesRequest] = useOrganizationsRequest({
    name: `post /organizations/${currentOrganization?._id}/sync-prices`,
  })
  const [generalsFields] = useMafactDataFields(
    currentOrganization,
    actions.syncPrices,
    syncPricesRequest,
  )

  return (
    <Row>
      {generalsFields.map((item, index) => (
        <Field item={item} className={index > 1 ? 'mt-3' : ''} key={item.label} />
      ))}
    </Row>
  )
}
export default MafactData

import * as React from 'react'
import { FormFieldProp } from '_components/form/types'
import { useBrands, useBrandsForSelectField } from '_stores/brands/store'

const useListFiltersFields = (filters: any, isAttemptsList: boolean): FormFieldProp[] => {
  const [brandsState, brandsActions] = useBrands()

  React.useEffect(() => {
    if (!brandsState.all[0]) brandsActions.fetch()
  }, [])

  const [brands] = useBrandsForSelectField()

  return React.useMemo(
    () => [
      {
        name: 'filters',
        type: 'section',
        section: {
          fields: [
            isAttemptsList
              ? {
                  field: {
                    name: 'active',
                    type: 'react-select',
                    multiple: true,
                    label: 'Actives',
                    options: [
                      {
                        value: true,
                        label: 'Oui',
                      },
                      {
                        value: false,
                        label: 'Non',
                      },
                    ],
                  },
                  colProps: {
                    md: '6',
                  },
                }
              : {
                  field: {
                    name: 'visibility',
                    type: 'react-select',
                    multiple: true,
                    label: 'Visibilité',
                    options: [
                      {
                        value: 'homepage.newness',
                        label: 'Nouveauté',
                      },
                      {
                        value: 'homepage.promotion',
                        label: 'En promotion',
                      },
                      {
                        value: 'public',
                        label: 'Site public',
                      },
                      {
                        value: 'professionals',
                        label: 'professionels connectés',
                      },
                    ],
                  },
                  colProps: {
                    md: '6',
                  },
                },
            {
              field: {
                name: 'brand',
                type: 'react-select',
                multiple: true,
                label: 'Marques',
                options: brands,
              },
              colProps: {
                md: '6',
              },
            },
          ],
        },
      },
    ],
    [filters, brands],
  )
}

export default useListFiltersFields

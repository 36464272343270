import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Medias from '_components/medias'
import { useMedias } from '_stores/medias/store'
import { Media } from '_stores/medias/types'

interface MediasModalProps {
  medias: Media[]
  defaultValue?: string[]
  multiple?: boolean
  modalIsVisible: boolean
  setModalVisible(visible: boolean): void
  onMediaSelected?(medias: string[]): void
  onSubmit?(medias: string[]): void
  modalReadonly?: boolean
}

const MediasModal: React.FunctionComponent<MediasModalProps> = ({
  medias,
  defaultValue,
  multiple,
  modalIsVisible,
  setModalVisible,
  onMediaSelected,
  onSubmit,
  modalReadonly,
}) => {
  const [state, actions] = useMedias()

  //Selected medias
  const [selectedMedias, setSelectedMedias] = React.useState(defaultValue || ([] as string[]))

  const selectMedia = (mediaId: string) => {
    const indexOfMedia = selectedMedias.findIndex((m: string) => m === mediaId)
    if (indexOfMedia === -1) {
      if (multiple) return setSelectedMedias([...selectedMedias, mediaId])
      return setSelectedMedias([mediaId])
    }
    if (multiple) {
      selectedMedias.splice(indexOfMedia, 1)
      setSelectedMedias([...selectedMedias])
    } else {
      setSelectedMedias([])
    }
  }

  React.useEffect(() => {
    onMediaSelected && onMediaSelected(selectedMedias)
  }, [selectedMedias])

  const Buttons = () => (
    <>
      <Button variant="default" onClick={() => setModalVisible(false)} className="mr-2">
        Cancel
      </Button>
      <Button
        onClick={() => {
          onSubmit && onSubmit(selectedMedias)
          setModalVisible(false)
        }}
      >
        Select
      </Button>
    </>
  )

  return (
    <Modal show={modalIsVisible} onHide={() => setModalVisible(false)} size="xl">
      <Modal.Header>
        Medias
        <div className="pull-right">
          <Buttons />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Medias
          medias={medias}
          onDrop={(files: any) => actions.create(files)}
          onDropzoneHide={() => actions.clearRequest({ key: 'post /medias' })}
          request={state.requests['post /medias']}
          selectable
          onSelect={(media: Media) => {
            media._id && selectMedia(media._id)
          }}
          selected={selectedMedias}
          readOnly={modalReadonly}
        />
      </Modal.Body>
      <Modal.Footer>
        <Buttons />
      </Modal.Footer>
    </Modal>
  )
}

export default MediasModal

import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { ApiRequest, genericActions, OrderState } from '_core/store'
import { Order } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'

export type OrdersState = OrderState<Order>
export type OrdersApi = StoreActionApi<OrdersState>

const initialState: OrdersState = {
  endpoint: '/orders',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {},
}

const actions = {
  fetch: () => async ({ dispatch }: OrdersApi) => {
    await dispatch(
      genericActions.apiFetch({
        populate: ['organization', 'products.item', 'creator'],
        sort: '-dates.created',
      }),
    )
  },
  get: (_id: string) => async ({ dispatch }: OrdersApi) => {
    await dispatch(
      genericActions.apiGet(_id, {
        data: {
          populate: ['organization', 'products.item', 'creator'],
        },
      }),
    )
  },
  create: (data: Order) => async ({ getState, dispatch }: OrdersApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: Partial<Order>) => async ({ dispatch }: OrdersApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: Partial<Order>) => async ({ dispatch }: OrdersApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: OrdersApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  mafactSync: (_id: string) => async ({ dispatch, getState }: OrdersApi) => {
    const res = await dispatch(
      genericActions.api({
        method: 'post',
        url: `${getState().endpoint}/${_id}/mafact-sync`,
      }),
    )
    if (res && res.result) await dispatch(actions.get(_id))
  },
  getSchema: () => async ({ dispatch }: OrdersApi) => {
    await dispatch(genericActions.getSchema())
  },
  setFilter: (key: string, value: any) => async ({ dispatch }: OrdersApi) => {
    dispatch(genericActions.setFilter(key, value))
    await dispatch(actions.fetch())
  },
  setFilters: (values: any) => async ({ dispatch }: OrdersApi) => {
    dispatch(genericActions.setFilters(values))
    await dispatch(actions.fetch())
  },
  setCurrent: (order?: Order) => ({ dispatch }: OrdersApi) => {
    dispatch(genericActions.setCurrent(order))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: OrdersApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: OrdersApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) => ({ dispatch }: OrdersApi) =>
    dispatch(genericActions.getRequest(method, withId)),
}

export type OrdersActions = typeof actions

const Store = createStore<OrdersState, OrdersActions>({
  name: 'orders',
  initialState,
  actions,
})

export const OrdersSubscriber = createSubscriber(Store)
export const useOrders = createHook(Store)
export const useCurrentOrder = createHook(Store, {
  selector: (state: OrdersState) => {
    if (state.currentId) return state.all.find(order => order._id === state.currentId)
    return
  },
})
export const useOrdersRequest = createHook(Store, {
  selector: (
    state: OrdersState,
    props: { method?: string; id?: string; name?: string },
  ): ApiRequest | undefined => {
    const { requests, endpoint } = state
    if (props.name) return requests[props.name]
    if (props.method && props.id) return requests[`${props.method} ${endpoint}/${props.id}`]
    else if (props.method) return requests[`${props.method} ${endpoint}`]
    return
  },
})

export const OrdersContainer = createContainer(Store)

export default Store

import { faBell, faPowerOff, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import InputField from '_components/form/fields/input'

export interface HeaderProps {
  title?: React.ReactElement | string
  rightLinks?: React.ReactElement[]
  links?: React.ReactElement[]
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    className="menu-link user-dropdown"
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </a>
))

const Header: React.FunctionComponent<HeaderProps> = ({ title, links, rightLinks }) => {
  return (
    <header className="app-header d-flex">
      <div className="logo">
        <img
          src="https://exalto.be/static/506b281920a22cc7b6f196900e61bad0/fba31/exalto-logo.webp"
          style={{ height: 30 }}
        />
      </div>
      <div className="tools">
        <div className="tools-search">
          {/*<InputField name="search" placeholder="Search..." />*/}
        </div>
        <div className="tools-item">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>PB</Dropdown.Toggle>

            <Dropdown.Menu>
              <LinkContainer to="/me">
                <Dropdown.Item>
                  Profile
                  <span className="dropdown-icon">
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-secondary" />
                  </span>
                </Dropdown.Item>
              </LinkContainer>
              <LinkContainer to="/logout">
                <Dropdown.Item href="#/action-3">
                  Logout
                  <span className="dropdown-icon">
                    <FontAwesomeIcon icon={faPowerOff} className="mr-2 text-secondary" />
                  </span>
                </Dropdown.Item>
              </LinkContainer>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/*
      <div className="mr-auto d-flex align-items-center">
        {title && <h4 className="app-header-title">{title}</h4>}
        {links && links.map(link => link)}
      </div>
      <div className="d-flex align-items-center mr-3">
        {rightLinks && rightLinks.map(link => link)}
      </div>
      <div className="d-flex align-items-center app-header-user">
        <div>
          <FontAwesomeIcon icon={faBell} />
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="link" className="user-dropdown ml-3">
            <div className="d-flex">
              <FontAwesomeIcon icon={faUserCircle} size="2x" />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <LinkContainer to="/me">
              <Dropdown.Item>
                <FontAwesomeIcon icon={faUser} className="mr-2 text-secondary" />
                Profile
              </Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/logout">
              <Dropdown.Item href="#/action-3">
                <FontAwesomeIcon icon={faPowerOff} className="mr-2 text-secondary" />
                Logout
              </Dropdown.Item>
            </LinkContainer>
          </Dropdown.Menu>
        </Dropdown>
      </div>*/}
    </header>
  )
}

export default Header

import * as React from 'react'
import { useCategories, useCategoriesRequest } from '_stores/categories/store'
import Table from '_components/table'
import { Link, Route, Switch } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import moment from 'moment'
import useDebounce from '../../hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faPlusCircle,
  faSearch,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Modal } from 'react-bootstrap'
import InputField from '_components/form/fields/input'
import CategoryItem from './item'
import { Category } from '_stores/categories/types'
import { Product } from '_stores/products/types'
import { sortBy } from 'lodash'

interface CategoryWithLevel extends Category {
  level: number
}

const CategoriesList: React.FunctionComponent = () => {
  const [state, actions] = useCategories()
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 100)
  const [categoryToRemove, setCategoryToRemove] = React.useState<Category>()

  const request = React.useMemo(() => {
    return (
      categoryToRemove &&
      categoryToRemove._id &&
      state.requests[`delete /categories/${categoryToRemove._id}`]
    )
  }, [state.requests, categoryToRemove])

  React.useEffect(() => {
    if (request && request?.status === 'success') {
      setCategoryToRemove(undefined)
    }
  }, [request])

  React.useEffect(() => {
    actions.setFilter('search', search)
  }, [debouncedSearch])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'labels.fr',
        Cell: (data: any) => {
          const category: CategoryWithLevel = data.cell.row.original
          return (
            <div style={{ marginLeft: category.level * 20 }}>
              <Link to={`/categories/${category._id}`}>{category.labels.fr}</Link>
            </div>
          )
        },
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => {
          const category: Category = data.cell.row.original
          return <span>{category.dates && moment(category.dates.created).format()}</span>
        },
      },
      {
        Header: 'Action',
        width: '15%',
        accessor: 'actions',
        Cell: (data: any) => {
          const category: Category = data.cell.row.original
          return (
            <a href="#" onClick={() => setCategoryToRemove(category)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          )
        },
      },
    ],
    [],
  )

  const data = React.useMemo(() => {
    const rows: CategoryWithLevel[] = []

    const addCategoriesAndChildrens = (categories: Category[], level: number) => {
      sortBy(categories, 'labels.fr').forEach(category => {
        rows.push({ ...category, level })
        if (category.childrens) {
          addCategoriesAndChildrens(category.childrens, level + 1)
        }
      })
    }

    addCategoriesAndChildrens(state.all, 0)

    return rows
  }, [state.all])

  return (
    <PageApp>
      <div className="d-flex align-items-center mb-3">
        <h1 className="mb-0">Catégories</h1>
        <div className="ml-auto">
          <Button as={Link} to={'/categories/new'}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Nouvelle catégorie
          </Button>
        </div>
      </div>
      <hr className="mb-4"></hr>
      <Card>
        <div className="p-4">
          <InputField
            name="search"
            onChange={value => setSearch(value)}
            prepend={<FontAwesomeIcon icon={faSearch} />}
          />
          <Table columns={columns} data={data} />
        </div>
      </Card>
      <Switch>
        <Route path="/categories/:id" exact component={CategoryItem} />
      </Switch>
      <Modal show={Boolean(categoryToRemove)} onHide={() => setCategoryToRemove(undefined)}>
        <Modal.Header>Suppression d'une catégorie</Modal.Header>
        <Modal.Body>
          Êtes-vous certain de vouloir supprimer <strong>{categoryToRemove?.labels.fr}</strong> ?
          {request && request.status === 'error' && (
            <>
              <p className="mt-3 text-danger">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                {request.message?.match('products') &&
                  `Impossible de supprimer la catégorie car il est utilisé par ${request.error?.data.products.length} produit(s).`}
                {request.message?.match('childrens') &&
                  'Impossible de supprimer une catégorie parente'}
              </p>
              {request.error?.data &&
                request.error?.data.products &&
                request.error?.data.products.map((product: Product) => (
                  <p key={product._id}>
                    <Link to={`/products/${product._id}`}> {product.name.fr}</Link>
                  </p>
                ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="default" onClick={() => setCategoryToRemove(undefined)}>
              Annuler
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                categoryToRemove && categoryToRemove._id && actions.delete(categoryToRemove._id)
              }
            >
              Supprimer
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </PageApp>
  )
}

export default CategoriesList

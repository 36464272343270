import '_assets/styles/entry.scss'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
//import * as Sentry from '@sentry/react'
//import { Integrations } from '@sentry/tracing'
import App from './containers/app'
import 'regenerator-runtime/runtime'
import { defaults } from 'react-sweet-state'
import '_core/moment'

defaults.devtools = process.env.NODE_ENV !== 'production'

const RenderApp = () => <App />

if (process.env.NODE_ENV === 'production') {
  /*  Sentry.init({
    dsn: 'https://82e27e8f21924026a41094f1d920c35f@o333814.ingest.sentry.io/5497915',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })*/
}

ReactDOM.render(<RenderApp />, document.getElementById('app'))

import {
  faBan,
  faCaretDown,
  faCaretRight,
  faCheck,
  faPlus,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { orderBy } from 'lodash'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import CheckboxField from '../check'
import Field, { BaseFormFieldProps } from '../index'

export interface TreeSelectOption {
  className?: string
  actions?: any[]
  value: string
  label: string
  multiple?: boolean
  childrens?: TreeSelectOption[]
  disbaled?: true
  [key: string]: any
}

export interface TreeSelectFieldProps extends BaseFormFieldProps {
  creatable?: boolean
  creatableLabels?: {
    main?: string
    sub?(parent: string): string
  }
  onCreate(item?: { parent?: string }): string
  options: TreeSelectOption[]
}

const FieldOptions: React.FunctionComponent<{
  options: TreeSelectOption[]
  level?: number
  parents?: string[]
  onChange(item: TreeSelectOption, checked: boolean, parents: string[]): void
  selected: string[]
  expanded: string[]
  creatable?: boolean
  onCreate?(item?: { parent?: string }): string
  expand(value: string): void
  mustExpanded: string[]
}> = props => {
  const level = props.level || 0

  return (
    <>
      {orderBy(props.options, [option => option.label.toLowerCase()]).map(option => (
        <div key={option.value}>
          <div className="tree-select-field">
            <span>
              {option.childrens && (
                <FontAwesomeIcon
                  size={props.mustExpanded.includes(option.value) ? 'xs' : undefined}
                  icon={
                    props.mustExpanded.includes(option.value)
                      ? faCheck
                      : props.expanded.includes(option.value)
                      ? faCaretDown
                      : faCaretRight
                  }
                  onClick={() =>
                    !props.mustExpanded.includes(option.value) && props.expand(option.value)
                  }
                />
              )}
            </span>
            <CheckboxField
              name={option.value}
              options={[
                {
                  value: option.value,
                  label: option.label,
                  forceChecked: props.selected.includes(option.value),
                },
              ]}
              disabled={option.disabled}
              onChange={value => props.onChange(option, value, props.parents || [])}
            />
            <span className="actions">
              {props.creatable && (
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => props.onCreate && props.onCreate({ parent: option.value })}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
              )}
            </span>
          </div>
          <div style={{ paddingLeft: (level + 1) * 20 }}>
            {option.childrens && props.expanded.includes(option.value) && (
              <FieldOptions
                {...props}
                options={option.childrens}
                level={level + 1}
                parents={props.parents ? [...props.parents, option.value] : [option.value]}
                selected={props.selected || []}
              />
            )}
          </div>
        </div>
      ))}
    </>
  )
}

const TreeSelectField: React.FunctionComponent<TreeSelectFieldProps> = props => {
  const [selected, setSelected] = React.useState<string[]>(props.defaultValue || [])
  const [expanded, setExpanded] = React.useState<string[]>([])
  const [mustExpanded, setMustExpanded] = React.useState<string[]>([])

  React.useEffect(() => {
    props.onChange && props.onChange(selected)
  }, [selected])

  React.useEffect(() => {
    setSelected(props.defaultValue || [])
  }, [props.defaultValue])

  React.useEffect(() => {
    const mustExpanded: string[] = []
    const getMustExpandedChildrens = (options: TreeSelectOption[]): boolean => {
      let haveChildrenExpanded = false
      for (const option of options) {
        if (option.childrens && getMustExpandedChildrens(option.childrens)) {
          mustExpanded.push(option.value)
          haveChildrenExpanded = true
        }
        if (selected.includes(option.value)) haveChildrenExpanded = true
      }
      return haveChildrenExpanded
    }
    getMustExpandedChildrens(props.options)
    setMustExpanded(mustExpanded)
    setExpanded([...expanded, ...mustExpanded.filter(v => !expanded.includes(v))])
  }, [selected])

  return (
    <Field {...props} className="tree-select-fields">
      <>
        {props.creatable && (
          <Button
            size="sm"
            variant="link"
            className="mb-1 p-0"
            onClick={() => props.onCreate && props.onCreate()}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
            Nouvelle catégorie
          </Button>
        )}
        <FieldOptions
          options={props.options}
          onChange={(item, checked, parents) => {
            const childrens: string[] = []
            const getChrildrensValue = (option: TreeSelectOption) => {
              if (option.childrens) {
                option.childrens.forEach(children => {
                  childrens.push(children.value)
                  getChrildrensValue(children)
                })
              }
            }
            getChrildrensValue(item)
            let newState = selected.filter(
              item => !parents.includes(item) && !childrens.includes(item),
            )
            if (checked) {
              if (props.multiple) newState.push(item.value)
              else newState = [item.value]
            } else {
              newState = newState.filter(value => value !== item.value)
            }
            setSelected(newState)
          }}
          selected={selected}
          creatable={props.creatable}
          onCreate={props.onCreate}
          expanded={expanded}
          mustExpanded={mustExpanded}
          expand={value => {
            const indexOfValue = expanded.indexOf(value)
            if (indexOfValue === -1) setExpanded([...expanded, value])
            else {
              expanded.splice(indexOfValue, 1)
              setExpanded([...expanded])
            }
          }}
        />
      </>
    </Field>
  )
}
export default TreeSelectField

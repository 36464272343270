import * as React from 'react'
import { useCurrentPage } from '_stores/pages/store'
import { Page } from '_stores/pages/types'
import { Route, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import Switch from 'react-bootstrap/esm/Switch'
import PageFields from './fields'
import PageEdit from './edit'
import PageSettings from './settings'
import HeaderButtonLink from '_components/header/button-link'
import { faCogs, faFile, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

export interface PageItemProps {
  page: Page
}

const PageItem: React.FunctionComponent = () => {
  const [currentPage, actions] = useCurrentPage()
  const { pathname } = useLocation()
  let activeNav = 'edit'
  if (pathname.match('/fields')) activeNav = 'fields'
  if (pathname.match('/settings')) activeNav = 'settings'

  const Tabs = React.useMemo(
    () =>
      currentPage
        ? [
            {
              label: 'Edit',
              to: `/pages/${currentPage._id}`,
              icon: faPencilAlt,
              active: activeNav === 'edit',
            },
            {
              label: 'Fields',
              to: `/pages/${currentPage._id}/fields`,
              icon: faFile,
              active: activeNav === 'fields',
            },
            {
              label: 'Settings',
              to: `/pages/${currentPage._id}/settings`,
              icon: faCogs,
              active: activeNav === 'settings',
            },
          ]
        : [],
    [currentPage],
  )

  return (
    <PageApp
      header={
        currentPage && currentPage._id
          ? {
              title: currentPage.name,
              rightLinks: [
                ...Tabs.map(tab => (
                  <HeaderButtonLink
                    key={tab.label}
                    variant={tab.active ? 'primary' : 'link'}
                    {...tab}
                  />
                )),
              ],
            }
          : undefined
      }
    >
      <ApiItemWrapper item={currentPage} actions={actions}>
        <div className="mt-3">
          <Switch className="pl-0">
            <Route path="/pages/:id/fields" component={PageFields} exact />
            <Route path="/pages/:id/settings" component={PageSettings} exact />
            <Route path="/pages/:id" exact component={PageEdit} />
          </Switch>
        </div>
      </ApiItemWrapper>
    </PageApp>
  )
}

export default PageItem

import * as React from 'react'
import { useCategories, useCategoriesRequest, useCurrentCategory } from '_stores/categories/store'
import CategoryForm from './form'
import { Category } from '_stores/categories/types'
import { Button, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

export interface CategoryItemProps {
  category: Category
}

const CategoryItem: React.FunctionComponent = () => {
  const [state] = useCategories()
  const [currentCategory, actions] = useCurrentCategory()
  const history = useHistory()
  const locationParams: any = useParams()
  const [request] = useCategoriesRequest('post')

  React.useEffect(() => {
    if (locationParams.id && locationParams.id !== 'new') {
      /*if (!state.allIds.includes(locationParams.id)) {
        actions.get(locationParams.id)
      }*/
      actions.setCurrent(locationParams.id)
    } else if (locationParams.id && locationParams.id === 'new') {
      actions.setCurrent(undefined)
    }
  }, [locationParams.id, state.all, state.currentId])

  React.useEffect(() => {
    if (request && request.status === 'success') history.goBack()
    return () => actions.clearRequest({ key: 'post /categories' })
  }, [request])

  return (
    <>
      <Modal show={true} onHide={() => history.goBack()}>
        <Modal.Header>
          {currentCategory ? currentCategory.labels.fr : 'Nouvelle catégorie'}
        </Modal.Header>
        <Modal.Body>
          <CategoryForm />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CategoryItem

import {
  createStore,
  createSubscriber,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state'
import { ApiCallOptions, ApiRequest, genericActions, ResourceState } from '_core/store'
import { Product, ProductKinds } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'
import { useLocation } from 'react-router'

export interface ProductsState extends ResourceState<Product> {
  autocomplete: {
    searchValue?: string
    result: { value: string; label: string }[]
  }
}
export type ProductsApi = StoreActionApi<ProductsState>

const initialState: ProductsState = {
  endpoint: '/products',
  all: [],
  currentId: undefined,
  requests: {},
  filters: {
    limit: 25,
    sort: 'name.fr',
  },
  autocomplete: {
    result: [],
  },
}

const actions = {
  fetch: () => async ({ dispatch, getState, setState }: ProductsApi) => {
    const filters = { ...getState().filters }
    if (window.location.pathname.match(ProductKinds.attempt)) filters.kind = ProductKinds.attempt
    if (filters.visibility) {
      filters.visibility.forEach((visibility: string) => {
        filters[`visibility.${visibility}`] = true
      })
      delete filters.visibility
    }
    const res = await dispatch(
      genericActions.api({
        method: 'get',
        url: getState().endpoint,
        data: {
          ...filters,
          populate: ['pictures.item', 'creator', 'categories', 'brand'],
        },
      }),
    )
    if (res && res.result) setState({ all: res.result, count: res.count })
  },
  autocomplete: (search: string, locale: string) => async ({
    dispatch,
    getState,
    setState,
  }: ProductsApi) => {
    const res = await dispatch(
      genericActions.api({
        method: 'get',
        url: getState().endpoint,
        data: {
          search,
          fields: ['name', '_id', 'mafactData.ref', 'pictures'],
          populate: ['pictures.item'],
          limit: 40,
        },
      }),
    )
    if (res && res.result)
      setState({
        autocomplete: {
          searchValue: search,
          result: res.result.map((p: Product) => ({
            value: p._id,
            label: `${p.name[locale]} - ${p.mafactData.ref}`,
          })),
        },
      })
  },
  get: (_id: string) => async ({ dispatch }: ProductsApi) => {
    await dispatch(
      genericActions.apiGet(_id, {
        data: {
          populate: [
            'pictures.item',
            'creator',
            'categories',
            'brand',
            'linkedProducts',
            'votes.user',
          ],
        },
      }),
    )
  },
  create: (data: Product) => async ({ getState, dispatch }: ProductsApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) history.push(`${getState().endpoint}/${res.result._id}`)
  },
  update: (data: Partial<Product>, options?: ApiCallOptions) => async ({
    dispatch,
    getState,
  }: ProductsApi) => {
    if (!options) options = {}
    options.requestLivetime = 3000
    const res = await dispatch(
      genericActions.api(
        {
          method: 'put',
          url: `${getState().endpoint}/${data._id}`,
          data,
        },
        options,
      ),
    )
    if (res && res.result) await dispatch(actions.get(res.result._id))
  },
  createOrUpdate: (data: Partial<Product>) => async ({ dispatch }: ProductsApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: ProductsApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  createFile: (data: any) => async ({ getState, setState, dispatch }: ProductsApi) => {
    const formData = new FormData()
    formData.append('files', data.file[0])
    delete data.file
    formData.append('payload', JSON.stringify(data))
    const res = await dispatch(
      genericActions.api(
        {
          method: 'post',
          url: `${getState().endpoint}/${data.productId}/files`,
          data: formData,
        },
        {
          progress: true,
          headers: {
            'content-type': 'multipart/form-data',
          },
          timeout: 120 * 1000,
        },
      ),
    )
    await dispatch(actions.get(data.productId))
  },
  updateFile: (data: any) => async ({ getState, setState, dispatch }: ProductsApi) => {
    await dispatch(
      genericActions.api(
        {
          method: 'put',
          url: `${getState().endpoint}/${data.productId}/files/${data.fileId}`,
          data: data,
        },
        {
          requestLivetime: 3000,
        },
      ),
    )
    await dispatch(actions.get(data.productId))
  },
  removeFile: (data: { fileId: string; productId: string }) => async ({
    getState,
    dispatch,
  }: ProductsApi) => {
    await dispatch(
      genericActions.api({
        method: 'delete',
        url: `${getState().endpoint}/${data.productId}/files/${data.fileId}`,
      }),
    )
    await dispatch(actions.get(data.productId))
  },
  getSchema: () => async ({ dispatch }: ProductsApi) => {
    await dispatch(genericActions.getSchema())
  },
  setFilter: (key: string, value: any) => async ({ dispatch }: ProductsApi) => {
    dispatch(genericActions.setFilter(key, value))
    await dispatch(actions.fetch())
  },
  setFilters: (values: any) => async ({ dispatch }: ProductsApi) => {
    dispatch(genericActions.setFilters(values))
    await dispatch(actions.fetch())
  },
  setCurrent: (product?: Product) => ({ dispatch }: ProductsApi) => {
    dispatch(genericActions.setCurrent(product))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: ProductsApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: ProductsApi) => {
    dispatch(genericActions.clearRequests())
  },
  setCurrentPaginationPage: (currentPaginationPage: number) => ({ setState }: ProductsApi) => {
    setState({ currentPaginationPage })
  },
}

export type ProductsActions = typeof actions

const Store = createStore<ProductsState, ProductsActions>({
  name: 'products',
  initialState,
  actions,
})

export const ProductsSubscriber = createSubscriber(Store)
export const useProducts = createHook(Store)
export const useCurrentProduct = createHook(Store, {
  selector: (state: ProductsState) => {
    if (state.currentId) return state.all.find(product => product._id === state.currentId)
    return
  },
})
export const useProductsRequest = createHook(Store, {
  selector: (
    state: ProductsState,
    props: { method?: string; id?: string; name?: string },
  ): ApiRequest | undefined => {
    const { requests, endpoint } = state
    if (props.name) return requests[props.name]
    if (props.method && props.id) return requests[`${props.method} ${endpoint}/${props.id}`]
    else if (props.method) return requests[`${props.method} ${endpoint}`]
    return
  },
})

export const ProductsContainer = createContainer(Store)

export default Store

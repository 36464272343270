import * as React from 'react'
import { Formik, FormikProps, Field, Form as FormikForm } from 'formik'
import RequestMessage from '_components/request-message'
import { Button, Col, Row } from 'react-bootstrap'
import { FormFieldProp } from './types'
import { getComponent } from './utils'
import _, { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Title from '_components/title'
import classNames from 'classnames'
import { Prompt } from 'react-router'
import { ApiRequest } from '_core/store'
import CheckboxField from './fields/check'

export type FieldsProps = FormFieldProp[]

interface CancelButtonProp {
  onClick(): void
  label?: string
}

export interface FormProps {
  fields: FieldsProps
  initialValues?: any
  request?: ApiRequest
  validate?(value: any): any
  onSubmit?(values: any): any
  onFieldChange?(fieldName: string, value: any): void
  success?(): any
  submitLabel?: string
  submitLg?: boolean
  cancelButton?: CancelButtonProp
  buttonPosition?: 'bottom' | 'top' | 'all'
  noButton?: boolean
  unsavedAlert?: boolean
  forceShowSubmit?: boolean
  bindValues?: any
}

interface ButtonsProps {
  submitLg?: boolean
  submitLabel?: string
  request?: ApiRequest
  cancelButton?: CancelButtonProp
  disabled?: boolean
  className?: string
}

const Buttons: React.FunctionComponent<ButtonsProps> = ({
  submitLg,
  submitLabel,
  request,
  cancelButton,
  disabled,
  className,
}) => (
  <>
    <Button
      className={classNames(className)}
      variant="primary"
      block={submitLg}
      type="submit"
      disabled={disabled}
    >
      {submitLabel || 'Submit'}
      {request && request.status === 'inProgress' && (
        <FontAwesomeIcon className="ml-1" icon={faSpinner} spin />
      )}
      {request && request.status === 'success' && (
        <FontAwesomeIcon className="ml-1" icon={faCheck} />
      )}
    </Button>
    {cancelButton && (
      <Button
        className="ml-2"
        variant="default"
        block={submitLg}
        type="button"
        onClick={cancelButton.onClick}
      >
        {cancelButton.label || 'Cancel'}
      </Button>
    )}
  </>
)

interface FormFieldProps {
  field: FormFieldProp
  onFieldChange?(fieldName: string, value: any): void
  values: any
  errors: any
  touched: any
  submitCount: number
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  initialValues: any
}

const FormField: React.FunctionComponent<FormFieldProps> = ({
  field,
  onFieldChange,
  values,
  errors,
  touched,
  submitCount,
  setFieldValue,
  initialValues,
}) => {
  const commonProps = {
    component: getComponent(field.type),
    submitCount: submitCount,
    setFieldValue: setFieldValue,
    values: values,
  }

  const [duplicatedLocales, setDuplicatedLocales] = React.useState(!field.defaultDuplicateLocales)

  const setDuplicateValue = (value?: string) => {
    if (!value) {
      const fieldValues = get(values, field.name)
      value = fieldValues && fieldValues[Object.keys(fieldValues)[0]]
    }
    if (field.locales) {
      const newValue: any = {}
      for (const locale of field.locales) {
        newValue[locale] = value
      }
      setFieldValue(field.name, newValue)
    }
  }

  React.useEffect(() => {
    if (field.locales && field.canDuplicateLocales && field.defaultDuplicateLocales !== false) {
      const fieldValues: any[] = []
      field.locales.forEach(locale => {
        const name = `${field.name}.${locale}`
        const fieldValue = get(initialValues, name)
        if (!fieldValues.includes(fieldValue)) fieldValues.push(fieldValue)
      })
      const defaultDuplicateLocales =
        field.canDuplicateLocales && !fieldValues.includes(undefined) && fieldValues.length === 1
      setDuplicatedLocales(defaultDuplicateLocales)
      if (defaultDuplicateLocales) {
        setDuplicateValue()
      }
    }
  }, [])

  if (field.locales) {
    return (
      <>
        {field.locales.map((locale: string, index: number): any => {
          const name = `${field.name}.${locale}`
          if (!duplicatedLocales || index === 0)
            return (
              <Field
                key={name}
                {...field}
                {...commonProps}
                name={name}
                prepend={!duplicatedLocales && (field.prepend || locale)}
                error={_.get(errors, name)}
                defaultValue={_.get(values, name)}
                onChange={(value: any) => {
                  onFieldChange && onFieldChange(name, value)
                  if (duplicatedLocales && field.locales) {
                    setDuplicateValue(value)
                  } else {
                    setFieldValue(name, value)
                  }
                }}
                touched={Boolean(submitCount || _.get(touched, name))}
                className={classNames(field.className, { 'mb-0': duplicatedLocales })}
              />
            )
        })}
        {field.canDuplicateLocales && (
          <CheckboxField
            name={`${field.name}_duplicatedLocales`}
            id={`${field.name}_duplicatedLocales`}
            onChange={value => {
              setDuplicatedLocales(value)
              setDuplicateValue()
            }}
            defaultValue={duplicatedLocales}
            options={[
              {
                label: 'Utiliser la même valeur pour toutes les langues?',
                value: 'true',
                forceChecked: duplicatedLocales,
              },
            ]}
          />
        )}
      </>
    )
  }
  return (
    <Field
      key={field.name}
      {...field}
      {...commonProps}
      error={_.get(errors, field.name)}
      defaultValue={_.get(values, field.name)}
      onChange={(value: any) => {
        onFieldChange && onFieldChange(field.name, value)
        setFieldValue(field.name, value)
      }}
      touched={Boolean(submitCount || _.get(touched, field.name))}
    />
  )
}

const Form: React.FunctionComponent<FormProps> = ({
  fields,
  initialValues,
  validate,
  onSubmit,
  request,
  success,
  submitLabel,
  submitLg,
  cancelButton,
  onFieldChange,
  buttonPosition,
  noButton,
  unsavedAlert,
  forceShowSubmit,
  bindValues,
}) => {
  if (request && request.status === 'success' && success) return success()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || {}}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values = {},
        errors = {},
        touched = {},
        submitCount,
        setFieldValue,
      }: FormikProps<any>) => {
        const isModified = Boolean(!initialValues || !values || !_.isEqual(values, initialValues))
        bindValues && bindValues(values)
        return (
          <FormikForm>
            <RequestMessage request={request} />
            {unsavedAlert && (
              <Prompt
                when={isModified}
                message="You have unsaved changes, are you sure you want to leave?"
              />
            )}
            {(buttonPosition === 'top' || buttonPosition === 'all') &&
              (isModified || forceShowSubmit) && (
                <div className="mb-2 text-right top-buttons">
                  <Buttons
                    submitLg={submitLg}
                    submitLabel={submitLabel}
                    request={request}
                    cancelButton={cancelButton}
                    //disabled={ !isModified }
                  />
                </div>
              )}
            {fields.map((field, index) => {
              if (field.type === 'form') {
                return (
                  <div key={index}>
                    <field.form />
                  </div>
                )
              } else if (field.type === 'section') {
                return (
                  <Row key={index}>
                    {field.section && field.section.title && (
                      <Col md="12">
                        <Title label={field.section.title} level={4} className="mt-4 mb-1" />
                      </Col>
                    )}
                    {field.section &&
                      field.section.fields.map((field, index) => (
                        <Col {...field.colProps} key={index}>
                          <FormField
                            field={field.field}
                            initialValues={initialValues}
                            onFieldChange={onFieldChange}
                            values={values}
                            errors={errors}
                            touched={touched}
                            submitCount={submitCount}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                      ))}
                  </Row>
                )
              }
              return (
                <FormField
                  field={field}
                  onFieldChange={(fieldName, value) => {
                    onFieldChange && onFieldChange(fieldName, value)
                    field.onChange && field.onChange(value)
                  }}
                  values={values}
                  errors={errors}
                  touched={touched}
                  submitCount={submitCount}
                  setFieldValue={setFieldValue}
                  key={index}
                  initialValues={initialValues}
                />
              )
            })}
            {(!buttonPosition || buttonPosition === 'bottom' || buttonPosition === 'all') &&
              !noButton && (
                <Buttons
                  submitLg={submitLg}
                  submitLabel={submitLabel}
                  request={request}
                  cancelButton={cancelButton}
                  //disabled={ !isModified }
                />
              )}
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default Form

import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useResourceFromParams } from '_core/store'
import Loader from '_components/loader'

interface ApiItemWrapperProps {
  children: React.ReactNode
  item?: any
  actions: any
  noItem?: boolean
}

const ApiItemWrapper: React.FunctionComponent<ApiItemWrapperProps> = ({
  children,
  item,
  actions,
  noItem,
}) => {
  const locationParams: any = useParams()
  useResourceFromParams(actions)
  if (locationParams.id === 'new' || item || noItem) return <>{children}</>

  return <Loader />
}

export default ApiItemWrapper

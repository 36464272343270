import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form } from 'react-bootstrap'
import { random } from 'lodash'
export type SwitchFieldProps = BaseFormFieldProps

export const SwitchField: React.FunctionComponent<SwitchFieldProps> = props => {
  const id = React.useMemo(
    () => `switch_${props.field.name}_${new Date().getTime()}${random(1000000)}`,
    [],
  )
  return (
    <Field {...props}>
      <Form.Check
        type="switch"
        defaultChecked={props.defaultValue}
        id={id}
        label={props.inputLabel}
        onChange={(e: any) => props.onChange && props.onChange(e.target.checked)}
      />
    </Field>
  )
}

export default SwitchField

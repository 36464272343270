import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import Table from '_components/table'
import { useCurrentProduct } from '_stores/products/store'
import useLinkedProductsTable from '../hooks/use-linked-products-table'
import useLinkedProductFormFields from '../hooks/use-linked-product-form-fields'
import Form from '_components/form'

interface LinkedProductsFormProps {
  locale: string
}

const LinkedProductsForm: React.FunctionComponent<LinkedProductsFormProps> = ({ locale }) => {
  const [currentProduct, action] = useCurrentProduct()
  const [showNewModal, setShowNewModal] = React.useState(false)
  const [columns, data] = useLinkedProductsTable({
    product: currentProduct,
    locale,
  })
  const [fields] = useLinkedProductFormFields({
    product: currentProduct,
    locale,
  })

  return (
    <>
      <Card>
        <div className="p-4">
          <Table columns={columns} data={data} />
        </div>
      </Card>
      <div className="d-flex justify-content-end mt-2">
        <Button size="sm" variant={'link'} className="mb-2" onClick={() => setShowNewModal(true)}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
          Lier un produit
        </Button>
      </div>
      <Modal show={showNewModal} onHide={() => setShowNewModal(false)}>
        <Modal.Header>Lier un produit</Modal.Header>
        <Modal.Body>
          <Form
            fields={fields}
            onSubmit={values => {
              const currentLinkedProducts = currentProduct?.linkedProducts || []
              if (currentProduct && !currentLinkedProducts.includes(values.product)) {
                action.update({
                  _id: currentProduct._id,
                  linkedProducts: [...currentLinkedProducts, values.product.value],
                })
              }
              setShowNewModal(false)
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LinkedProductsForm

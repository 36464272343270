import { faEnvelope, faFax, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { OrganizationContact as OrganizationContactType } from '_stores/organizations/types'

interface OrganizationContactProps {
  title: string
  contact: OrganizationContactType
}

const OrganizationContact: React.FunctionComponent<OrganizationContactProps> = ({
  contact,
  title,
}) => {
  return (
    <div>
      <h5>
        {contact.firstName} {contact.lastName}
        <br />
        <small>{title}</small>
      </h5>
      <p></p>
      <p>
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" title="email" />
        {contact.email}
        <br />
        <FontAwesomeIcon icon={faPhone} className="mr-2" title="téléphone" />
        {contact.phone}
        {contact.fax && (
          <>
            <FontAwesomeIcon icon={faFax} className="ml-2 mr-2" title="fax" />
            {contact.fax}
          </>
        )}
      </p>
    </div>
  )
}

export default OrganizationContact
